import { z } from 'zod';

export const MATERIAL_SUPPORTED_UNITS_SCHEMA = z.enum([
    'KILOGRAM',
    'TON',
    'CUBIC_METER',
    'CUBIC_CENTIMETER',
    'LITER',
    'PIECE',
]);

export type MATERIAL_SUPPORTED_UNITS = z.infer<typeof MATERIAL_SUPPORTED_UNITS_SCHEMA>;

export const MATERIAL_PRODUCT_CATEGORY_SCHEMA = z.enum(['MATERIAL', 'MATERIAL_SERVICE']);

export type MATERIAL_PRODUCT_CATEGORY = z.infer<typeof MATERIAL_PRODUCT_CATEGORY_SCHEMA>;

export const MATERIAL_PRODUCT_TYPES_SCHEMA = z.enum([
    'CUSTOM_BULK_MATERIAL',
    'CUSTOM_RECYCLED_BULK_MATERIAL',
    'CUSTOM_NON_HAZARDOUS_WASTE_RECYCLING',
    'CUSTOM_NON_HAZARDOUS_WASTE_DISPOSAL',
    'CUSTOM_HAZARDOUS_WASTE_RECYCLING',
    'CUSTOM_HAZARDOUS_WASTE_DISPOSAL',
    'CUSTOM_CONCRETE',
    'CUSTOM_ASPHALT',
    'CUSTOM_SERVICE_ANALYSIS',
    'BULK_MATERIAL',
    'NON_HAZARDOUS_WASTE_DISPOSAL',
    'HAZARDOUS_WASTE_DISPOSAL',
    'NON_HAZARDOUS_WASTE_RECYCLING',
    'HAZARDOUS_WASTE_RECYCLING',
]);

export type MATERIAL_PRODUCT_TYPES = z.infer<typeof MATERIAL_PRODUCT_TYPES_SCHEMA>;
