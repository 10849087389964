import { z } from 'zod';
import { QUOTE_STATUS_SCHEMA } from '../quote/quote-status.ts';
import { SUPPORTED_UNITS_SCHEMA } from '../quote-unit/unit-types.ts';

export const GetProductQuoteUrlSchema = z.strictObject({
    quoteId: z.string().uuid(),
});

export const GetProductQuoteResponseSchema = z.strictObject({
    meta: z.strictObject({
        id: z.string().uuid(),
        status: QUOTE_STATUS_SCHEMA,
        createdAt: z.coerce.date(),
        updatedAt: z.coerce.date(),
        // order information
        orderId: z.string().uuid(),
        selectedProductGroupId: z.string().uuid(),
        selectedProductId: z.string().uuid(),
        // product information
        amount: z.number(),
        unit: SUPPORTED_UNITS_SCHEMA,
        serviceDate: z.coerce.date(),
        // payment information
        partnerOrganizationId: z.number().int().positive().nullable(),
        orderingOrganizationId: z.number().int().positive(),
        platformOrganizationId: z.number().int().positive(),
        purchasePrice: z.number(),
        purchaseTaxClassId: z.string(),
        salesPrice: z.number(),
        salesTaxClassId: z.string(),
        currencyCode: z.string(),
    }),
    payload: z.any(),
});
