import React from 'react';
import { MaterialIcon, VehicleMaterialIcon, LaptopIcon } from '@schuettflix/icons-react';
import { ProductCategoryIcon } from '@/shared/types/prefill-template-data';

interface CategoryIconProps {
    productCategory: ProductCategoryIcon;
}

const CategoryIcon: React.FC<CategoryIconProps> = ({ productCategory }) => {
    switch (productCategory) {
        case 'material-transport':
            return (
                <div className="flex">
                    <MaterialIcon className="mr-2" />
                    <VehicleMaterialIcon />
                </div>
            );
        case 'material':
            return <MaterialIcon />;
        case 'transport':
            return <VehicleMaterialIcon />;
        case 'service':
            return <LaptopIcon />;
        default:
            return <MaterialIcon />;
    }
};

export default CategoryIcon;
