import React from 'react';
import { MessageBox } from '@schuettflix/react-components';
import { useToasterContext } from '@/shared/context/ToasterContext.tsx';
import { cn } from '@/shared/utils/cn.ts';

export const Toaster: React.FC = () => {
    const { toasts, removeToast } = useToasterContext();

    return (
        <div className="fixed right-8 top-[100px] z-10 ml-[100px] flex w-full flex-col gap-y-2 md:max-w-[50%] xl:max-w-[500px]">
            {toasts.map(toast => (
                <div key={toast.id}>
                    <MessageBox
                        title={toast.title}
                        type={toast.type}
                        className={cn('transition-all hover:opacity-90', toast.className)}
                        onClose={
                            toast.closable
                                ? () => {
                                      removeToast(toast.id!);
                                  }
                                : undefined
                        }
                    >
                        {toast.message}
                    </MessageBox>
                </div>
            ))}
        </div>
    );
};
