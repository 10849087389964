import React from 'react';
import {
    BaseCustomRequestProductProps,
    CustomRequestProduct,
    CustomRequestProductForm,
} from '@/CustomRequestProductChannel/components/CustomRequestProduct.tsx';
import { CustomRequestSuspenseBoundary } from '@/CustomRequestProductChannel/components/CustomRequestSuspenseBoundary.tsx';
import { NestedSelectedProductResponse } from '@schuettflix/interfaces';
import { useDebounce } from '@/shared/hooks/useDebounce';

type Product = BaseCustomRequestProductProps['product'] & Pick<NestedSelectedProductResponse, 'selectedProductGroupId'>;

export type DebouncedCustomRequestProductProps = BaseCustomRequestProductProps & {
    product: Product;
    onCompleteChange: (productId: string, value: boolean) => void; // shared
    onSubmit: (formValues: CustomRequestProductForm) => Promise<void>; // shared
};

export type SimpleDebouncedCustomRequestProductProps = Omit<
    DebouncedCustomRequestProductProps,
    'onSubmit' | 'taxInfo' | 'customerId' | 'quote'
>;

export const DebouncedCustomRequestProduct: React.FC<DebouncedCustomRequestProductProps> = props => {
    const [formValuesStage, setFormValuesStage] = React.useState<CustomRequestProductForm | null>(null);
    const handleSubmit = (formValues: CustomRequestProductForm | null) => {
        if (!formValues) return;
        void props.onSubmit(formValues);
    };

    useDebounce(formValuesStage, 500, handleSubmit);

    return (
        <CustomRequestSuspenseBoundary>
            <CustomRequestProduct
                {...props}
                onFormChange={(...params) => {
                    const [form] = params;
                    if (form.isValid) {
                        setFormValuesStage({ ...form.values });
                    } else {
                        // if we have errors, this section is not valid. By this callback we trigger uproot that this section is not valid.
                        props?.onCompleteChange(props.product.id, false);
                    }
                    props.onFormChange?.(...params);
                }}
            />
        </CustomRequestSuspenseBoundary>
    );
};
