
            import './src/index.css';
            import SimpleState from '@schuettflix/util-simple-state';
            import { appOrder } from '@/index.library';

            const states = {
                accessToken: new SimpleState(''),
                baseUrl: {
                    backend: new SimpleState(import.meta.env.VITE_MONOLITH_BASE_URL),
                    constructionProject: new SimpleState(import.meta.env.VITE_CONSTRUCTION_PROJECT_BASE_URL),
                },
                userLocale: new SimpleState('de-DE'),
                environment: new SimpleState('development'),
            };

            window.setAccessToken = tokenOrMarketCode => {
                states.accessToken.value = ''; // [tokenOrMarketCode] ?? tokenOrMarketCode
            };

            appOrder.install({
                hostAppVersion: '6.54.1',
                ...states,
            });

            const div = document.querySelector('#root');

            appOrder.mount(div, {
                eventHandlers: {
                    handleClose: () => {
                        console.log('CloseEvent emitted from App (CustomElement)');
                    },
                    handlePushMessage(detail) {
                        console.log('PushMessageEvent emitted from App (CustomElement)', detail);
                    },
                },
            });
        