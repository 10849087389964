import z from 'zod';
import { TemplateSchema } from './template.ts';
import {
    PRODUCT_GROUP_TEMPLATE_TITLE_SCHEMA,
    TEMPLATE_GROUP_DESCRIPTION_SCHEMA,
    TEMPLATE_GROUP_TITLE_SCHEMA,
} from './template-type.ts';

const TemplateGroupSectionSchema = z.enum(['TRANSPORT', 'MATERIAL', 'TRANSPORT_MATERIAL', 'SERVICE']);

export const TemplateGroupSchema = z.strictObject({
    id: z.string().uuid(),
    name: TEMPLATE_GROUP_TITLE_SCHEMA,
    productGroupTemplateTitle: PRODUCT_GROUP_TEMPLATE_TITLE_SCHEMA,
    sections: z.array(TemplateGroupSectionSchema),
    templates: z.array(TemplateSchema).optional(),
    description: TEMPLATE_GROUP_DESCRIPTION_SCHEMA.optional().nullable(),
});

export const GetTemplateGroupResponseSchema = TemplateGroupSchema;

export type GetTemplateGroupResponse = z.infer<typeof GetTemplateGroupResponseSchema>;

export type TemplateGroupSection = z.infer<typeof TemplateGroupSectionSchema>;

export type TemplateGroup = z.infer<typeof TemplateGroupSchema>;
