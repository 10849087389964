import z from 'zod';
import { ORDER_ORGANIZATION_ROLE_SCHEMA } from '../finance-settings/role.ts';
import { PaymentSettingsWithOptionsResponseSchema } from '../finance-settings/payment-settings.ts';
import { TransactionalTotalsSchema } from './totals.ts';
import { TransactionWithQuoteStatusSchema } from './transactions.ts';

export const GetOrderSummaryUrlSchema = z.strictObject({
    orderId: z.string().uuid(),
});

export const GetOrderSummaryResponseSchema = z.strictObject({
    organizations: z.array(
        z.strictObject({
            orgId: z.number(),
            functionalRoles: z.array(ORDER_ORGANIZATION_ROLE_SCHEMA),
            totals: z.array(TransactionalTotalsSchema),
            transactions: z.array(TransactionWithQuoteStatusSchema),
            financeSettings: z.array(PaymentSettingsWithOptionsResponseSchema),
        })
    ),
});
