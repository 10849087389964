import { CURRENCY, CURRENCY_DECIMALS } from '@schuettflix/interfaces';

import { Money } from 'bigint-money';

export class Price {
    value: Money;

    constructor(value: number | string | undefined, currency: CURRENCY) {
        const cleanedUpValue = `${value}`.replace(',', '.');
        this.value = new Money(value === undefined ? 0 : cleanedUpValue, currency);
    }

    public toString(): string {
        return this.value.toFixed(CURRENCY_DECIMALS[this.value.currency as CURRENCY]);
    }

    public toNumber(): number {
        return parseFloat(this.value.format());
    }

    public add(price: Price): Price {
        return new Price(this.value.add(price.value).format(), this.value.currency as CURRENCY);
    }

    public subtract(price: Price): Price {
        return new Price(this.value.subtract(price.value).format(), this.value.currency as CURRENCY);
    }

    public multiply(factor: number): Price {
        return new Price(this.value.multiply(`${factor}`).format(), this.value.currency as CURRENCY);
    }

    public divide(divisor: string | number | Price): Price {
        if (divisor instanceof Price) {
            return new Price(this.value.divide(divisor.value).format(), this.value.currency as CURRENCY);
        }
        return new Price(this.value.divide(`${divisor}`).format(), this.value.currency as CURRENCY);
    }
}
