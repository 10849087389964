import { useSuspenseQuery } from '@tanstack/react-query';
import { getPositionById } from './position';
import { useMarketCode } from '@/shared/hooks/useMarketCode.ts';

export const useMarketAwarePosition = (positionId?: string | null, fetchOnce = false) => {
    const query = useSuspenseQuery({
        queryKey: ['position', positionId],
        queryFn: async () => (positionId ? getPositionById(positionId) : null),
        ...(fetchOnce && { staleTime: Infinity, cacheTime: Infinity }),
        refetchOnWindowFocus: false,
    });

    useMarketCode(query.data?.marketCode);

    return query;
};
