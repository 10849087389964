import { decodeToken } from '@okta/okta-auth-js';

export const getUserFromToken = (idToken: string) => {
    const decoded = decodeToken(idToken);

    return {
        email: decoded.payload.email,
        name: decoded.payload.name,
    };
};
