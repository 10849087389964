import { z } from 'zod';
import { order } from '@schuettflix/schema-registry-ts';

export const InternalOrderSubmittedSchema = order.orderService.versions.v1.schemas.INTERNAL_ORDER_SUBMITTED;

export const InternalProductGroupsSchema = order.orderService.versions.v1.schemas.INTERNAL_PRODUCT_GROUP;

export const InternalQuoteSchema = order.orderService.versions.v1.schemas.INTERNAL_QUOTE_SCHEMA;

export const InternalSelectProductSchema = order.orderService.versions.v1.schemas.INTERNAL_PRODUCT;

export const InternalRequiredBySchema = order.orderService.versions.v1.schemas.INTERNAL_REQUIRED_BY_SCHEMA;

export const InternalRequiresSchema = order.orderService.versions.v1.schemas.INTERNAL_REQUIRES_SCHEMA;

export type InternalOrderSubmittedPayload = z.infer<typeof InternalOrderSubmittedSchema>;
export type InternalProductGroups = z.infer<typeof InternalProductGroupsSchema>;
export type InternalQuoteOrderSubmittedPayload = z.infer<typeof InternalQuoteSchema>;
export type InternalSelectProduct = z.infer<typeof InternalSelectProductSchema>;
export type InternalRequiredBy = z.infer<typeof InternalRequiredBySchema>;
export type InternalRequires = z.infer<typeof InternalRequiresSchema>;
