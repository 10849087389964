import React from 'react';
import { cn } from '@/shared/utils/cn.ts';

type SearchOptionProps = {
    active: boolean;
    selected: boolean;
    title: React.ReactNode;
    description?: React.ReactNode;
    side?: React.ReactNode;
};

export const SearchOption: React.FC<SearchOptionProps> = ({ active, selected, ...props }) => {
    return (
        <div
            className={cn(
                'text-interactive hover:bg-surface-hovered flex items-center justify-between gap-2 border-b p-4 hover:cursor-pointer',
                { 'bg-surface-hovered': active, 'bg-surface-selected': selected }
            )}
            data-test="search-dropdown-items"
        >
            <div>
                <h6 className="font-copy-md-strong mb-1">{props.title}</h6>
                <div className="text-subdued font-copy-sm">{props.description}</div>
            </div>
            <div className="text-subdued font-copy-sm">{props.side}</div>
        </div>
    );
};
