import { z } from 'zod';
import { AbstractPatchQuoteRequestSchema } from '../quote/patch-quote.ts';
import { GetQuoteResponseSchema } from './get-quote.ts';

export const PatchQuoteUrlSchema = z.strictObject({
    quoteId: z.string().uuid(),
});

/**
 * validation schema for specific request payload
 */
const CustomRequestPatchPayload = z.strictObject({
    payload: z.strictObject({
        // add custom fields of the fulfillment channel here
        name: z.string().optional(),
    }),
});

/**
 * validation schema for whole (merged) request payload
 */
export const PatchQuoteRequestSchema = AbstractPatchQuoteRequestSchema.merge(CustomRequestPatchPayload).strict();

/**
 * validation schema for the response payload
 * we want to return the whole entity when PATCHing
 */
export const PatchQuoteResponseSchema = GetQuoteResponseSchema;
