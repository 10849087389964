import { z } from 'zod';

import { order } from '@schuettflix/schema-registry-ts';

export const PRODUCT_TYPE_SCHEMA = order.orderService.versions.v1.schemas.PRODUCT_TYPE_SCHEMA;

export type PRODUCT_TYPES = z.infer<typeof PRODUCT_TYPE_SCHEMA>;

export const PRODUCT_NAME_LABEL = {
    CUSTOM_BULK_MATERIAL: 'productTemplate.primaryBulkGoods',
    CUSTOM_RECYCLED_BULK_MATERIAL: 'productTemplate.recyclingBulkGoods',
    CUSTOM_NON_HAZARDOUS_WASTE_RECYCLING: 'productTemplate.nonHazardousWasteMaterial',
    CUSTOM_NON_HAZARDOUS_WASTE_DISPOSAL: 'productTemplate.nonHazardousWasteMaterial',
    CUSTOM_HAZARDOUS_WASTE_RECYCLING: 'productTemplate.hazardousWasteMaterial',
    CUSTOM_HAZARDOUS_WASTE_DISPOSAL: 'productTemplate.hazardousWasteMaterial',
    CUSTOM_TRANSPORT: 'productTemplate.transport',
    CUSTOM_CONCRETE: 'productTemplate.concrete',
    CUSTOM_ASPHALT: 'productTemplate.asphalt',
    CUSTOM_SERVICE: 'productTemplate.customService',
    CUSTOM_SERVICE_ACCOMPANYING_DOCUMENT: 'productTemplate.customServiceAccompanyingDocument',
    CUSTOM_SERVICE_ANALYSIS: 'productTemplate.customServiceAnalysis',
    CUSTOM_SERVICE_WAITING_TIME: 'productTemplate.customServiceWaitingTime',
    CUSTOM_SERVICE_TOLL: 'productTemplate.customServiceToll',
    CUSTOM_SERVICE_TRANSPORT: 'productTemplate.customServiceTransport',
    BULK_MATERIAL: 'productTemplate.primaryBulkGoods',
    TRANSPORT: 'productTemplate.transport',
    NON_HAZARDOUS_WASTE_DISPOSAL: 'productTemplate.nonHazardousWasteMaterial',
    HAZARDOUS_WASTE_DISPOSAL: 'productTemplate.hazardousWasteMaterial',
    NON_HAZARDOUS_WASTE_RECYCLING: 'productTemplate.nonHazardousWasteMaterial',
    HAZARDOUS_WASTE_RECYCLING: 'productTemplate.hazardousWasteMaterial',
} as const satisfies Record<PRODUCT_TYPES, string>;

export type SERVICE_PRODUCT_TYPES_VALUE = Extract<
    PRODUCT_TYPES,
    | 'CUSTOM_SERVICE_ACCOMPANYING_DOCUMENT'
    | 'CUSTOM_SERVICE_ANALYSIS'
    | 'CUSTOM_SERVICE_WAITING_TIME'
    | 'CUSTOM_SERVICE_TOLL'
>;

export const SERVICE_PRODUCT_NAME_VALUE = {
    CUSTOM_SERVICE_ACCOMPANYING_DOCUMENT: 'productTemplate.customServiceAccompanyingDocument',
    CUSTOM_SERVICE_ANALYSIS: 'productTemplate.customServiceAnalysis',
    CUSTOM_SERVICE_WAITING_TIME: 'productTemplate.customServiceWaitingTime',
    CUSTOM_SERVICE_TOLL: 'productTemplate.customServiceToll',
} as const satisfies Record<SERVICE_PRODUCT_TYPES_VALUE, string>;

export type SERVICE_PRODUCT_TYPES_PLACEHOLDER = Extract<PRODUCT_TYPES, 'CUSTOM_SERVICE' | 'CUSTOM_SERVICE_TRANSPORT'>;

export const SERVICE_PRODUCT_NAME_PLACEHOLDER = {
    CUSTOM_SERVICE: 'product.service.customService.placeholder',
    CUSTOM_SERVICE_TRANSPORT: 'product.service.customServiceTransport.placeholder',
} as const satisfies Record<SERVICE_PRODUCT_TYPES_PLACEHOLDER, string>;

// This implementation should be refined when we have multiple product channels
export const PRODUCT_CHANNELS = z.enum(['CUSTOM', 'MATERIAL', 'TRANSPORT']);
export type PRODUCT_CHANNELS_TYPES = z.infer<typeof PRODUCT_CHANNELS>;

export const PRODUCT_CHANNEL_TYPES: Record<PRODUCT_TYPES, PRODUCT_CHANNELS_TYPES> = {
    CUSTOM_BULK_MATERIAL: 'CUSTOM',
    CUSTOM_RECYCLED_BULK_MATERIAL: 'CUSTOM',
    CUSTOM_TRANSPORT: 'CUSTOM',
    CUSTOM_CONCRETE: 'CUSTOM',
    CUSTOM_ASPHALT: 'CUSTOM',
    CUSTOM_SERVICE: 'CUSTOM',
    CUSTOM_SERVICE_ACCOMPANYING_DOCUMENT: 'CUSTOM',
    CUSTOM_SERVICE_ANALYSIS: 'CUSTOM',
    CUSTOM_SERVICE_WAITING_TIME: 'CUSTOM',
    CUSTOM_SERVICE_TOLL: 'CUSTOM',
    CUSTOM_SERVICE_TRANSPORT: 'CUSTOM',
    BULK_MATERIAL: 'MATERIAL',
    TRANSPORT: 'TRANSPORT',
    CUSTOM_NON_HAZARDOUS_WASTE_RECYCLING: 'CUSTOM',
    CUSTOM_HAZARDOUS_WASTE_RECYCLING: 'CUSTOM',
    CUSTOM_NON_HAZARDOUS_WASTE_DISPOSAL: 'CUSTOM',
    CUSTOM_HAZARDOUS_WASTE_DISPOSAL: 'CUSTOM',
    NON_HAZARDOUS_WASTE_DISPOSAL: 'MATERIAL',
    HAZARDOUS_WASTE_DISPOSAL: 'MATERIAL',
    NON_HAZARDOUS_WASTE_RECYCLING: 'MATERIAL',
    HAZARDOUS_WASTE_RECYCLING: 'MATERIAL',
};
