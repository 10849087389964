import React from 'react';
import {
    BaseCustomRequestProductProps,
    CustomRequestProduct,
    CustomRequestProductForm,
} from '@/CustomRequestProductChannel/components/CustomRequestProduct.tsx';
import { useMutation } from '@tanstack/react-query';
import {
    patchFulfillmentQuote,
    postFulfillmentQuote,
} from '@/CustomRequestProductChannel/clients/CustomRequestClient.ts';
import { Price } from '@schuettflix/conversion';
import { CustomRequestSuspenseBoundary } from '@/CustomRequestProductChannel/components/CustomRequestSuspenseBoundary.tsx';
import { usePlatformOrganizationId } from '@/shared/hooks/usePlatformOrganizationId';
import { useMarketAwareOrder } from '@/clients/order/useOrder';
import { NestedSelectedProductResponse } from '@schuettflix/interfaces';
import { useFormattedActiveTaxClassesByOrder } from '@/clients/tax-classes/useTaxClasses';
import { useProductQuote } from '@/CustomRequestProductChannel/hooks/useProductQuote';
import { useFulfillmentContext } from '@/modules/fulfillment/context/FulfillmentContext';

type FulfillmentCustomRequestProductProps = Omit<BaseCustomRequestProductProps, 'customerId' | 'taxInfo'> & {
    product: BaseCustomRequestProductProps['product'] & Pick<NestedSelectedProductResponse, 'selectedProductGroupId'>;
    orderId: string;
    onQuoteChange?: () => void;
    onCompleteChange: (productId: string, value: boolean) => void; // shared
};

export const FulfillmentCustomRequestProduct: React.FC<FulfillmentCustomRequestProductProps> = props => {
    const platformOrganizationId = usePlatformOrganizationId();
    const { data: order } = useMarketAwareOrder(props.orderId);
    const orderingOrganizationId = order?.customerInfo?.organizationId;
    const { setCanSubmit } = useFulfillmentContext();

    const { data: quote } = useProductQuote(props.product.id);

    const { mutateAsync: mutateAsyncPostProduct, isPending: postProductIsPending } = useMutation({
        mutationKey: ['selected-product-group', 'product-quote'],
        mutationFn: postFulfillmentQuote,
        onSuccess: () => {
            props.onQuoteChange?.();
        },
    });

    const { mutateAsync: mutateAsyncPatchProduct, isPending: patchProductIsPending } = useMutation({
        mutationKey: ['selected-product-group', 'product-quote'],
        mutationFn: patchFulfillmentQuote,
        onSuccess: () => {
            props.onQuoteChange?.();
        },
    });

    const taxInfo = useFormattedActiveTaxClassesByOrder(props.orderId);

    if (!platformOrganizationId || !orderingOrganizationId) return null;

    const handleSubmit = async (formValues: CustomRequestProductForm) => {
        if (quote?.meta.id && !patchProductIsPending) {
            await mutateAsyncPatchProduct({
                body: {
                    meta: {
                        quote: {
                            orderingOrganizationId,
                            purchaseTaxClassId: formValues.purchaseTaxClassId,
                            salesTaxClassId: formValues.salesTaxClassId,
                            purchasePrice: new Price(formValues.purchasePrice, formValues.currencyCode).toNumber(),
                            amount: formValues.amount,
                            unit: formValues.unit,
                            partnerOrganizationId: formValues.partnerOrganizationId,
                            salesPrice: new Price(formValues.salesPrice, formValues.currencyCode).toNumber(),
                            serviceDate: formValues.serviceDate,
                        },
                        customerInformation: {
                            customerOrganizationId: orderingOrganizationId,
                        },
                        selectedProduct: {
                            productCategory: props.product.category,
                        },
                    },
                    payload: {
                        name: formValues.productName,
                    },
                },
                productId: props.product.id,
            });
            props.onCompleteChange(props.product.id, true);
        } else if (!postProductIsPending) {
            try {
                await mutateAsyncPostProduct({
                    meta: {
                        quote: {
                            orderId: props.orderId,
                            orderingOrganizationId,
                            purchaseTaxClassId: formValues.purchaseTaxClassId,
                            salesTaxClassId: formValues.salesTaxClassId,
                            purchasePrice: new Price(formValues.purchasePrice, formValues.currencyCode).toNumber(),
                            amount: formValues.amount,
                            unit: formValues.unit,
                            partnerOrganizationId: formValues.partnerOrganizationId,
                            platformOrganizationId,
                            currencyCode: formValues.currencyCode,
                            salesPrice: new Price(formValues.salesPrice, formValues.currencyCode).toNumber(),
                            serviceDate: new Date(formValues.serviceDate),
                        },
                        customerInformation: {
                            customerOrganizationId: orderingOrganizationId,
                        },
                        selectedProduct: {
                            productCategory: props.product.category,
                            productType: props.product.type,
                            selectedProductGroupId: props.product.selectedProductGroupId,
                            index: props.product.index,
                        },
                    },
                    payload: {
                        name: formValues.productName,
                    },
                });
                props.onCompleteChange(props.product.id, true);
            } catch (error) {
                console.error('Validation error: ', error);
                props.onCompleteChange(props.product.id, false);
                return;
            }
        }
    };

    return (
        <CustomRequestSuspenseBoundary>
            <CustomRequestProduct
                {...props}
                customerId={orderingOrganizationId}
                taxInfo={taxInfo}
                onFormChange={(...params) => {
                    const [form] = params;
                    if (form.isValid) {
                        void handleSubmit(form.values);
                        setCanSubmit(true);
                    } else {
                        // if we have errors, this section is not valid. By this this callback we trigger uproot that this section is not valid.
                        props?.onCompleteChange(props.product.id, false);
                        setCanSubmit(false);
                    }
                    props.onFormChange?.(...params);
                }}
                restrictFutureServiceDate
            />
        </CustomRequestSuspenseBoundary>
    );
};
