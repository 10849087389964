import { isKeyOf } from '@/shared/utils/isKeyOf.ts';

export interface EnrichedHookFormError {
    field: string;
    message: string;
    scrollToElement: () => void;
}

export const enrichHookFormErrorForFulfillmentSectionErrors = <TFieldName extends string>({
    errors,
    setFocus,
}: {
    errors: Partial<Record<TFieldName, { message?: string } | undefined>>;
    setFocus: (fieldName: Exclude<NoInfer<TFieldName>, 'root'>) => unknown;
}): EnrichedHookFormError[] => {
    return Object.keys(errors).flatMap(field =>
        isKeyOf(errors, field)
            ? {
                  field,
                  message: errors[field]?.message || '',
                  scrollToElement: () => field !== 'root' && field && setFocus(field as Exclude<typeof field, 'root'>),
              }
            : []
    );
};
