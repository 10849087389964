import React, { createContext, ReactNode, useContext, useState } from 'react';

interface Toast {
    title: string;
    message?: string | React.ReactNode;
    closable?: boolean;
    /* seconds */
    timeout?: number;
    className?: string;
    id?: string;
    type: 'warning' | 'error' | 'success' | 'info';
}

type ToasterContextType = {
    toasts: Toast[];
    addToast: (toast: Toast) => void;
    removeToast: (id: string) => void;
};

const ToasterContext = createContext<ToasterContextType | undefined>(undefined);

export const ToasterContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const MAX_TOASTS = 3;
    const [toasts, setToasts] = useState<Toast[]>([]);

    const addToast = (toast: Toast) => {
        const id = toast.id || Math.random().toString(36).substring(7);

        // Remove the oldest one if limit reached
        const strippedToasts = toasts.length >= MAX_TOASTS ? toasts.slice(1) : toasts;

        // If the id of the toast is already in the list, replace it
        const newToasts = [...strippedToasts.filter(entry => entry.id !== id), { ...toast, id }];

        if (!toast.closable) {
            const timeout = toast.timeout || 5;
            setTimeout(() => {
                removeToast(id);
            }, timeout * 1000);
        }

        setToasts(newToasts);
    };

    const removeToast = (id: string) => {
        const newToasts = toasts.filter(toast => toast.id !== id);
        setToasts(newToasts);
    };

    return <ToasterContext.Provider value={{ toasts, addToast, removeToast }}>{children}</ToasterContext.Provider>;
};

export const useToasterContext = () => {
    const context = useContext(ToasterContext);
    if (!context) {
        throw new Error('toasterContext is not initialized');
    }
    return context;
};
