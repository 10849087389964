import {
    FilterOptionsConstructionProject,
    GetConstructionProjectsResponseSchema,
    GetConstructionProjectWithCostCenterResponseSchema,
    GetConstructionProjectWithCostCenterResponse,
    GetConstructionProjectsResponse,
} from './types/constructionProject';
import { getConstructionProjectClient } from '../httpClients';

export async function getConstructionProjectListByOrganizationIdAndStatusIn(
    organizationId: number,
    filterOptions: FilterOptionsConstructionProject
) {
    return getConstructionProjectClient()
        .get<GetConstructionProjectsResponse>(`/admin/v1/organizations/${organizationId}/construction-projects`, {
            params: filterOptions,
        })
        .then(res => GetConstructionProjectsResponseSchema.parse(res.data));
}

export async function getConstructionProjectById(id: string) {
    return getConstructionProjectClient()
        .get<GetConstructionProjectWithCostCenterResponse>(`/admin/v1/construction-projects/${id}`)
        .then(res => GetConstructionProjectWithCostCenterResponseSchema.parse(res.data));
}
