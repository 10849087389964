import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { deletePositionProductGroup, getPositionProductGroups, updatePositionProductGroup } from './position';
import { z } from 'zod';
import { PatchPositionGroupRequestSchema } from '@schuettflix/interfaces';

export const usePositionProductGroups = (positionId: string) => {
    return useQuery({
        queryKey: ['positionProductGroups', positionId],
        queryFn: () => getPositionProductGroups(positionId),
        throwOnError: true,
    });
};

export const useDeletePositionProductGroup = (positionId: string) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ productGroupId }: { productGroupId: string }) =>
            deletePositionProductGroup(positionId, productGroupId),
        onSettled: () => {
            void queryClient.invalidateQueries({ queryKey: ['positionProductGroups', positionId] });
        },
        throwOnError: true,
    });
};

export const useUpdatePositionProductGroup = (positionId: string) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({
            productGroupId,
            productGroup,
        }: {
            productGroupId: string;
            productGroup: z.infer<typeof PatchPositionGroupRequestSchema>;
        }) => updatePositionProductGroup(positionId, productGroupId, productGroup),
        onSettled: () => {
            void queryClient.invalidateQueries({ queryKey: ['positionProductGroups', positionId] });
        },
        throwOnError: true,
    });
};
