import { FieldErrors, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const useFormErrorMessages = (errors: FieldErrors<FieldValues>) => {
    const { t } = useTranslation();

    const getErrorMessage = (key: keyof typeof errors, skip?: boolean) => {
        if (skip) return undefined;
        const error = errors[key];
        return error?.type && !error?.message
            ? t(`errorMessages.form.${error.type as string}`, {
                  ...(error.type === 'isWithinLastTwoCalendarYears'
                      ? {
                            year: new Date().getFullYear() - 2,
                            defaultValue: '',
                        }
                      : { defaultValue: '' }),
              })
            : (error?.message as string) || undefined;
    };

    return {
        getErrorMessage,
    };
};
