import { useSuspenseQuery } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { getPositionProduct } from '../clients/CustomRequestPositionClient';

export const usePositionCustomRequestProduct = (positionProductId: string | null) =>
    useSuspenseQuery({
        queryKey: ['positionCustomRequestProduct', positionProductId],
        queryFn: async () => {
            if (!positionProductId) {
                return null;
            }
            try {
                return await getPositionProduct({ positionProductId });
            } catch (error) {
                // since the query might return a 404, we need to prevent the query to throw an error for that case
                if (isAxiosError(error) && error.response?.status === 404) {
                    return null;
                }
                throw error;
            }
        },
        refetchOnWindowFocus: false,
    });
