import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import React, { useEffect } from 'react';
import { TextField } from '@schuettflix/react-components';

interface ProductGroupNameFormProps {
    disabled?: boolean;
    defaultProductGroupName?: string;
    onProductGroupNameChange?: (value?: string) => void;
}

export const ProductGroupNameForm = ({
    disabled,
    defaultProductGroupName,
    onProductGroupNameChange,
}: ProductGroupNameFormProps) => {
    const { t } = useTranslation();
    const { register, resetField, handleSubmit, getValues } = useForm({
        defaultValues: {
            productGroupName: defaultProductGroupName,
        },
    });

    useEffect(() => {
        if (defaultProductGroupName !== getValues().productGroupName) {
            resetField('productGroupName', {
                defaultValue: defaultProductGroupName,
            });
        }
    }, [defaultProductGroupName, resetField, getValues]);

    const handleBlurSubmit = () => {
        onProductGroupNameChange?.(getValues().productGroupName ?? undefined);
    };

    return (
        <form onBlur={handleSubmit(handleBlurSubmit)} onSubmit={e => e.preventDefault()}>
            <TextField
                {...register('productGroupName')}
                variant="sm"
                placeholder={t('product.productGroup.name')}
                disabled={disabled}
            />
        </form>
    );
};
