import { z } from 'zod';
import { POSITION_STATUS_SCHEMA, ProjectNumberSchema, ProjectPositionNumberSchema } from './shared';

export const PatchPositionUrlSchema = z.object({
    positionId: z.string().uuid(),
});

export const PatchPositionRequestSchema = z.object({
    validFrom: z.coerce.date().optional().nullable(),
    validTo: z.coerce.date().optional().nullable(),
    note: z.string().optional().nullable(),
    active: z.boolean(),
});

export const PatchPositionResponseSchema = z.strictObject({
    id: z.string().uuid(),
    projectNumber: ProjectNumberSchema,
    positionNumber: ProjectPositionNumberSchema,
    marketCode: z.string(),
    status: POSITION_STATUS_SCHEMA,
    validFrom: z.coerce.date().optional().nullable(),
    validTo: z.coerce.date().optional().nullable(),
    note: z.string().optional().nullable(),
    active: z.boolean(),
    orderCount: z.number(),
});
