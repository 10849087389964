import { PositionWizard } from '@/modules/position/components/PositionWizard';
import { LoadingSpinner } from '@schuettflix/react-components';
import { Suspense, useEffect, useRef } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DocumentTitle } from '@/shared/components/DocumentTitle.tsx';
import { useStartEditPosition } from '@/clients/position/useStartEditPosition.ts';

function usePositionEditReady() {
    const { positionId, temporaryId } = useParams<{ positionId: string; temporaryId: string }>();
    const [searchParams] = useSearchParams();
    const isPendingRef = useRef(false);
    const navigate = useNavigate();

    const { mutateAsync: startEditPosition } = useStartEditPosition(positionId!, args => {
        const section = searchParams.get('section');
        navigate(`/position/${args.positionId}/edit/${args.temporaryId}?section=${section}`, {
            replace: true,
        });
    });

    useEffect(() => {
        if (temporaryId === undefined && positionId !== undefined && !isPendingRef.current) {
            isPendingRef.current = true;
            void startEditPosition();
        }
    }, [temporaryId, positionId]);

    return {
        positionId,
        temporaryId,
        isPositionEditingReady: positionId !== undefined && temporaryId !== undefined,
    };
}

export function PositionEditPage() {
    const { t } = useTranslation();
    const { isPositionEditingReady, temporaryId } = usePositionEditReady();

    return (
        <>
            <DocumentTitle title={t('positions.edit-position')} />
            <Suspense
                fallback={
                    <div className="flex h-screen items-center justify-center">
                        <LoadingSpinner />
                    </div>
                }
            >
                {isPositionEditingReady && temporaryId && <PositionWizard positionId={temporaryId} />}
            </Suspense>
        </>
    );
}
