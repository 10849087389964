import { z } from 'zod';

export const PostPositionStartEditUrlSchema = z.strictObject({
    positionId: z.string().uuid(),
});

export const PostPositionStartEditResponseSchema = z.strictObject({
    positionId: z.string().uuid(),
    temporaryId: z.string().uuid(),
});

export type PostPositionStartEditResponse = z.infer<typeof PostPositionStartEditResponseSchema>;
