import { z } from 'zod';

export const GetOrderPaymentSettingsUrlPathParametersSchema = z.object({
    orderId: z.string().uuid(),
});

export const GetOrderPaymentSettingsQueryParameterSchema = z
    .object({
        organizationIds: z.string(),
    })
    .transform(data => {
        return data.organizationIds.split(',').map(Number);
    });
