import { useFormatters } from '@/shared/hooks/useFormatters/useFormatters.ts';
import { DeleteIcon } from '@schuettflix/icons-react';
import React, { Key, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Price } from '@schuettflix/conversion';
import { SUPPORTED_UNIT_LABEL } from '@schuettflix/interfaces';
import { Button, Modal, ModalTrigger } from '@schuettflix/planum-react';
import { CloseIcon } from '@schuettflix/planum-icons-react';
import { SectionSummary } from '@/shared/components/SectionSummary.tsx';
import { ProductGroupsProps } from './ProductGroups';
import { CustomRequestProductQuote } from '@/CustomRequestProductChannel/components/CustomRequestProduct';

interface ProductSelectionSummaryProps {
    selectedProductGroups: ProductGroupsProps['selectedProductGroups'];
    onEdit: () => void;
    rowRenderer: ({ productId }: { productId: string }) => React.ReactNode;
    onDelete: (productGroupId: string) => Promise<void>;
    disableDelete?: boolean;
}

export const ProductSelectionSummary: React.FC<ProductSelectionSummaryProps> = ({
    onEdit,
    onDelete,
    rowRenderer: Row,
    selectedProductGroups,
    disableDelete = false,
}) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isLoadingDeletion, setIsLoadingDeletion] = useState(false);

    const { t } = useTranslation();

    return (
        <SectionSummary title={`2. ${t('sections.productGroupSelection.title')}`} editable onEditClick={onEdit}>
            <div>
                {selectedProductGroups.map((productGroup, index: Key | null | undefined) => (
                    <div key={index} className="flex flex-row-reverse justify-between">
                        <div className="flex w-full flex-col justify-between">
                            <div className="text mb-2 flex justify-between">
                                <span>{productGroup.name ?? `Product ${productGroup.index + 1}`}</span>
                                {!disableDelete && (
                                    <div>
                                        <ModalTrigger isOpen={isModalOpen} onOpenChange={setIsModalOpen}>
                                            <DeleteIcon
                                                size="xs"
                                                monochrome
                                                className="cursor-pointer"
                                                onClick={() => setIsModalOpen(true)}
                                            />
                                            <Modal>
                                                {({ close }) => (
                                                    <>
                                                        <Modal.Heading onAbort={close}>
                                                            {t('sections.productGroupSelection.askProductDeletion')}
                                                        </Modal.Heading>
                                                        <Modal.Content>
                                                            {t('sections.productGroupSelection.productDeletionHint')}
                                                        </Modal.Content>
                                                        <Modal.Actions>
                                                            <Button
                                                                onPress={close}
                                                                prominence="secondary"
                                                                leadingSlot={<CloseIcon />}
                                                            >
                                                                {t(
                                                                    'sections.productGroupSelection.cancelProductDeletion'
                                                                )}
                                                            </Button>
                                                            <Button
                                                                isLoading={isLoadingDeletion}
                                                                onPress={async () => {
                                                                    if (isLoadingDeletion) return;
                                                                    setIsLoadingDeletion(true);
                                                                    try {
                                                                        await onDelete(productGroup.id);
                                                                        close();
                                                                    } catch (error) {
                                                                        console.error(error);
                                                                    }
                                                                    setIsLoadingDeletion(false);
                                                                }}
                                                            >
                                                                {t(
                                                                    'sections.productGroupSelection.confirmProductDeletion'
                                                                )}
                                                            </Button>
                                                        </Modal.Actions>
                                                    </>
                                                )}
                                            </Modal>
                                        </ModalTrigger>
                                    </div>
                                )}
                            </div>
                            <div>
                                {productGroup.selectedProducts?.flatMap(product => (
                                    <Row key={product.id} productId={product.id} />
                                ))}
                                {selectedProductGroups.length - 1 !== index && (
                                    <div className="border-b-dark-hovered my-6">
                                        <hr className="border-t" />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </SectionSummary>
    );
};

export function ProductSelectionSummaryRow({ quote }: { quote?: CustomRequestProductQuote }) {
    const { formatNumberWithCurrencyPerUnit, formatNumber } = useFormatters();
    const { t } = useTranslation();
    return (
        !!quote && (
            <div className="grid grid-cols-3">
                <div className="text-subdued font-copy-sm">
                    {quote.amount !== undefined ? (
                        <span>{`${formatNumber(quote.amount)} ${t(SUPPORTED_UNIT_LABEL[quote.unit])} ${quote.name}`}</span>
                    ) : null}
                </div>
                <div className="text-subdued font-copy-sm flex justify-end">
                    <span>
                        {formatNumberWithCurrencyPerUnit(
                            new Price(quote.purchasePrice, quote.currencyCode).toNumber(),
                            quote.unit,
                            quote.currencyCode
                        )}
                    </span>
                </div>
                <div className="text-subdued font-copy-sm flex justify-end">
                    <span>
                        {formatNumberWithCurrencyPerUnit(
                            new Price(quote.salesPrice, quote.currencyCode).toNumber(),
                            quote.unit,
                            quote.currencyCode
                        )}
                    </span>
                </div>
            </div>
        )
    );
}
