import z from 'zod';

export const ResponsibleUserSchema = z.object({
    id: z.number().optional().nullable(),
    firstName: z.string().optional().nullable(),
    lastName: z.string().optional().nullable(),
    imageUrl: z.any(),
});

export const ConstructionProjectStatusSchema = z.enum(['ACTIVE', 'INACTIVE', 'ARCHIVED']);

export const GetConstructionProjectResponseSchema = z.object({
    id: z.string().uuid(),
    name: z.string(),
    description: z.string().optional().nullable(),
    responsibleUser: ResponsibleUserSchema,
    country: z.string().optional().nullish(),
    state: z.string().optional().nullable(),
    city: z.string().optional().nullable(),
    zip: z.string().optional().nullable(),
    street: z.string().optional(),
    addressNumber: z.string().optional(),
    projectCode: z.string().optional(),
    status: ConstructionProjectStatusSchema,
    validFrom: z.string().optional().nullable(),
    validTo: z.string().optional().nullable(),
});

export const GetConstructionProjectWithCostCenterResponseSchema = z.object({
    ...GetConstructionProjectResponseSchema.shape,
    costCenter: z.string().optional(),
});

export const GetConstructionProjectsResponseSchema = z.object({
    constructionProjects: z.array(GetConstructionProjectResponseSchema),
});

export type ConstructionProject = z.infer<typeof GetConstructionProjectResponseSchema>;
export type ConstructionProjectWithCostCenter = z.infer<typeof GetConstructionProjectWithCostCenterResponseSchema>;
export type GetConstructionProjectWithCostCenterResponse = z.infer<
    typeof GetConstructionProjectWithCostCenterResponseSchema
>;
export type ConstructionProjectStatus = z.infer<typeof ConstructionProjectStatusSchema>;
export type GetConstructionProjectsResponse = z.infer<typeof GetConstructionProjectsResponseSchema>;
export type FilterOptionsConstructionProject = {
    statuses?: ConstructionProjectStatus[];
};
