import { z } from 'zod';

/**
 * abstract quote validation schema for post-quote-order request (to fulfillment channel) payload
 */
export const AbstractPostQuoteOrderRequestSchema = z.strictObject({
    meta: z.strictObject({
        // callback information
        callbackUrl: z.string().url(),
    }),
});
