import { User } from './Users';
import { getMonolithClient } from '../httpClients';
import { FilterOptionsUser } from './types/users';

export async function filter(filterOptions: FilterOptionsUser) {
    return getMonolithClient()
        .get<{ items: User[] }>('/v2/user', { params: filterOptions })
        .then(res => res.data);
}

export async function getUserById(id: number) {
    return getMonolithClient()
        .get<User>(`/v2/user/${id}`)
        .then(res => res.data);
}

export * from './types/users';
