import { PRODUCT_GROUP_TYPE } from '@schuettflix/interfaces';
import { ProductGroupNameForm } from '@/shared/components/ProductGroupHeader/components/ProductGroupNameForm.tsx';
import {
    ProductGroupBalance,
    ProductGroupBalanceProps,
} from '@/shared/components/ProductGroupHeader/components/ProductGroupBalance.tsx';

export type ProductGroupHeaderProps = {
    products: ProductGroupBalanceProps['products'];
    productGroupTemplateId: string;
    productGroupType: PRODUCT_GROUP_TYPE;
    productGroupName?: string;
    productGroupNameDisabled?: boolean;
    onProductGroupNameChange?: (updatedProductGroupName?: string) => void;
};

export const ProductGroupHeader = ({
    products,
    productGroupType,
    productGroupTemplateId,
    productGroupName,
    productGroupNameDisabled,
    onProductGroupNameChange,
}: ProductGroupHeaderProps) => {
    return (
        <section className="grid grid-cols-[1fr,3fr] gap-4">
            <ProductGroupNameForm
                disabled={productGroupNameDisabled}
                defaultProductGroupName={productGroupName}
                onProductGroupNameChange={onProductGroupNameChange}
            />

            <ProductGroupBalance
                products={products}
                productGroupType={productGroupType}
                productGroupTemplateId={productGroupTemplateId}
            />
        </section>
    );
};
