import z from 'zod';
import { PRODUCT_TYPE_SCHEMA } from '../product-type/product-types.ts';
import {
    TEMPLATE_GROUP_DESCRIPTION_SCHEMA,
    TEMPLATE_GROUP_TITLE_SCHEMA,
    TEMPLATE_TYPE_SCHEMA,
} from './template-type.ts';
import { SUPPORTED_UNITS_SCHEMA } from '../quote-unit/unit-types.ts';
import { PRODUCT_CATEGORY_SCHEMA } from '../selected-product/product-category.ts';

export const PreFillDataSchema = z.object({
    unit: SUPPORTED_UNITS_SCHEMA.optional().nullable(),
});

export const TemplateProductSchema = z.strictObject({
    id: z.string().uuid(),
    type: PRODUCT_TYPE_SCHEMA,
    category: PRODUCT_CATEGORY_SCHEMA,
    //TODO this is effectively "any" now, but fixing it breaks other parts of the code
    prefilledData: z.any().parse(PreFillDataSchema.optional().nullable()),
    templateId: z.string().optional().nullable(),
});

export const TemplateCategorySchema = z.enum(['ORDER', 'POSITION']);

export const GetTemplateUrlSchema = z.strictObject({
    templateId: z.string().uuid(),
});

export const TemplateSchema = z.strictObject({
    id: z.string().uuid(),
    templateGroupId: z.string().uuid(),
    name: TEMPLATE_GROUP_TITLE_SCHEMA,
    type: TEMPLATE_TYPE_SCHEMA,
    description: TEMPLATE_GROUP_DESCRIPTION_SCHEMA.optional().nullable(),
    preSelectedProducts: z.array(TemplateProductSchema),
    allowedProducts: z.array(TemplateProductSchema),
    templateCategories: z.array(TemplateCategorySchema),
});

export const GetTemplateResponseSchema = TemplateSchema;

export type Template = z.infer<typeof TemplateSchema>;
export type TemplateProduct = z.infer<typeof TemplateProductSchema>;
export type TemplateCategory = z.infer<typeof TemplateCategorySchema>;
export type GetTemplateResponse = z.infer<typeof GetTemplateResponseSchema>;

export const TEMPLATE_CATEGORIES = {
    ORDER: TemplateCategorySchema.enum.ORDER,
    POSITION: TemplateCategorySchema.enum.POSITION,
} as const;
