import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { AuthGuard } from '@/routes/AuthGuard.tsx';
import { Main } from '@/modules/main/Main.tsx';
import { OrderPage } from '@/modules/order/OrderPage.tsx';
import { PositionPage } from '@/modules/position/PositionPage.tsx';
import { FulfillmentPage } from '@/modules/fulfillment/FulfillmentPage.tsx';
import { LoginCallback } from '@okta/okta-react';
import { FullscreenLoading } from '@/shared/components/FullscreenLoading.tsx';
import { PositionEditPage } from '@/modules/position/PositionEditPage.tsx';

export const AuthenticatedRoutes: React.FC = () => {
    return (
        <Routes>
            <Route path="/" element={<AuthGuard />}>
                <Route path="" element={<Main />} />
                <Route path="order/:orderId" element={<OrderPage />} />
                <Route path="position/:positionId/edit/:temporaryId?" element={<PositionEditPage />} />
                <Route path="position/:positionId" element={<PositionPage />} />
                <Route path="fulfillment/:orderId" element={<FulfillmentPage />} />
            </Route>

            <Route path="/login/callback" element={<LoginCallback loadingElement={<FullscreenLoading />} />} />
        </Routes>
    );
};
