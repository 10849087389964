import { z } from 'zod';
import { SUPPORTED_UNITS_SCHEMA } from '../quote-unit/unit-types.ts';
import { PRODUCT_CATEGORY_SCHEMA } from '../selected-product/product-category.ts';
import { PRODUCT_TYPE_SCHEMA } from '../product-type/product-types.ts';

export const PostQuoteFulfillmentRequestMetaSchema = z.strictObject({
    quote: z.object({
        orderId: z.string().uuid(),
        amount: z.number(),
        unit: SUPPORTED_UNITS_SCHEMA,
        serviceDate: z.coerce.date(),
        partnerOrganizationId: z.number().int().positive(),
        orderingOrganizationId: z.number().int().positive(),
        platformOrganizationId: z.number().int().positive(),
        purchasePrice: z.number(),
        purchaseTaxClassId: z.string(),
        salesPrice: z.number(),
        salesTaxClassId: z.string(),
        currencyCode: z.string(),
    }),
    customerInformation: z.object({
        customerOrganizationId: z.number(),
    }),
    selectedProduct: z.object({
        selectedProductGroupId: z.string().uuid(),
        productCategory: PRODUCT_CATEGORY_SCHEMA,
        productType: PRODUCT_TYPE_SCHEMA,
        index: z.number(),
    }),
});

export const PostQuoteFulfillmentRequestSchema = z.strictObject({
    meta: PostQuoteFulfillmentRequestMetaSchema,
    payload: z.object({
        name: z.string(),
    }),
});
