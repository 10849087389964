import React, { useEffect } from 'react';
import { useOrganization } from '@/clients/organization/useOrganization.ts';
import { SelectOrganization } from '@/shared/components/SelectOrganization.tsx';
import { useFormatters } from '@/shared/hooks/useFormatters/useFormatters.ts';
import { useZodForm } from '@/shared/hooks/useZodForm.ts';
import { ReactHookFormDevTool } from '@/shared/components/ReactHookFormDevTool.tsx';
import { Price } from '@schuettflix/conversion';
import {
    GetProductGroupListResponse,
    NestedSelectedProductResponse,
    PRODUCT_CATEGORY_LABEL,
    PRODUCT_NAME_LABEL,
    PRODUCT_NAME_PLACEHOLDER_LABEL,
    SERVICE_PRODUCT_NAME_PLACEHOLDER,
    SERVICE_PRODUCT_NAME_VALUE,
    SERVICE_PRODUCT_TYPES_PLACEHOLDER,
    SUPPORTED_UNIT_LABEL,
    SUPPORTED_UNITS,
    SUPPORTED_UNITS_SCHEMA,
    CURRENCY_DECIMALS,
    CURRENCY_SCHEMA,
} from '@schuettflix/interfaces';
import { DatePicker, NumberField } from '@schuettflix/planum-react';
import { parseDate } from '@internationalized/date';
import { Combobox, TextField } from '@schuettflix/react-components';
import { format, isValid as isValidDate, parseISO } from 'date-fns';
import { Controller, FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { DeleteIcon } from '@schuettflix/icons-react';
import { useTemplate } from '@/clients/template/useTemplate.ts';
import { FormattedActiveTaxClasses } from '@/clients/tax-classes/useTaxClasses.ts';
import { useFeatureFlag } from '@/tools/featureFlags/useFeatureFlag.ts';
import { validateNegativePrice } from '@/CustomRequestProductChannel/utils/validateNegativePrice.ts';
import {
    EnrichedHookFormError,
    enrichHookFormErrorForFulfillmentSectionErrors,
} from '@/CustomRequestProductChannel/utils/enrichHookFormErrorForFulfillmentSectionErrors.ts';
import { getProductCategoryIcon } from '@/CustomRequestProductChannel/utils/getProductCategoryIcon.ts';
import { useSupportedUnitNamesByProductType } from '@/CustomRequestProductChannel/hooks/useSupportedUnitNamesByProductType.ts';
import { cn } from '@/shared/utils/cn.ts';

function useCustomRequestProductSchema() {
    const enableNegativePricesFeatureFlag = useFeatureFlag<boolean>('enableNegativePrices');
    const { t } = useTranslation();

    const schema = z.object({
        amount: z
            .number({ required_error: t('product.addPosition.errorMessage.pleaseEnterValue') })
            .min(0)
            .positive(),
        purchasePrice: z
            .string({ required_error: t('product.addPosition.errorMessage.pleaseEnterValue') })
            .min(1)
            .refine(
                validateNegativePrice(enableNegativePricesFeatureFlag),
                t('product.addPosition.errorMessage.negativePrices')
            ),
        salesPrice: z
            .string({ required_error: t('product.addPosition.errorMessage.pleaseEnterValue') })
            .min(1)
            .refine(
                validateNegativePrice(enableNegativePricesFeatureFlag),
                t('product.addPosition.errorMessage.negativePrices')
            ),
        unit: SUPPORTED_UNITS_SCHEMA.refine(unit => unit, t('product.addPosition.errorMessage.pleaseEnterValue')),
        partnerOrganizationId: z
            .number({ required_error: t('product.addPosition.errorMessage.pleaseEnterValue') })
            .int()
            .positive(),
        serviceDate: z.string().refine(date => isValidDate(parseISO(date)), {
            message: t('product.addPosition.errorMessage.pleaseEnterValue'),
        }),
        purchaseTaxClassId: z.string({ required_error: t('product.addPosition.errorMessage.pleaseEnterValue') }),
        salesTaxClassId: z.string({ required_error: t('product.addPosition.errorMessage.pleaseEnterValue') }),
        productName: z.string({ required_error: t('product.addPosition.errorMessage.pleaseEnterValue') }).min(1),
        currencyCode: CURRENCY_SCHEMA,
    });

    return schema;
}

export type CustomRequestProduct = Pick<
    NestedSelectedProductResponse,
    'id' | 'category' | 'type' | 'positionProductId' | 'index'
>;
export type CustomRequestProductForm = z.input<ReturnType<typeof useCustomRequestProductSchema>>;
export type FormErrors = FieldErrors<CustomRequestProductForm>;
type FieldErrorKey = keyof CustomRequestProductForm;

export type CustomRequestProductQuote = Pick<
    NonNullable<NestedSelectedProductResponse['quote']>,
    | 'partnerOrganizationId'
    | 'unit'
    | 'purchasePrice'
    | 'salesPrice'
    | 'purchaseTaxClassId'
    | 'currencyCode'
    | 'salesTaxClassId'
> & {
    id?: string;
    amount?: number;
    serviceDate?: NonNullable<NestedSelectedProductResponse['quote']>['serviceDate'];
    name: string;
};

type FormFieldSettings = Partial<
    Record<
        keyof CustomRequestProductQuote,
        {
            // to overwrite the label of the field
            label?: string;
            // to make the field optional in the validation
            isOptional?: boolean;
            // to hide the field in the UI
            isHidden?: boolean;
            isInvalid?: boolean;
        }
    >
>;

export interface BaseCustomRequestProductProps {
    // To intersect the schema with the form schema (extend schema)
    intersectionSchema?: z.ZodObject<{
        salesPrice: z.ZodEffects<z.ZodString, string, string>;
    }>;
    // Leading values are always overwriting the form values
    leadingValues?: Partial<Pick<CustomRequestProductForm, 'amount' | 'unit'>>;
    product: CustomRequestProduct;
    quote?: CustomRequestProductQuote;
    customerId: number;
    showValidation: boolean; // shared
    showProductPriceAddedHint?: boolean; // shared
    showProductPriceNotAddedHint?: boolean; // shared
    enableDelete?: boolean; // shared
    disabled?: boolean; // shared
    disableAmount?: boolean; // shared
    disableUnit?: boolean; // shared
    taxInfo?: FormattedActiveTaxClasses;
    formFieldSettings?: FormFieldSettings;
    onFormChange?: (
        form: (
            | { values: Partial<CustomRequestProductForm>; isValid: false }
            | { values: CustomRequestProductForm; isValid: true }
        ) & {
            productId: CustomRequestProduct['id'];
        }
    ) => void; // shared
    onBeforeFormChange?: (values: Partial<CustomRequestProductForm>) => void; // shared
    onDeleteProduct?: (productId: string) => void; // shared
    templateId?: GetProductGroupListResponse['templateId']; // shared
    restrictFutureServiceDate?: boolean; // shared
    onErrorsChange?: (errors: EnrichedHookFormError[]) => void;
}

export const CustomRequestProduct: React.FC<BaseCustomRequestProductProps> = ({
    intersectionSchema: IntersectionSchema,
    leadingValues,
    product,
    quote,
    formFieldSettings,
    templateId,
    customerId,
    taxInfo,
    showValidation,
    showProductPriceAddedHint = false,
    showProductPriceNotAddedHint = false,
    enableDelete,
    disabled = false,
    disableAmount = false,
    disableUnit = false,
    onFormChange,
    onBeforeFormChange,
    onDeleteProduct,
    onErrorsChange,
    restrictFutureServiceDate = false,
}) => {
    const enableNegativePricesFeatureFlag = useFeatureFlag<boolean>('enableNegativePrices');
    const { t } = useTranslation();
    const { data: organization } = useOrganization(customerId);
    const { getCurrencySymbol } = useFormatters();
    const { data: template } = useTemplate(templateId);
    const { data: supportedUnitNames } = useSupportedUnitNamesByProductType(product.type);

    const CustomRequestProductSchema = useCustomRequestProductSchema();
    const CustomRequestFormSchema = IntersectionSchema
        ? z.intersection(CustomRequestProductSchema, IntersectionSchema)
        : CustomRequestProductSchema;

    const defaultProductName =
        product.type in SERVICE_PRODUCT_NAME_VALUE ? t(PRODUCT_NAME_LABEL[product.type]) : undefined;

    const defaultValues = {
        amount: leadingValues?.amount ?? quote?.amount,
        unit:
            leadingValues?.unit ??
            quote?.unit ??
            (template?.allowedProducts.find(({ type }) => type === product.type)?.prefilledData?.unit as
                | SUPPORTED_UNITS
                | undefined) ??
            (template?.preSelectedProducts.find(({ type }) => type === product.type)?.prefilledData?.unit as
                | SUPPORTED_UNITS
                | undefined) ??
            undefined,
        partnerOrganizationId: quote?.partnerOrganizationId ?? undefined,
        purchasePrice: quote
            ? new Price(quote.purchasePrice, CURRENCY_SCHEMA.parse(quote.currencyCode)).toString()
            : undefined,
        purchaseTaxClassId: quote?.purchaseTaxClassId ?? taxInfo?.defaultTaxClass,
        salesPrice: quote
            ? new Price(quote.salesPrice, CURRENCY_SCHEMA.parse(quote.currencyCode)).toString()
            : undefined,
        salesTaxClassId: quote?.salesTaxClassId ?? taxInfo?.defaultTaxClass,
        serviceDate: quote?.serviceDate ? format(new Date(quote.serviceDate), 'yyyy-MM-dd') : '',
        productName: quote?.name ?? defaultProductName,
        currencyCode: organization?.market.currencyCode ?? 'EUR',
    };

    const { handleSubmit, control, formState, register, trigger, setValue, getValues, watch, setFocus, getFieldState } =
        useZodForm({
            schema: CustomRequestFormSchema,
            defaultValues: formFieldSettings
                ? {
                      ...defaultValues,
                      ...Object.fromEntries(
                          Object.entries(formFieldSettings)
                              .filter(([_, { isOptional }]) => isOptional)
                              .map(([name]) => {
                                  // this allows to redefine via the formFieldSettings the mentioned fields to be optional
                                  // these "optional" fields are still required for the schema validation and therefore are directly assigned
                                  // with valid default values. This fakes an optional behavior for the mentioned fields.
                                  // this works for all fields but during the time of creation this mechanism is was only necessary for the serviceDate field in positions.
                                  const defaultValuesToFakeOptionalBehavior: z.input<
                                      typeof CustomRequestProductSchema
                                  > = {
                                      amount: 1,
                                      unit: 'TON',
                                      partnerOrganizationId: 1,
                                      purchasePrice: '1',
                                      purchaseTaxClassId: 'S-DE',
                                      salesPrice: '1',
                                      salesTaxClassId: 'S-DE',
                                      serviceDate: '2024-01-01',
                                      productName: 'Undefined',
                                      currencyCode: 'EUR',
                                  };
                                  return [
                                      name,
                                      defaultValuesToFakeOptionalBehavior[
                                          name as keyof z.input<typeof CustomRequestProductSchema>
                                      ],
                                  ];
                              })
                      ),
                  }
                : defaultValues,
            mode: 'onChange',
        });
    const { errors } = formState;

    const formValues = getValues();
    const { data: partnerOrganization } = useOrganization(formValues.partnerOrganizationId);

    useEffect(() => {
        const subscription = watch(values => {
            onBeforeFormChange?.(values);
            try {
                CustomRequestFormSchema.parse(values);
                if (values.serviceDate && validateServiceDateConsideringFutureRestriction(values.serviceDate)) {
                    throw new Error('Service date is in the future');
                }
                void handleSubmit(
                    // fires when the form is really valid without errors
                    values => {
                        onFormChange?.({ values, isValid: true, productId: product.id });
                    },
                    // fires when the form is invalid and has errors (and shows validation to the user)
                    () => {
                        onFormChange?.({ values, isValid: false, productId: product.id });
                    }
                )();
                // In case the schema parsing failed we know that the current form is invalid
                // the parse() throws an error
            } catch {
                onFormChange?.({ values, isValid: false, productId: product.id });
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    useEffect(() => {
        const values = getValues();
        onBeforeFormChange?.(values);
        try {
            if (values.serviceDate && validateServiceDateConsideringFutureRestriction(values.serviceDate)) {
                throw new Error('Service date is in the future');
            }
            CustomRequestFormSchema.parse(values);
            onFormChange?.({ values, isValid: true, productId: product.id });
        } catch {
            onFormChange?.({ values, isValid: false, productId: product.id });
        }
    }, []);

    const validateServiceDateConsideringFutureRestriction = (date: string) => {
        return restrictFutureServiceDate && date && new Date(date) > new Date();
    };

    const getFormError = (fieldName: FieldErrorKey) => {
        return errors[fieldName];
    };

    const getFormErrorMessage = (fieldName: FieldErrorKey) => {
        const formError = getFormError(fieldName);
        return formError?.message;
    };

    const isNegativePurchasePrice = Number(formValues.purchasePrice) < 0;
    const isNegativeSalesPrice = Number(formValues.salesPrice) < 0;
    const isZeroPurchasePrice = Number(formValues.purchasePrice) === 0;
    const isZeroSalesPrice = Number(formValues.salesPrice) === 0;

    useEffect(() => {
        onErrorsChange?.(enrichHookFormErrorForFulfillmentSectionErrors({ errors, setFocus }));
    }, [
        errors.salesPrice,
        errors.purchasePrice,
        errors.amount,
        errors.unit,
        errors.productName,
        errors.partnerOrganizationId,
        errors.serviceDate,
        errors.purchaseTaxClassId,
        errors.salesTaxClassId,
    ]);

    useEffect(() => {
        if (showValidation) {
            trigger().catch(e => console.error(e));
        }
    }, [showValidation, trigger]);

    useEffect(() => {
        (['amount', 'unit'] as const).map(field => {
            if (leadingValues?.[field]) {
                setValue(field, leadingValues[field], { shouldDirty: true, shouldValidate: true });
            }
        });
    }, [leadingValues?.amount, leadingValues?.unit, setValue]);

    useEffect(() => {
        if (getFieldState('salesPrice').isDirty) {
            trigger('salesPrice').catch(e => console.error(e));
        }
    }, [formFieldSettings?.salesPrice?.isInvalid]);

    const PriceFieldSuffix = () => {
        const unit = getValues('unit');

        return (
            <NumberField.Suffix>{`${getCurrencySymbol(organization?.market.currencyCode)}${
                unit ? ' / ' + t(SUPPORTED_UNIT_LABEL[unit]) : ''
            }`}</NumberField.Suffix>
        );
    };

    return (
        <>
            <form className="focus-within:bg-hovered group px-4 py-8">
                <div className="flex flex-col gap-4">
                    <p className="font-copy-sm-strong uppercase [counter-increment:productCounter] before:content-[counter(productCounter)'.\0020']">
                        {t(PRODUCT_CATEGORY_LABEL[product.category])}
                    </p>
                    <div className="flex md:grid md:grid-cols-[2fr_3fr]">
                        <TextField
                            {...register('productName')}
                            variant="sm"
                            className="flex-grow"
                            placeholder={
                                product.type in SERVICE_PRODUCT_NAME_PLACEHOLDER
                                    ? t(
                                          SERVICE_PRODUCT_NAME_PLACEHOLDER[
                                              product.type as SERVICE_PRODUCT_TYPES_PLACEHOLDER
                                          ]
                                      )
                                    : t(PRODUCT_NAME_PLACEHOLDER_LABEL[product.category])
                            }
                            isCritical={!!getFormError('productName')}
                            disabled={disabled}
                            errorMessage={getFormErrorMessage('productName')}
                            leadingIcon={getProductCategoryIcon(product.category)}
                            data-test={`order-product-name-${product.category}`}
                        />
                        <div className="flex flex-shrink-0 items-start justify-end pl-4 pt-4">
                            <DeleteIcon
                                monochrome
                                className={!enableDelete ? 'cursor-not-allowed' : 'cursor-pointer'}
                                disabled={!enableDelete}
                                onClick={() => onDeleteProduct?.(product.id)}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col gap-4 md:flex-row [&>*]:flex-1">
                        <div>
                            <div className="grid grid-cols-9 gap-0">
                                <Controller
                                    render={({ field: { onChange, value, ...field } }) => (
                                        <NumberField
                                            {...field}
                                            value={Number(value)}
                                            stature="sm"
                                            onChange={value => {
                                                onChange(Number.isNaN(value) ? undefined : Number(value));
                                            }}
                                            isInvalid={!!getFormError('amount')}
                                            errorMessage={getFormErrorMessage('amount')}
                                            isDisabled={disableAmount || disabled}
                                            className={cn(
                                                'relative col-span-5 -mr-px focus-within:z-10 hover:z-10 [&_.planum-input-module-wrapper]:rounded-r-none',
                                                { 'z-10': !!getFormError('amount') }
                                            )}
                                            formatOptions={{
                                                maximumFractionDigits: 3,
                                                minimumFractionDigits: 0,
                                            }}
                                            placeholder={
                                                formFieldSettings?.amount?.label ?? t('product.quote.quantity')
                                            }
                                            data-test={`order-product-amount-${product.category}`}
                                        />
                                    )}
                                    name={'amount'}
                                    control={control}
                                />
                                <Controller
                                    name="unit"
                                    control={control}
                                    render={({ field: { ref: _, ...field } }) => (
                                        <Combobox
                                            className={`col-span-4 cy-order-product-unit-${product.category}`}
                                            {...field}
                                            options={(supportedUnitNames ?? []).map(unit => {
                                                return {
                                                    value: unit,
                                                    label: t(SUPPORTED_UNIT_LABEL[unit]),
                                                };
                                            })}
                                            placeholder={t('product.quote.unit')}
                                            disabled={disableUnit || disabled}
                                            variant="sm"
                                            buttonClassName="rounded-l-none"
                                            errorMessage={getFormErrorMessage('unit')}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div>
                            <div className="grid grid-cols-1 gap-0">
                                <Controller
                                    name={'partnerOrganizationId'}
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <SelectOrganization
                                            {...field}
                                            selectedOrganizationId={field.value}
                                            placeholder={t('product.addPosition.partner.label')}
                                            filters={{
                                                isActive: true,
                                                isBlocked: false,
                                                status: ['approved'],
                                            }}
                                            critical={!!fieldState.error}
                                            helperText={t('product.addPosition.errorMessage.pleaseEnterValue')}
                                            disabled={disabled}
                                            size="sm"
                                            excludeMatchingIds={{ id: customerId }}
                                            dataTest={`order-product-partner-organization-${product.category}`}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div>
                            <div className="grid grid-cols-5 gap-0">
                                <Controller
                                    name="purchasePrice"
                                    control={control}
                                    render={({ field: { onChange, value, ...field } }) => (
                                        <NumberField
                                            {...field}
                                            className={cn(
                                                'relative col-span-3 -mr-px focus-within:z-10 hover:z-10 [&_.planum-input-module-wrapper]:rounded-r-none',
                                                { 'z-10': !!getFormError('purchasePrice') }
                                            )}
                                            value={Number(value)}
                                            stature="sm"
                                            trailingSlot={<PriceFieldSuffix />}
                                            onChange={value => {
                                                onChange(Number.isNaN(value) ? undefined : String(value));
                                            }}
                                            isDisabled={disabled}
                                            isInvalid={!!getFormError('purchasePrice')}
                                            errorMessage={getFormErrorMessage('purchasePrice')}
                                            placeholder={t('product.quote.purchasePrice')}
                                            formatOptions={{
                                                maximumFractionDigits:
                                                    CURRENCY_DECIMALS[organization!.market.currencyCode],
                                                minimumFractionDigits:
                                                    CURRENCY_DECIMALS[organization!.market.currencyCode],
                                            }}
                                            data-test={`order-product-purchase-price-${product.category}`}
                                        />
                                    )}
                                />

                                <Controller
                                    name="purchaseTaxClassId"
                                    control={control}
                                    render={({ field: { ref: _, ...field } }) => (
                                        <Combobox
                                            className="col-span-2"
                                            {...field}
                                            options={taxInfo?.taxClassOptions || []}
                                            placeholder={t('product.quote.tax')}
                                            disabled={disabled}
                                            variant="sm"
                                            buttonClassName="rounded-l-none"
                                            errorMessage={getFormErrorMessage('purchaseTaxClassId')}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div>
                            <div className="grid grid-cols-5 gap-0">
                                <Controller
                                    name="salesPrice"
                                    control={control}
                                    render={({ field: { onChange, value, ...field } }) => (
                                        <NumberField
                                            className={cn(
                                                'relative col-span-3 -mr-px focus-within:z-10 hover:z-10 [&_.planum-input-module-wrapper]:rounded-r-none',
                                                { 'z-10': !!getFormError('salesPrice') }
                                            )}
                                            {...field}
                                            value={Number(value)}
                                            stature={'sm'}
                                            placeholder={t('product.quote.salesPrice')}
                                            formatOptions={{
                                                maximumFractionDigits:
                                                    CURRENCY_DECIMALS[organization!.market.currencyCode],
                                                minimumFractionDigits:
                                                    CURRENCY_DECIMALS[organization!.market.currencyCode],
                                            }}
                                            onChange={value => {
                                                onChange(Number.isNaN(value) ? undefined : String(value));
                                            }}
                                            isInvalid={!!getFormError('salesPrice')}
                                            errorMessage={getFormErrorMessage('salesPrice')}
                                            isDisabled={disabled}
                                            trailingSlot={<PriceFieldSuffix />}
                                            data-test={`order-product-sales-price-${product.category}`}
                                        />
                                    )}
                                />

                                <Controller
                                    name="salesTaxClassId"
                                    control={control}
                                    render={({ field: { ref: _, ...field } }) => (
                                        <Combobox
                                            className="col-span-2"
                                            {...field}
                                            options={taxInfo?.taxClassOptions || []}
                                            placeholder={t('product.quote.tax')}
                                            disabled={disabled}
                                            variant="sm"
                                            buttonClassName="rounded-l-none"
                                            errorMessage={getFormErrorMessage('salesTaxClassId')}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        {!formFieldSettings?.serviceDate?.isHidden && (
                            <div
                                className="[&_.planum-datePicker-module-trigger]:min-w-full"
                                data-test={`date-picker-${product.category}`}
                            >
                                <Controller
                                    name="serviceDate"
                                    control={control}
                                    render={({ field: { value, onChange } }) => (
                                        <DatePicker
                                            value={value ? parseDate(value) : undefined}
                                            onChange={date => {
                                                onChange(date?.toString());
                                            }}
                                            label={t('product.quote.serviceDate')}
                                            stature="sm"
                                            isDisabled={disabled}
                                            data-test={`order-product-service-date-${product.category}`}
                                        />
                                    )}
                                />
                                <span className="text-critical font-copy-sm mt-2">
                                    {getFormErrorMessage('serviceDate')}
                                </span>
                                {validateServiceDateConsideringFutureRestriction(formValues.serviceDate) ? (
                                    <span className="text-critical font-copy-sm mt-2">
                                        {t('product.addPosition.errorMessage.serviceDateInFuture')}
                                    </span>
                                ) : null}
                            </div>
                        )}
                    </div>
                </div>
                {enableNegativePricesFeatureFlag && (
                    <>
                        {isNegativePurchasePrice && (
                            <div className="font-copy-sm text-subdued mt-4 h-4">
                                {t('sections.productSelection.productPositionFooter.negativePurchaseInfoText', {
                                    orgName: partnerOrganization?.name,
                                })}
                            </div>
                        )}
                        {isNegativeSalesPrice && (
                            <div className="font-copy-sm text-subdued mt-4 h-4">
                                {t('sections.productSelection.productPositionFooter.negativeSalesInfoText', {
                                    orgName: organization?.name,
                                })}
                            </div>
                        )}
                        {isZeroPurchasePrice && (
                            <div className="font-copy-sm text-subdued mt-4 h-4">
                                {t('sections.productSelection.productPositionFooter.zeroPurchaseInfoText', {
                                    orgName: partnerOrganization?.name,
                                })}
                            </div>
                        )}
                        {isZeroSalesPrice && (
                            <div className="font-copy-sm text-subdued mt-4 h-4">
                                {t('sections.productSelection.productPositionFooter.zeroSalesInfoText', {
                                    orgName: organization?.name,
                                })}
                            </div>
                        )}
                    </>
                )}
                {showProductPriceNotAddedHint && (
                    <div className="font-copy-sm text-subdued mt-4 h-4">
                        {t('sections.productSelection.productHint.productPriceNotAdded')}
                    </div>
                )}
                {showProductPriceAddedHint && (
                    <div className="font-copy-sm text-subdued mt-4 h-4">
                        {t('sections.productSelection.productHint.productPriceAdded')}
                    </div>
                )}
            </form>
            <ReactHookFormDevTool control={control} />
        </>
    );
};
