import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createPositionProduct, deletePositionProduct } from './position';
import { PostPositionProductRequestSchema } from '@schuettflix/interfaces';
import { z } from 'zod';

export const useDeletePositionProduct = (positionId: string) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ productGroupId, productId }: { productGroupId: string; productId: string }) =>
            deletePositionProduct(positionId, productGroupId, productId),
        onSettled: () => {
            void queryClient.invalidateQueries({ queryKey: ['positionProductGroups', positionId] });
        },
        throwOnError: true,
    });
};

export const useCreatePositionProduct = (positionId: string) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationKey: ['createPositionProduct', positionId],
        mutationFn: async (payload: {
            productGroupId: string;
            product: z.infer<typeof PostPositionProductRequestSchema>;
        }) => {
            return createPositionProduct(positionId, payload.productGroupId, payload.product);
        },
        onSuccess: () => {
            void queryClient.invalidateQueries({ queryKey: ['positionProductGroups', positionId] });
        },
        throwOnError: true,
    });
};
