import React, { useMemo, useState } from 'react';
import { FinanceSettings } from '@/shared/components/FinanceSettings/FinanceSettings.tsx';
import { useOrderSummary } from '@/clients/order/useOrderSummary.ts';
import { useSubmitOrder } from '@/modules/order/components/OrderWizard/hooks/useSubmitOrder.ts';
import { SectionPreview } from '@/shared/components/SectionPreview.tsx';
import { Button, LoadingSpinner } from '@schuettflix/react-components';
import { useTranslation } from 'react-i18next';
import { useBalanceSheet } from '@/shared/hooks/useBalanceSheet.ts';
import { OrderConfirmation } from '@/modules/order/components/OrderSummary/components/OrderConfirmation.tsx';
import { useMarketAwareOrder } from '@/clients/order/useOrder.ts';
import { SummaryItems } from '@/shared/components/SummaryItems.tsx';
import { SectionSummaryPreview } from '@/shared/components/SectionSummaryPreview.tsx';

export interface OrderSummaryProps {
    isOpen: boolean;
    orderId: string;
    onComplete: () => void;
    onEdit: () => void;
}

export const OrderSummary: React.FC<OrderSummaryProps> = ({ isOpen, orderId, onComplete, onEdit }) => {
    const { t } = useTranslation();
    const { data: orderSummary } = useOrderSummary(orderId);
    const { data: order } = useMarketAwareOrder(orderId);
    const { submitOrder } = useSubmitOrder(orderId);
    const balanceSheetMap = useBalanceSheet(orderSummary?.organizations?.flatMap(({ totals }) => totals) || []);
    const isSubmitted = useMemo(() => order?.status === 'SUBMITTED', [order]);
    const [showConfirmation, setShowConfirmation] = useState<boolean>(isSubmitted);
    const [showErrorMessages, setShowErrorMessages] = useState<boolean>(false);

    if (!isOpen && balanceSheetMap.has('ORDER')) {
        return (
            <SectionSummaryPreview
                title={`3. ${t('sections.summary.title')}`}
                onEdit={onEdit}
                balanceSheet={balanceSheetMap.get('ORDER')}
            />
        );
    }

    if (!isOpen && orderSummary.organizations.length === 0) {
        return (
            <SectionPreview
                description={t('sections.summary.preview.description')}
                headline={`3. ${t('sections.summary.title')}`}
            />
        );
    }

    return (
        <div className="mt-6 flex w-full max-w-[720px] grow flex-col rounded border-t pt-[40px]">
            <h2 className="font-headline-lg mb-[40px]">{`3. ${t('sections.summary.title')}`}</h2>
            <div className="mb-6 flex flex-col gap-6">
                <FinanceSettings
                    referenceId={orderId}
                    groups={orderSummary?.organizations}
                    showErrorMessages={showErrorMessages}
                    context="ORDER"
                />
            </div>
            <SummaryItems balanceSheetMap={balanceSheetMap} />
            <div className="flex justify-end py-4">
                <Button
                    leadingIcon={submitOrder.isPending || submitOrder.isSuccess ? LoadingSpinner : undefined}
                    size="sm"
                    label={t('sections.summary.buttons.createOrder')}
                    type="button"
                    disabled={submitOrder.isPending || submitOrder.isSuccess}
                    data-test="order-confirmation-button"
                    onClick={async () => {
                        try {
                            if (
                                orderSummary?.organizations?.find(org => {
                                    // PARTNER_INVOICE on a non-customer org allows for a null payment term
                                    return org.financeSettings.find(
                                        ({ selected }) =>
                                            !selected.paymentTermId && selected.billingMethod !== 'PARTNER_INVOICE'
                                    );
                                })
                            ) {
                                setShowErrorMessages(true);
                                return;
                            }

                            const response = await submitOrder.mutateAsync({ orderId });

                            if (response.humanReadableId) {
                                setShowConfirmation(true);
                            }
                        } catch (error) {
                            console.error('Error submitting order:', error);
                        }
                    }}
                />
            </div>
            {(submitOrder.isSuccess || order?.status === 'SUBMITTED') && (
                <OrderConfirmation
                    isConfirmationOpen={showConfirmation}
                    humanReadableId={submitOrder.data?.humanReadableId || order?.humanReadableId || ''}
                    orderId={orderId}
                    setShowConfirmation={setShowConfirmation}
                    onComplete={onComplete}
                />
            )}
        </div>
    );
};
