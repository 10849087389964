import { z } from 'zod';

export const GetOrderStatusUrlSchema = z.object({
    orderId: z.string().uuid(),
});

export const ORDER_PROCESS_STATUS_SCHEMA = z.enum(['NEW', 'IN_PROGRESS', 'COMPLETED', 'CANCELED']);

export type ORDER_PROCESS_STATUS = z.infer<typeof ORDER_PROCESS_STATUS_SCHEMA>;

export const GetOrderStatusResponseSchema = z.object({
    orderStatus: ORDER_PROCESS_STATUS_SCHEMA,
    closedAt: z.coerce.date().nullable(),
});

export type GetOrderStatusResponse = z.infer<typeof GetOrderStatusResponseSchema>;
