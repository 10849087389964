import z from 'zod';
import { PROJECT_CODE_SCHEMA } from '../project/project.ts';

export const GetCustomerInfoUrlSchema = z.strictObject({
    orderId: z.string().uuid(),
    customerInfoId: z.string().uuid(),
});

/**
 * validation schema for response payload
 */
export const GetCustomerInfoResponseSchema = z.strictObject({
    id: z.string(),
    orderId: z.string().uuid(),
    organizationId: z.number(),
    userId: z.number(),
    manualCostCenter: z.string().nullable(),
    constructionProjectId: z.string(),
    projectCode: PROJECT_CODE_SCHEMA,
});
