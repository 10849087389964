import { Query } from '@tanstack/react-query';
import { useToasterContext } from '@/shared/context/ToasterContext.tsx';
import { useTranslation } from 'react-i18next';

export const useQueryErrorHandler = () => {
    const { addToast } = useToasterContext();
    const { t } = useTranslation();

    return {
        handleCacheErrors: {
            onError: (error: unknown, query: Query<unknown, unknown, unknown>) => {
                if (query?.meta?.preventGlobalError) {
                    return;
                }

                addToast({
                    title: t('errorMessages.api.requestErrorTitle'),
                    message: (error as Error).message,
                    type: 'error',
                    closable: true,
                });

                throw error;
            },
        },
        handleMutationErrors: {
            onError: (error: unknown) => {
                addToast({
                    title: t('errorMessages.api.requestErrorTitle'),
                    message: (error as Error).message,
                    type: 'error',
                    closable: true,
                });
            },
        },
    };
};
