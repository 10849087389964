import { ChevronDownIcon, ChevronRightIcon, ChevronUpIcon } from '@schuettflix/icons-react';
import { PrefillTemplate } from '@/shared/types/prefill-template-data';
import { Trans, useTranslation } from 'react-i18next';
import { useState } from 'react';
import {
    PRODUCT_GROUP_TEMPLATE_TITLE_LABEL,
    TEMPLATE_GROUP_DESCRIPTION_LABEL,
    TEMPLATE_NAME_LABEL,
    TemplateGroup,
} from '@schuettflix/interfaces';
import {
    getPrefilledTemplate,
    getPrefilledTemplateGroup,
} from '@/shared/components/ProductSelection/PresetSelection/utils/template';

interface CombinedProductsCardProps {
    templateGroup: TemplateGroup;
    onSelect: (template: PrefillTemplate) => void;
    dataTest?: string;
}

export const CombinedProductsCard = ({ onSelect, templateGroup, dataTest }: CombinedProductsCardProps) => {
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState<boolean>(false);
    const prefilledTemplateGroup = getPrefilledTemplateGroup(templateGroup.name);
    const imgSrc = prefilledTemplateGroup?.imageSource;
    const productCardTitle = t(PRODUCT_GROUP_TEMPLATE_TITLE_LABEL[templateGroup.productGroupTemplateTitle]);

    if (templateGroup.templates?.length === 0) {
        return null;
    }

    return (
        <div
            className="bg-surface shadow-low grid w-[720px] cursor-pointer grid-cols-[80px_auto] gap-0 rounded"
            onClick={() => setExpanded(!expanded)}
            data-test={dataTest}
        >
            <div
                className="bg-surface-subdued overflow-hidden rounded-l bg-repeat-y"
                style={{
                    backgroundImage: imgSrc ? `url(${imgSrc})` : undefined,
                }}
            ></div>
            <div className="flex w-full flex-col">
                <div className="flex items-center justify-between p-4">
                    <div className="flex flex-col">
                        <span className="font-copy-md-strong text-interactive truncate" title={productCardTitle}>
                            {productCardTitle}
                        </span>

                        {templateGroup.description && (
                            <span className="font-copy-md text-subdued">
                                {t(TEMPLATE_GROUP_DESCRIPTION_LABEL[templateGroup.description])}
                            </span>
                        )}
                    </div>
                    {expanded ? <ChevronUpIcon size="xs" /> : <ChevronDownIcon size="xs" />}
                </div>
                {expanded && (
                    <div>
                        {templateGroup.templates?.map(template => (
                            <div
                                key={template.id}
                                onClick={() => onSelect(getPrefilledTemplate(template))}
                                className="hover:bg-surface-hovered px-4 pb-0 pt-4 last-of-type:rounded-br [&:last-of-type>*]:border-none [&:last-of-type>*]:pb-4"
                                data-test={`productCard-${template.type}`}
                            >
                                <div className="flex items-center justify-between border-b pb-[15px]">
                                    <div className="flex flex-col">
                                        <p className="text-md">
                                            <Trans
                                                t={t}
                                                i18nKey={TEMPLATE_NAME_LABEL[template.type]}
                                                components={{ type: <u /> }}
                                            />
                                        </p>
                                        {template.description && (
                                            <span className="font-copy-sm text-subdued">
                                                {t(TEMPLATE_GROUP_DESCRIPTION_LABEL[template.description])}
                                            </span>
                                        )}
                                    </div>
                                    <ChevronRightIcon size="xs" />
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};
