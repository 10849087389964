import { SUPPORTED_UNITS } from '@schuettflix/interfaces';
import { useState } from 'react';
import { FormErrors } from '@/CustomRequestProductChannel/components/CustomRequestProduct.tsx';
import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

function numbersHaveSameSign(number1?: number, number2?: number) {
    if (!number1 || !number2) {
        return true;
    }

    return Math.sign(number1) === Math.sign(number2);
}

export type FrancoFormErrors = {
    materialSalesPrice?: FormErrors['salesPrice'];
    transportSalesPrice?: FormErrors['salesPrice'];
};

type FieldErrorKey = keyof FrancoFormErrors;

interface InitialValues {
    initialAmount?: number;
    initialUnit?: SUPPORTED_UNITS;
    initialMaterialSalesPrice?: number;
    initialTransportSalesPrice?: number;
    initialFrancoFormErrors: FrancoFormErrors;
}

interface FrancoValues {
    leadingAmount?: number;
    leadingUnit?: SUPPORTED_UNITS;
    materialSalesPrice?: number;
    transportSalesPrice?: number;
}

export type FrancoValueKey = keyof FrancoValues;

export const useFranco = ({
    initialAmount,
    initialUnit,
    initialMaterialSalesPrice,
    initialTransportSalesPrice,
    initialFrancoFormErrors,
}: InitialValues) => {
    const { t } = useTranslation();
    const [francoFormValues, setFrancoFormValues] = useState<FrancoValues>({
        leadingAmount: initialAmount,
        leadingUnit: initialUnit,
        materialSalesPrice: initialMaterialSalesPrice,
        transportSalesPrice: initialTransportSalesPrice,
    });

    const [francoFormErrors, setFrancoFormErrors] = useState<FrancoFormErrors>(initialFrancoFormErrors);

    const { leadingAmount, leadingUnit, materialSalesPrice, transportSalesPrice } = francoFormValues;

    const setFrancoFormValue = <T extends FrancoValueKey>(francoValueKey: T, value: FrancoValues[T]) => {
        setFrancoFormValues(previousFrancoFormValues => ({
            ...previousFrancoFormValues,
            [francoValueKey]: value,
        }));
    };

    const setFrancoFormError = (errorKey: FieldErrorKey, error: FieldError) => {
        setFrancoFormErrors(previousErrors => ({
            ...previousErrors,
            [errorKey]: error,
        }));
    };

    const removeFrancoFormError = (errorKey: FieldErrorKey) => {
        setFrancoFormErrors(previousErrors => {
            const { [errorKey]: _errorToBeDeleted, ...restOfErrors } = previousErrors;
            return restOfErrors;
        });
    };

    const getFrancoFormError = (errorKey: FieldErrorKey) => {
        return francoFormErrors[errorKey];
    };

    const onSalesPriceChange = (salesPrice: number, productType: 'materialSalesPrice' | 'transportSalesPrice') => {
        const currentSalesPrice = productType === 'materialSalesPrice' ? transportSalesPrice : materialSalesPrice;

        if (!numbersHaveSameSign(salesPrice, currentSalesPrice)) {
            setFrancoFormError(productType, {
                type: 'francoFormError',
                message: t('product.addPosition.errorMessage.francoSalesPrice'),
            });
        }

        if (numbersHaveSameSign(salesPrice, currentSalesPrice)) {
            removeFrancoFormError('materialSalesPrice');
            removeFrancoFormError('transportSalesPrice');
        }

        setFrancoFormValue(productType, salesPrice);
    };

    return {
        leadingAmount,
        leadingUnit,
        materialSalesPrice,
        transportSalesPrice,
        francoFormErrors,
        onSalesPriceChange,
        setFrancoFormValues,
        setFrancoFormValue,
        getFrancoFormError,
        setFrancoFormError,
        removeFrancoFormError,
    };
};
