import {
    ProductUseCaseDocument,
    ProductUseCaseImage,
    setAppVersion,
    setAuthTokenAndOperatingMarket,
    setBaseURL,
    setLocale,
    uploadDocument,
    uploadImage,
} from '@schuettflix/client-file';
import { useMutation } from '@tanstack/react-query';
import { useGlobals } from './useGlobals.ts';
import { useCallback, useEffect } from 'react';
import { authTokenFromLocalStorage, marketFromLocalStorage } from '@/shared/utils/localStorage.ts';
import { useTranslation } from 'react-i18next';

export const useFileUpload = () => {
    const { hostAppVersion, baseUrl } = useGlobals();
    const { i18n } = useTranslation();

    useEffect(() => {
        setLocale(i18n.language);
    }, [i18n.language]);

    setAuthTokenAndOperatingMarket({
        oktaToken: authTokenFromLocalStorage(),
        market: marketFromLocalStorage(),
    });
    setBaseURL(baseUrl.backend);
    setAppVersion(hostAppVersion);

    const uploadImageMutation = useMutation({
        mutationKey: ['file', 'image', 'upload'],
        mutationFn: uploadImage,
    }).mutateAsync;

    const uploadDocumentMutation = useMutation({
        mutationKey: ['file', 'document', 'upload'],
        mutationFn: uploadDocument,
    }).mutateAsync;

    return {
        uploadFile: useCallback(
            async (
                file: File,
                abortSignal: AbortSignal,
                onUploadProgressPercent: (progress: number) => void
            ): Promise<string> => {
                const isImage = file.type.startsWith('image/');
                let response;
                if (isImage) {
                    response = await uploadImageMutation({
                        file: file,
                        signal: abortSignal,
                        meta: {
                            productUseCase: ProductUseCaseImage.InvoiceAttachmentImage,
                        },
                        onUploadProgressPercent,
                    });
                } else {
                    response = await uploadDocumentMutation({
                        file: file,
                        signal: abortSignal,
                        meta: {
                            productUseCase: ProductUseCaseDocument.InvoiceAttachmentDocument,
                        },
                        onUploadProgressPercent,
                    });
                }

                return `${isImage ? 'image' : 'document'}/${response.data.urlPath}`;
            },
            [uploadImageMutation, uploadDocumentMutation]
        ),
    };
};
