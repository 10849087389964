import React, { useEffect } from 'react';
import { Price } from '@schuettflix/conversion';
import {
    DebouncedCustomRequestProduct,
    DebouncedCustomRequestProductProps,
    SimpleDebouncedCustomRequestProductProps,
} from '../../components/DebouncedCustomRequestProduct';
import { useFormattedActiveTaxClassesByMarket } from '@/clients/tax-classes/useTaxClasses';
import { usePlatformOrganizationId } from '@/shared/hooks/usePlatformOrganizationId';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useMarketAwarePosition } from '@/clients/position/useMarketAwarePosition';
import {
    createPositionProduct,
    patchPositionProduct,
} from '@/CustomRequestProductChannel/clients/CustomRequestPositionClient';
import { useTranslation } from 'react-i18next';
import { usePositionCustomRequestProduct } from '@/CustomRequestProductChannel/hooks/usePositionCustomRequestProduct';
import { constructProductQuote } from '@/CustomRequestProductChannel/utils/constructProductQuote.ts';

export type PositionCustomRequestProductProps = SimpleDebouncedCustomRequestProductProps & {
    positionId: string;
};

export const PositionCustomRequestProduct: React.FC<PositionCustomRequestProductProps> = ({ positionId, ...props }) => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    const { data: position } = useMarketAwarePosition(positionId);
    const taxInfo = useFormattedActiveTaxClassesByMarket(position?.marketCode);
    const platformOrganizationId = usePlatformOrganizationId();
    const orderingOrganizationId = position?.customerInfo?.organizationId;

    function invalidateProductsGroup() {
        void queryClient.invalidateQueries({
            queryKey: ['positionCustomRequestProduct', positionId, props.product.id],
        });
    }

    const { data: positionProduct, isLoading } = usePositionCustomRequestProduct(props.product.id);
    useEffect(() => {
        if (positionProduct) {
            props.onCompleteChange(props.product.id, true);
        }
    }, [positionProduct]);

    const { mutateAsync: mutateAsyncPostProduct } = useMutation({
        mutationKey: ['positionCustomRequestProduct'],
        mutationFn: createPositionProduct,
        onSuccess: () => {
            invalidateProductsGroup();
        },
    });

    const { mutateAsync: mutateAsyncPatchProduct } = useMutation({
        mutationKey: ['positionCustomRequestProduct'],
        mutationFn: patchPositionProduct,
        onSuccess: () => {
            invalidateProductsGroup();
        },
    });

    const handleSubmit: DebouncedCustomRequestProductProps['onSubmit'] = async formValues => {
        if (positionProduct) {
            await mutateAsyncPatchProduct({
                productId: props.product.id,
                product: {
                    name: formValues.productName,
                    quota: formValues.amount,
                    unit: formValues.unit,
                    partnerOrganizationId: formValues.partnerOrganizationId,
                    purchasePrice: new Price(formValues.purchasePrice, formValues.currencyCode).toNumber(),
                    purchaseTaxClassId: formValues.purchaseTaxClassId,
                    salesPrice: new Price(formValues.salesPrice, formValues.currencyCode).toNumber(),
                    salesTaxClassId: formValues.salesTaxClassId,
                    currencyCode: formValues.currencyCode,
                    productCategory: props.product.category,
                    productType: props.product.type,
                },
            });
            props.onCompleteChange(props.product.id, true);
        } else if (platformOrganizationId && orderingOrganizationId) {
            try {
                await mutateAsyncPostProduct({
                    product: {
                        name: formValues.productName,
                        quota: formValues.amount,
                        unit: formValues.unit,
                        partnerOrganizationId: formValues.partnerOrganizationId,
                        purchasePrice: new Price(formValues.purchasePrice, formValues.currencyCode).toNumber(),
                        purchaseTaxClassId: formValues.purchaseTaxClassId,
                        salesPrice: new Price(formValues.salesPrice, formValues.currencyCode).toNumber(),
                        salesTaxClassId: formValues.salesTaxClassId,
                        currencyCode: formValues.currencyCode,
                        productCategory: props.product.category,
                        productType: props.product.type,
                        positionProductId: props.product.id,
                        positionId,
                        projectOwnerOrganizationId: orderingOrganizationId,
                        platformOrganizationId: platformOrganizationId,
                    },
                });
                props.onCompleteChange(props.product.id, true);
            } catch {
                props.onCompleteChange(props.product.id, false);
                return;
            }
        }
    };

    return position?.customerInfo && !isLoading ? (
        <DebouncedCustomRequestProduct
            {...props}
            customerId={position.customerInfo.organizationId}
            quote={constructProductQuote({ positionQuote: positionProduct, taxInfo })}
            taxInfo={taxInfo}
            formFieldSettings={{
                ...(props.formFieldSettings ?? {}),
                serviceDate: {
                    ...(props.formFieldSettings?.serviceDate ?? {}),
                    isOptional: true,
                    isHidden: true,
                },
                amount: {
                    label: t('product.quote.quota'),
                    ...(props.formFieldSettings?.amount ?? {}),
                },
            }}
            onSubmit={handleSubmit}
        />
    ) : null;
};
