// RFC 9457 error response utility
// https://datatracker.ietf.org/doc/html/rfc9457
// Make error messages informative, actionable, and consistent across various endpoints and services

import { z } from 'zod';

// schema according to RFC 9457
const ErrorResponseSchema = z.object({
    type: z.string().url(), // A URI reference that identifies the problem type
    title: z.string(), // A short, human-readable summary of the problem type
    status: z.number().int(), // The HTTP status code generated by the origin server for this occurrence of the problem
    detail: z.string().optional(), // A human-readable explanation specific to this occurrence of the problem
    instance: z.string().url().optional(), // A URI reference that identifies the specific occurrence of the problem
    additionalInfo: z.record(z.any()).optional(), // A map of additional information specific to the error
});

export type ErrorResponse = z.infer<typeof ErrorResponseSchema>;

export function createErrorResponse(
    type: string,
    title: string,
    status: number,
    detail?: string,
    instance?: string,
    additionalInfo?: Record<string, any>
): ErrorResponse {
    const errorResponse = {
        type,
        title,
        status,
        detail,
        instance,
        additionalInfo,
    };

    return ErrorResponseSchema.parse(errorResponse);
}
