import { BILLING_TYPE } from '@schuettflix/interfaces';
import { PaymentTerm } from '@/clients/finance/types/FinanceSettings.ts';

export function getSelectablePaymentTerms(billingMethod: BILLING_TYPE, paymentTerms: PaymentTerm[]) {
    if (billingMethod === 'PARTNER_INVOICE') {
        return null;
    }

    const filteredPaymentTerms = paymentTerms.filter(pt =>
        billingMethod === 'INVOICE' ? pt.eligibleForRevenueIncrease : pt.eligibleForCostIncrease
    );

    return filteredPaymentTerms?.map(pt => ({
        value: pt.id,
        label: pt.description,
        id: pt.id,
    }));
}
