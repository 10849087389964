import { z } from 'zod';
import { ORDER_PROCESS_STATUS_SCHEMA } from './get-order-status';
import { order } from '@schuettflix/schema-registry-ts';

const { IsoDate, IsoDatetimeMaybeRange } = order.shared.versions.v1.schemas;

export const GetOrderDetailsUrlSchema = z.object({
    orderId: z.string().uuid(),
});

export const GetOrderDetailsResponseSchema = z.object({
    orderStatus: ORDER_PROCESS_STATUS_SCHEMA,
    closedAt: IsoDate.nullable(),

    createdAt: IsoDate,
    createdBy: z.number(),
    createdFor: z.number().nullable(),
    closedBy: z.number().nullable(),

    serviceDate: IsoDatetimeMaybeRange,
    humanReadableId: z.string(),

    customerOrganizationId: z.number(),
    constructionProjectId: z.string(),
    costCenters: z.array(z.string()).nullable(),
});

export type GetOrderDetailsResponse = z.infer<typeof GetOrderDetailsResponseSchema>;
