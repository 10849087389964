import { useEditPositionState } from '@/modules/position/hooks/useEditPositionState.ts';
import { useSearchParams } from 'react-router-dom';
import { useState } from 'react';

export const PRESELECT_SECTION_SEARCH_PARAM = 'section';

export const PRODUCT_SELECTION_SECTION_NUMBER = 2;
export const POSITION_SETTINGS_SECTION_NUMBER = 3;
export const POSITION_SUMMARY_SECTION_NUMBER = 4;

function sectionToNumber(section?: string) {
    switch (section) {
        case 'products':
            return PRODUCT_SELECTION_SECTION_NUMBER;
        case 'settings':
            return POSITION_SETTINGS_SECTION_NUMBER;
        case 'terms':
            return POSITION_SUMMARY_SECTION_NUMBER;
        default:
            return undefined;
    }
}

export function useCurrentSection(defaultSection: number = PRODUCT_SELECTION_SECTION_NUMBER) {
    const { isEditing } = useEditPositionState();
    const [searchParams] = useSearchParams();
    const selectedSection =
        sectionToNumber(searchParams.get(PRESELECT_SECTION_SEARCH_PARAM) || undefined) ?? defaultSection;
    return useState<number>(isEditing ? selectedSection : defaultSection);
}
