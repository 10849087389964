import { Helmet } from 'react-helmet';

interface DocumentTitleProps {
    title: string;
}

export function DocumentTitle({ title }: DocumentTitleProps) {
    return (
        <Helmet>
            <title>{title} - Schüttflix</title>
        </Helmet>
    );
}
