import { useOrganizationSuspenseQuery } from '@/clients/organization/useOrganization.ts';
import { useUserSuspenseQuery } from '@/clients/users/useUsers.ts';
import { useConstructionProjectSuspenseQuery } from '@/clients/constructionProject/useConstructionProject.ts';
import React from 'react';

interface ClientInformationSummaryContentProps {
    organizationId?: number;
    constructionProjectId?: string;
    userId?: number;
    manualCostCenter?: string | null;
}

export const ClientInformationSummaryContent = ({
    organizationId,
    userId,
    constructionProjectId,
    manualCostCenter,
}: ClientInformationSummaryContentProps) => {
    const { data: organization } = useOrganizationSuspenseQuery(organizationId);
    const { data: user } = useUserSuspenseQuery(userId);
    const { data: constructionProject } = useConstructionProjectSuspenseQuery(constructionProjectId);

    return (
        <div className="grid grid-cols-1 grid-rows-1 gap-1 md:grid-cols-2 md:grid-rows-2">
            <div className="truncate" title={organization?.name}>
                {organization?.name}
            </div>

            {userId ? (
                <div className="truncate" title={user?.username}>
                    {user ? `${user.firstName} ${user.lastName}` : ''}
                </div>
            ) : (
                <div />
            )}
            <div className="truncate" title={constructionProject?.name}>
                {constructionProject?.name}
            </div>
            {manualCostCenter && <div className="truncate md:col-start-2 md:row-start-2">{manualCostCenter}</div>}
        </div>
    );
};
