import {
    GetFulfillmentSummaryRequestSchema,
    GetFulfillmentSummaryResponseSchema,
    GetOrderResponseSchema,
    GetOrderSummaryResponseSchema,
    PatchCustomerInfoRequestSchema,
    PatchCustomerInfoResponseSchema,
    PatchPaymentTermRequestSchema,
    PatchPaymentTermResponseSchema,
    PostCustomerInfoRequestSchema,
    PostCustomerInfoResponseSchema,
    PostOrderFulfillRequestSchema,
    PostOrderResponseSchema,
    SubmitOrderResponseSchema,
} from '@schuettflix/interfaces';
import { PaymentTermsSettings } from '@/clients/finance/types/FinanceSettings';
import { getCustomRequestClient, getMonolithClient, getOrderClient } from '@/clients/httpClients';
import { decorateOrganizationNamesByOrganizationIds } from '@/clients/organization/Organization';
import { z } from 'zod';

export const createOrder = async () => {
    return getOrderClient()
        .post<z.input<typeof PostOrderResponseSchema>>('/v1/order')
        .then(res => PostOrderResponseSchema.parse(res.data));
};

export const getOrderById = async (orderId: string) => {
    // throw new Error('CANT GET ORDER BY ID :(');
    return getOrderClient()
        .get<z.input<typeof GetOrderResponseSchema>>(`/v1/order/${orderId}`)
        .then(res => GetOrderResponseSchema.parse(res.data));
};

export const updateOrderWithClientInfo = async (
    payload: z.input<typeof PostCustomerInfoRequestSchema>,
    orderId: string
) => {
    return getOrderClient()
        .post<z.input<typeof PostCustomerInfoResponseSchema>>(`/v1/order/${orderId}/customer-info`, payload)
        .then(res => PostCustomerInfoResponseSchema.parse(res.data));
};

export const updateOrderWithUpdatedClientInfo = async (
    payload: z.input<typeof PatchCustomerInfoRequestSchema>,
    orderId: string
) => {
    return getOrderClient()
        .patch<z.input<typeof PatchCustomerInfoResponseSchema>>(
            `/v1/order/${orderId}/customer-info/${payload.id}`,
            payload
        )
        .then(res => PatchCustomerInfoResponseSchema.parse(res.data));
};

export const submitOrder = async (orderId: string) => {
    return getOrderClient()
        .patch<z.input<typeof SubmitOrderResponseSchema>>(`/v1/order/${orderId}/submit`)
        .then(res => SubmitOrderResponseSchema.parse(res.data));
};

export const getOrderSummaryById = async (orderId: string) => {
    const orderSummary = await getOrderClient()
        .get<z.input<typeof GetOrderSummaryResponseSchema>>(`/v1/order/${orderId}/order-summary`)
        .then(res => {
            return GetOrderSummaryResponseSchema.parse(res.data);
        });

    return decorateOrganizationNamesByOrganizationIds(orderSummary.organizations);
};

export type GetFulfillmentSummaryPayload = z.input<typeof GetFulfillmentSummaryRequestSchema>;
export const getFulfillmentSummaryById = async (orderId: string, payload: GetFulfillmentSummaryPayload) => {
    const orderSummary = await getOrderClient()
        .post(`/v1/order/${orderId}/fulfillment-summary`, payload)
        .then(res => {
            return GetFulfillmentSummaryResponseSchema.parse(res.data);
        });

    return decorateOrganizationNamesByOrganizationIds(orderSummary.organizations);
};

export const updateOrganizationFinanceSettings = async (
    payload: z.input<typeof PatchPaymentTermRequestSchema>,
    organizationId: number,
    referenceId: string
) => {
    return getOrderClient()
        .patch<z.input<typeof PatchPaymentTermRequestSchema>>(
            `/v1/order/${referenceId}/organization/${organizationId}/payment-term`,
            payload
        )
        .then(res => PatchPaymentTermResponseSchema.parse(res.data));
};

export const getPaymentTerms = async () => {
    return getMonolithClient()
        .get<PaymentTermsSettings>(`/finance/v1/platform/payment-terms`)
        .then(res => res.data);
};

export const fulfillOrder = async (orderId: string, payload: z.input<typeof PostOrderFulfillRequestSchema>) => {
    return getCustomRequestClient().post(`/v1/order-fulfill/${orderId}`, payload);
};

export const cancelOrder = async (orderId: string) => {
    return getCustomRequestClient().delete(`/v1/order-fulfill/${orderId}`);
};
