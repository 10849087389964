import { z } from 'zod';
import { POSITION_STATUS_SCHEMA, ProjectNumberSchema, ProjectPositionNumberSchema } from './shared';

export const PostPositionSubmitUrlSchema = z.strictObject({
    positionId: z.string().uuid(),
});

export const PostPositionSubmitResponseSchema = z.strictObject({
    id: z.string().uuid(),
    projectNumber: ProjectNumberSchema,
    positionNumber: ProjectPositionNumberSchema,
    marketCode: z.string(),
    note: z.string().nullable(),
    status: POSITION_STATUS_SCHEMA,
    active: z.boolean(),
    validFrom: z.coerce.date().nullable(),
    validTo: z.coerce.date().nullable(),
    createdAt: z.coerce.date(),
    updatedAt: z.coerce.date().nullable(),
    orderCount: z.number(),
});

export type PostPositionSubmitResponse = z.infer<typeof PostPositionSubmitResponseSchema>;
