import { z } from 'zod';
import { AbstractPostQuoteOrderRequestSchema } from '../quote/post-quote-order.ts';
import { GetQuoteResponseSchema } from './get-quote.ts';

export const PostQuoteOrderUrlSchema = z.strictObject({
    id: z.string().uuid(),
});

/**
 * validation schema for specific request payload
 */
const CustomRequestPostQuoteOrderPayload = z.strictObject({
    payload: z.strictObject({}).optional(),
});

/**
 * validation schema for whole (merged) request payload
 */
export const PostQuoteOrderRequestSchema = AbstractPostQuoteOrderRequestSchema.merge(
    CustomRequestPostQuoteOrderPayload
).strict();

/**
 * validation schema for the response payload
 */
export const PostQuoteOrderResponseSchema = GetQuoteResponseSchema;
