import React, {
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useReducer,
    useState,
} from 'react';
import { z } from 'zod';
import {
    GetOrderResponseSchema,
    GetProductGroupListResponseSchema,
    PRODUCT_CATEGORY,
    PRODUCT_TYPES,
    SUPPORTED_UNITS,
    CURRENCY,
} from '@schuettflix/interfaces';
import { useMarketAwareOrder } from '@/clients/order/useOrder';
import { useSelectedProductGroup } from '@/clients/selectedProducts/useSelectedProductGroup.ts';
import { Price } from '@schuettflix/conversion';
import { useNavigate } from 'react-router-dom';
import { useGetQuotesByOrder } from '@/clients/quotes/useQuotes.ts';
import { NestedProductTypeResponseSchema } from '@schuettflix/interfaces/src/product-type/NestedTypeResponseEntity';

export type OrderType = z.infer<typeof GetOrderResponseSchema> | null;

export type FulfillmentQuote = {
    id: string;
    quoteId: string;
    name: string;
    category: PRODUCT_CATEGORY;
    type: PRODUCT_TYPES;
    amount: number;
    unit: SUPPORTED_UNITS;
    currencyCode: CURRENCY;
    partnerOrganizationId?: number;
    orderingOrganizationId: number;
    platformOrganizationId: number;
    purchasePrice: Price;
    purchaseTaxClassId: string;
    salesPrice: Price;
    salesTaxClassId: string;
    serviceDate: string;
    templateId?: string;
    inOriginalOrder: boolean;
    productGroupType?: z.infer<typeof NestedProductTypeResponseSchema>;
    positionProductId: string | null;
};

export type FormError = {
    field: string;
    message: string;
    scrollToElement: () => void;

    /** An optional arbitrary id that can help identifying
     * if the error belongs to the current section / component instance
     */
    sectionId?: string;
};

type FulfillmentContextType = {
    order: OrderType;
    setOrder: (value: OrderType) => void;
    quotes: FulfillmentQuote[];
    updateQuote: (value: FulfillmentQuote) => void;
    documents: DocumentType[];
    setDocuments: (values: DocumentType[]) => void;
    errors: FormError[];
    setErrors: Dispatch<SetStateAction<FormError[]>>;
    /** Whether the form submit button was clicked */
    isSubmitted: boolean;
    setSubmitted: (value: boolean) => void;
    invalidSections: string[];
    setInvalidSections: Dispatch<SetStateAction<string[]>>;
    isPageValid: boolean;
    canSubmit: boolean;
    setCanSubmit: Dispatch<SetStateAction<boolean>>;
    productGroup: z.input<typeof GetProductGroupListResponseSchema>;
};

type DocumentType = {
    url: string;
    fileName: string;
};

const FulfillmentContext = createContext<FulfillmentContextType | undefined>(undefined);

type State = {
    quotesInOrder: FulfillmentQuote[];
    fulfillmentQuotes: FulfillmentQuote[];
    quotes: FulfillmentQuote[];
};
export const FulfillmentContextProvider: React.FC<{ orderId: string; children: ReactNode }> = ({
    orderId,
    children,
}) => {
    const [order, setOrder] = useState<OrderType>(null);
    const reducer = (
        state: State,
        action: { type: 'setQuotesInOrder' | 'setFulfillmentQuotes'; payload: FulfillmentQuote[] }
    ): State => {
        switch (action.type) {
            case 'setQuotesInOrder':
                return {
                    ...state,
                    quotesInOrder: action.payload,
                    quotes: [...action.payload, ...state.fulfillmentQuotes],
                };
            case 'setFulfillmentQuotes':
                return {
                    ...state,
                    fulfillmentQuotes: action.payload,
                    quotes: [...state.quotesInOrder, ...action.payload],
                };
        }
    };
    const [{ quotes, quotesInOrder }, dispatch] = useReducer(reducer, {
        quotesInOrder: [],
        fulfillmentQuotes: [],
        quotes: [],
    } as State);

    const [documents, setDocuments] = useState<DocumentType[]>([]);
    const [errors, setErrors] = useState<FormError[]>([]);
    const [isSubmitted, setSubmitted] = useState<boolean>(false);

    const [invalidSections, setInvalidSections] = useState<string[]>([]);
    const [isPageValid, setIsPageValid] = useState<boolean>(true);
    const [canSubmit, setCanSubmit] = useState<boolean>(true);

    const { data: orderData } = useMarketAwareOrder(orderId, true);
    const { data: productGroupsData } = useSelectedProductGroup(orderId, true);
    const { data: quotesByOrder } = useGetQuotesByOrder(order?.id);

    const quotesNotInOriginalOrder = useMemo(() => {
        if (!quotesByOrder?.items || !productGroupsData?.selectedProducts) {
            return [];
        }
        const allQuotes = quotesByOrder?.items;
        const orderedQuotes = productGroupsData?.selectedProducts?.map(product => product.quote);

        return allQuotes?.filter(quote => !orderedQuotes?.some(orderedQuote => orderedQuote?.id === quote.id));
    }, [quotesByOrder?.items, productGroupsData?.selectedProducts]);

    const navigate = useNavigate();

    useEffect(() => {
        if (orderData?.status === 'DRAFT') {
            navigate('/order/' + orderId);
        }
        if (orderData) {
            setOrder(orderData);
        }
    }, [orderData, orderId, navigate]);

    useEffect(() => {
        setIsPageValid(invalidSections.length === 0);
    }, [invalidSections]);

    useEffect(() => {
        const quotesFromOrder =
            productGroupsData?.selectedProducts?.flatMap<FulfillmentQuote>(product =>
                product.quote
                    ? {
                          id: product.id,
                          quoteId: product.quote.id,
                          category: product.category,

                          name: product.quote.quoteData.name as string,
                          amount: product.quote.amount,
                          unit: product.quote.unit,
                          currencyCode: product.quote.currencyCode,
                          partnerOrganizationId: product.quote.partnerOrganizationId ?? undefined,
                          orderingOrganizationId: product.quote.orderingOrganizationId,
                          platformOrganizationId: product.quote.platformOrganizationId,
                          purchasePrice: new Price(product.quote.purchasePrice, product.quote.currencyCode),
                          purchaseTaxClassId: product.quote.purchaseTaxClassId,
                          salesPrice: new Price(product.quote.salesPrice, product.quote.currencyCode),
                          salesTaxClassId: product.quote.salesTaxClassId,
                          serviceDate: product.quote.serviceDate,
                          templateId: product.templateId || undefined,
                          type: product.type,
                          productGroupType: product.productType,
                          inOriginalOrder: true,
                          positionProductId: product.positionProductId,
                      }
                    : []
            ) ?? [];
        dispatch({ type: 'setQuotesInOrder', payload: quotesFromOrder });
    }, [productGroupsData?.selectedProducts]);

    useEffect(() => {
        const quotesNotInOrder =
            quotesNotInOriginalOrder?.map<FulfillmentQuote>(quote => ({
                id: quote.selectedProductId,
                quoteId: quote.id,
                category: quote.productCategory,
                name: quote.name,
                amount: quote.amount,
                unit: quote.unit,
                currencyCode: quote.currencyCode as CURRENCY,
                partnerOrganizationId: quote.partnerOrganizationId,
                orderingOrganizationId: quote.orderingOrganizationId,
                platformOrganizationId: quote.platformOrganizationId,
                purchasePrice: new Price(quote.purchasePrice, quote.currencyCode as CURRENCY),
                purchaseTaxClassId: quote.purchaseTaxClassId,
                salesPrice: new Price(quote.salesPrice, quote.currencyCode as CURRENCY),
                salesTaxClassId: quote.salesTaxClassId,
                serviceDate: quote.serviceDate?.toISOString(),
                type: quote.productType,
                templateId: undefined,
                inOriginalOrder: false,
                positionProductId: null,
            })) ?? [];
        dispatch({ type: 'setFulfillmentQuotes', payload: quotesNotInOrder });
    }, [quotesNotInOriginalOrder]);

    const updateQuote = useCallback(
        (value: FulfillmentQuote) => {
            const quoteIndex = quotesInOrder.findIndex(product => product.id === value.id);
            const updatedQuotes = [...quotesInOrder];
            updatedQuotes[quoteIndex] = { ...updatedQuotes[quoteIndex], ...value };
            dispatch({ type: 'setQuotesInOrder', payload: updatedQuotes });
        },
        [quotesInOrder, dispatch]
    );

    return (
        <FulfillmentContext.Provider
            value={{
                order,
                setOrder,
                quotes,
                updateQuote,
                documents,
                setDocuments,
                errors,
                setErrors,
                isSubmitted,
                setSubmitted,
                isPageValid,
                invalidSections,
                setInvalidSections,
                productGroup: productGroupsData,
                canSubmit,
                setCanSubmit,
            }}
        >
            {children}
        </FulfillmentContext.Provider>
    );
};

export const useFulfillmentContext = () => {
    const context = useContext(FulfillmentContext);
    if (!context) {
        throw new Error('useFulfillmentContext must be used within a FulfillmentContext');
    }
    return context;
};
