import { z } from 'zod';
import { GetPositionQuoteResponseSchema, GetQuoteResponseSchema, CURRENCY_SCHEMA } from '@schuettflix/interfaces';
import { format } from 'date-fns';
import { Price } from '@schuettflix/conversion';
import { FormattedActiveTaxClasses } from '@/clients/tax-classes/useTaxClasses.ts';

export type ConstructProductQuoteParams = {
    taxInfo?: FormattedActiveTaxClasses;
    quote?: z.infer<typeof GetQuoteResponseSchema> | null;
    positionQuote?: z.infer<typeof GetPositionQuoteResponseSchema> | null;
    includePositionQuota?: boolean;
};

export function constructProductQuote({
    taxInfo,
    quote,
    positionQuote,
    includePositionQuota = true,
}: ConstructProductQuoteParams) {
    if (quote) {
        return {
            partnerOrganizationId: quote.meta.partnerOrganizationId,
            amount: quote.meta.amount,
            unit: quote.meta.unit,
            purchasePrice: new Price(
                quote.meta.purchasePrice,
                CURRENCY_SCHEMA.parse(quote?.meta.currencyCode)
            ).toNumber(),
            salesPrice: new Price(quote.meta.salesPrice, CURRENCY_SCHEMA.parse(quote?.meta.currencyCode)).toNumber(),
            serviceDate: format(quote.meta.serviceDate, 'yyyy-MM-dd'),
            purchaseTaxClassId: quote.meta.purchaseTaxClassId ?? taxInfo?.defaultTaxClass ?? '',
            currencyCode: quote.meta.currencyCode as z.input<typeof CURRENCY_SCHEMA>,
            salesTaxClassId: quote.meta.salesTaxClassId,
            name: quote.payload.name,
        };
    }

    if (positionQuote) {
        return {
            partnerOrganizationId: positionQuote.partnerOrganizationId,
            unit: positionQuote.unit,
            purchasePrice: new Price(
                positionQuote?.purchasePrice,
                CURRENCY_SCHEMA.parse(positionQuote.currencyCode)
            ).toNumber(),
            salesPrice: new Price(
                positionQuote.salesPrice,
                CURRENCY_SCHEMA.parse(positionQuote.currencyCode)
            ).toNumber(),
            purchaseTaxClassId: positionQuote.purchaseTaxClassId ?? taxInfo?.defaultTaxClass ?? '',
            currencyCode: positionQuote.currencyCode as z.input<typeof CURRENCY_SCHEMA>,
            salesTaxClassId: positionQuote.salesTaxClassId,
            name: positionQuote.name,
            amount: includePositionQuota ? positionQuote.quota : undefined,
        };
    }

    return undefined;
}
