import { getFulfillmentClient } from '@/clients/httpClients.ts';
import {
    GetGroupedPaymentTermSchema,
    ListResponseBody,
    GetOrderStatusResponse,
    GetOrderStatusResponseSchema,
    BILLING_TYPE,
} from '@schuettflix/interfaces';
import { z } from 'zod';
import { getOrganizationById } from '@/clients/organization/Organization.ts';

export type PaymentTermUpdatePayload = {
    billingMethod: BILLING_TYPE;
    paymentTermId: number | null;
    organizationId: any;
};

export const getPaymentTermsByOrderId = async (orderId: string) => {
    const paymentTerms = await getFulfillmentClient()
        .get<ListResponseBody<z.input<typeof GetGroupedPaymentTermSchema>>>(`/v1/orders/${orderId}/payment-terms`)
        .then(response => {
            response.data.items.map(item => GetGroupedPaymentTermSchema.parse(item));
            return response.data;
        });

    return await Promise.all(
        paymentTerms.items.map(async item => {
            try {
                const organization = await getOrganizationById(item.organizationId);
                return {
                    ...item,
                    organizationName: organization.name,
                };
            } catch {
                return {
                    ...item,
                    organizationName: item.organizationId.toString(),
                };
            }
        })
    );
};

export const getOrderStatus = async (orderId: string) => {
    return await getFulfillmentClient()
        .get<GetOrderStatusResponse>(`/v1/orders/${orderId}/status`)
        .then(response => GetOrderStatusResponseSchema.parse(response.data));
};

export const updatePaymentTerms = async (orderId: string, { organizationId, ...payload }: PaymentTermUpdatePayload) => {
    return await getFulfillmentClient().patch(
        `/v1/orders/${orderId}/organization/${organizationId}/payment-terms`,
        payload
    );
};
