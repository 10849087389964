import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useMarketAwareOrder } from '@/clients/order/useOrder.ts';
import { ClientInformationFormOrder } from '@/modules/order/components/ClientInformationFormOrder.tsx';
import { OrderSummary } from '@/modules/order/components/OrderSummary/OrderSummary.tsx';
import { OrderProductSelection } from '@/modules/order/components/ProductSelection/OrderProductSelection.tsx';
import { ProductSelectionSuspenseBoundary } from '@/shared/components/ProductSelection/ProductSelectionSuspenseBoundary.tsx';
import { PageHeader } from '@/shared/components/PageHeader.tsx';
import { OrderSummarySuspenseBoundary } from '@/modules/order/components/OrderSummary/OrderSummarySuspenseBoundary.tsx';

export interface OrderWizardProps {
    orderId: string;
    isOpen?: boolean;
    onPushMessage: (opts: { type: 'error' | 'success'; message: string }) => void;
    onComplete: () => void;
}

export const OrderWizard: React.FC<OrderWizardProps> = ({ orderId, onComplete }) => {
    const { t } = useTranslation();
    const { data: order } = useMarketAwareOrder(orderId);
    const [currentSectionNumber, setCurrentSectionNumber] = useState(order?.status === 'SUBMITTED' ? 3 : 1);

    const handleOrderCompletion = () => {
        onComplete();
    };

    if (!order) {
        return null;
    }

    return (
        <section className="bg-light-gray-200 min-h-screen">
            <PageHeader title={t('page.title')} />
            <main className="pt-18 w-full">
                <div className="mx-auto flex w-full max-w-[1280px] flex-col items-center gap-4">
                    <ClientInformationFormOrder
                        isOpen={currentSectionNumber === 1}
                        orderId={orderId}
                        onComplete={() => setCurrentSectionNumber(2)}
                        onEdit={() => setCurrentSectionNumber(1)}
                    />

                    <ProductSelectionSuspenseBoundary>
                        <OrderProductSelection
                            isOpen={currentSectionNumber === 2}
                            onComplete={() => setCurrentSectionNumber(3)}
                            onEdit={() => setCurrentSectionNumber(2)}
                            onDelete={() => setCurrentSectionNumber(2)}
                            orderId={orderId}
                        />
                    </ProductSelectionSuspenseBoundary>
                    <div className="mb-18 flex w-full justify-center">
                        <OrderSummarySuspenseBoundary>
                            <OrderSummary
                                isOpen={currentSectionNumber === 3}
                                onComplete={handleOrderCompletion}
                                onEdit={() => setCurrentSectionNumber(3)}
                                orderId={orderId}
                            />
                        </OrderSummarySuspenseBoundary>
                    </div>
                </div>
            </main>
        </section>
    );
};
