import { z } from 'zod';
import { AbstractGetQuoteResponseSchema } from '../quote/get-quote.ts';

export const GetQuoteUrlSchema = z.strictObject({
    id: z.string().uuid(),
});

/**
 * validation schema for specific response payload
 */
const CustomRequestGetResponsePayload = z.strictObject({
    payload: z.strictObject({
        // add custom fields of the fulfillment channel here
        name: z.string(),
    }),
});

/**
 * validation schema for whole (merged) response payload
 */
export const GetQuoteResponseSchema = AbstractGetQuoteResponseSchema.merge(CustomRequestGetResponsePayload);
