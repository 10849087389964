import { useMutation, useQuery, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import {
    createProductGroupApi,
    getProductGroupByIdApi,
    getSelectedProductGroupsApi,
} from '@/clients/selectedProducts/selectedProductsClient';
import { PatchProductGroupRequestSchema, PatchProductGroupResponseSchema } from '@schuettflix/interfaces';
import { getOrderClient } from '../httpClients';
import { z } from 'zod';

export const useCreateProductGroup = () => {
    return {
        createProductGroup: useMutation({
            mutationKey: ['selected-product-group', 'create-'],
            mutationFn: createProductGroupApi,
        }),
    };
};

export const useUpdateProductGroup = (orderId: string) => {
    const queryClient = useQueryClient();

    const updateProductGroup = async (payload: z.input<typeof PatchProductGroupRequestSchema>) => {
        if (!payload.id) {
            throw new Error('Product group ID is required for updating a product group.');
        }

        return await getOrderClient()
            .patch('/v1/product-group', payload)
            .then(res => PatchProductGroupResponseSchema.parse(res.data));
    };

    return {
        updateProductGroup: useMutation({
            mutationKey: ['selected-product-group', 'update', orderId],
            mutationFn: (payload: z.input<typeof PatchProductGroupRequestSchema>) => updateProductGroup(payload),
            onSuccess: async () => {
                await queryClient.invalidateQueries({
                    queryKey: ['selected-product-group', 'get', orderId],
                });
            },
        }),
    };
};

export const useSelectedProductsGroups = (orderId: string) => {
    const query = useQuery({
        queryKey: ['selected-product-group', 'get', orderId],
        queryFn: () => getSelectedProductGroupsApi(orderId),
        refetchOnWindowFocus: false,
    });

    return {
        selectedProductGroups: query.data ?? [],
        ...query,
    };
};

export const useSelectedProductGroupById = (productGroupId: string) => {
    return useSuspenseQuery({
        queryKey: ['selected-product-group-by-id', 'get', productGroupId],
        queryFn: () => getProductGroupByIdApi(productGroupId),
        refetchOnWindowFocus: false,
    });
};

export const useSelectedProductGroup = (orderId: string, fetchOnce = false) => {
    return useSuspenseQuery({
        queryKey: ['selected-product-group', 'get', orderId],
        queryFn: () => getSelectedProductGroupsApi(orderId),
        refetchOnWindowFocus: false,
        select: data => data[0],
        ...(fetchOnce && { staleTime: Infinity, cacheTime: Infinity }),
    });
};

export const useDeleteProductGroup = () => {
    const deleteProductGroup = async (productGroupId?: string) => {
        if (!productGroupId) {
            throw new Error('Product group ID is required for deleting a product group.');
        }

        await getOrderClient().delete(`/v1/product-group/${productGroupId}`);
    };

    return { deleteProductGroup };
};
