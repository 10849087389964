import { useSuspenseQuery } from '@tanstack/react-query';
import { getPaymentTerms } from '@/clients/order/order';

export const usePaymentTerms = () => {
    return useSuspenseQuery({
        queryKey: ['paymentTerms'],
        queryFn: () => getPaymentTerms(),
        refetchOnWindowFocus: false,
    });
};

export const usePaymentTermsWithoutMarket = () => {
    return useSuspenseQuery({
        queryKey: ['paymentTerms'],
        queryFn: () => getPaymentTerms(),
        refetchOnWindowFocus: false,
        select: data => data.paymentTerms || [],
    });
};
