import React, { useState } from 'react';
import { ChevronDownIcon } from '@schuettflix/icons-react';
import { cn } from '@/shared/utils/cn.ts';

interface AccordionProps {
    headline: string;
    organisationType: string;
    className?: string;
    children: React.ReactNode;
    priceTag: React.ReactNode;
}

export const Accordion: React.FC<AccordionProps> = ({ headline, organisationType, className, priceTag, children }) => {
    const [isOpen, setIsOpen] = useState(true);

    return (
        <div className={cn('w-full', className)}>
            <div className="flex">
                <ChevronDownIcon
                    className={cn('shrink-0 transform self-center transition-transform duration-300', {
                        '-rotate-180': isOpen,
                    })}
                    onClick={() => setIsOpen(!isOpen)}
                />
                <div className="grow truncate px-6">
                    <p className="font-copy-sm">{organisationType}</p>
                    <h3 className="font-headline-md-strong truncate">{headline}</h3>
                </div>
                <div className="shrink-0 self-end">{priceTag}</div>
            </div>
            {isOpen && <div className="mt-6">{children}</div>}
        </div>
    );
};
