import { z } from 'zod';
import { SUPPORTED_UNITS_SCHEMA } from '../quote-unit/unit-types.ts';
import { PRODUCT_CATEGORY_SCHEMA } from '../selected-product/product-category.ts';
import { PRODUCT_TYPE_SCHEMA } from '../product-type/product-types.ts';

export const AbstractPostQuoteRequestMetaSchema = z.object({
    // order information
    orderId: z.string().uuid(),
    selectedProductGroupId: z.string().uuid(),
    selectedProductId: z.string().uuid(),
    positionProductId: z.string().uuid().nullable(),
    // product information
    amount: z.number(),
    unit: SUPPORTED_UNITS_SCHEMA,
    serviceDate: z.coerce.date(),
    productCategory: PRODUCT_CATEGORY_SCHEMA,
    productType: PRODUCT_TYPE_SCHEMA,
    // payment information
    partnerOrganizationId: z.number().int().positive().nullable(),
    orderingOrganizationId: z.number().int().positive(),
    platformOrganizationId: z.number().int().positive(),
    purchasePrice: z.number(),
    purchaseTaxClassId: z.string(),
    salesPrice: z.number(),
    salesTaxClassId: z.string(),
    currencyCode: z.string(),
    // callback information
    callbackUrl: z.string().url(),
});

/**
 * abstract quote validation schema for post request (to fulfillment channel) payload
 */
export const AbstractPostQuoteRequestSchema = z.object({
    meta: AbstractPostQuoteRequestMetaSchema,
    // will be overwritten by custom fulfillment channel payload
    payload: z.any(),
});
