import { z } from 'zod';
import { order } from '@schuettflix/schema-registry-ts';

export const BILLING_TYPE_SCHEMA = order.orderService.versions.v1.schemas.BILLING_TYPE_SCHEMA;
export type BILLING_TYPE = z.infer<typeof BILLING_TYPE_SCHEMA>;

export const BILLING_TYPE_LABEL = {
    CREDIT_NOTE: 'organization.billingMethod.creditNote',
    INVOICE: 'organization.billingMethod.invoice',
    PARTNER_INVOICE: 'organization.billingMethod.partnerInvoice',
} as const satisfies Record<BILLING_TYPE, string>;
