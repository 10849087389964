import { z } from 'zod';
import { SUPPORTED_UNITS_SCHEMA } from './unit-types.ts';
import { PRODUCT_TYPE_SCHEMA } from '../product-type/product-types.ts';

export const GetSupportedUnitListUrlSchema = z.strictObject({
    productType: PRODUCT_TYPE_SCHEMA,
});

export const GetSupportedUnitListResponseSchema = z.strictObject({
    id: z.string(),
    unitName: SUPPORTED_UNITS_SCHEMA,
    productTypeId: PRODUCT_TYPE_SCHEMA,
});
