import {
    GetPositionQuoteResponseSchema,
    PatchPositionQuoteRequestSchema,
    PostPositionQuoteRequestSchema,
} from '@schuettflix/interfaces';
import { getCustomRequestClient } from '@/clients/httpClients.ts';
import { z } from 'zod';

export const createPositionProduct = async ({
    product,
}: {
    product: z.infer<typeof PostPositionQuoteRequestSchema>;
}) => {
    return getCustomRequestClient()
        .post<z.infer<typeof PostPositionQuoteRequestSchema>>(`/v1/position-product`, product)
        .then(res => GetPositionQuoteResponseSchema.parse(res.data));
};

export const getPositionProduct = async ({
    positionProductId,
}: {
    positionProductId: z.infer<typeof PostPositionQuoteRequestSchema>['positionProductId'];
}) => {
    return getCustomRequestClient()
        .get<z.infer<typeof GetPositionQuoteResponseSchema>>(`/v1/position-product/${positionProductId}`)
        .then(res => GetPositionQuoteResponseSchema.parse(res.data));
};

export const patchPositionProduct = async ({
    productId,
    product,
}: {
    productId: z.infer<typeof PostPositionQuoteRequestSchema>['positionProductId'];
    product: z.infer<typeof PatchPositionQuoteRequestSchema>;
}) => {
    return getCustomRequestClient()
        .patch<void>(`/v1/position-product/${productId}`, product)
        .then(res => PatchPositionQuoteRequestSchema.parse(res.data));
};
