import * as Sentry from '@sentry/react';
import { useOktaAuth } from '@okta/okta-react';
import { useEffect } from 'react';

export const SentryUserProvider = () => {
    const { authState } = useOktaAuth();
    const email = authState?.idToken?.claims.email;

    useEffect(() => {
        if (email !== undefined) {
            Sentry.setUser({
                email: email,
            });
        } else {
            Sentry.setUser(null);
        }
    }, [email]);

    return null;
};
