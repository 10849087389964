import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useMonolithFrontendUrl } from '@/shared/hooks/useMonolithFrontendUrl.ts';
import { ThumbsUpImage } from '@/modules/order/components/OrderSummary/components/ThumbsUpImage.tsx';
import { CloseIcon } from '@schuettflix/icons-react';
import { IconButton } from '@schuettflix/react-components';

export interface OrderConfirmationProps {
    isConfirmationOpen: boolean;
    humanReadableId: string | null;
    orderId: string;
    setShowConfirmation: (showConfirmation: boolean) => void;
    onComplete: () => void;
}

export const OrderConfirmation: React.FC<OrderConfirmationProps> = ({
    isConfirmationOpen,
    humanReadableId,
    orderId,
    setShowConfirmation,
    onComplete,
}) => {
    const { t } = useTranslation();

    const { openUrl: openOverviewPage } = useMonolithFrontendUrl({
        path: '/dashboard',
        target: 'newTab',
    });

    return (
        <div
            className={`${
                isConfirmationOpen ? 'fixed' : 'hidden'
            } bg-light-gray-200 inset-0 h-screen items-center justify-center rounded-md p-8 text-center`}
        >
            <div className="mb-8 flex justify-end">
                <IconButton className="flex justify-end" icon={CloseIcon} onClick={() => openOverviewPage()} />
            </div>
            <div className="mx-auto mt-8 flex w-1/2 flex-col">
                <div className="flex justify-center">
                    <ThumbsUpImage />
                </div>
                <div className="flex flex-col items-center justify-center">
                    <div className="w-[609px] md:flex-row">
                        <div className="font-headline-xl-strong mb-2">
                            {t('sections.summary.buttons.orderPlaced', {
                                orderHumanReadableId: humanReadableId,
                            })}
                        </div>
                        <div className="pl-[10px] pr-[10px]">
                            <div className="font-copy-md mb-4 px-6 text-center">
                                {t('sections.summary.orderPlaced.description.orderConfirmation')}
                            </div>
                            <div className="font-copy-md px-6 text-center">
                                <Link to={`/fulfillment/${orderId}`} data-test="fulfillment-link">
                                    <span className="cursor-pointer underline">
                                        {t('sections.summary.orderPlaced.description.navigationAction_v2')}
                                    </span>
                                </Link>
                                ,&nbsp;{t('sections.summary.orderPlaced.description.viewOrder_v2')}.
                            </div>
                        </div>
                    </div>
                    <div className="mt-6 flex justify-center">
                        <a
                            className="font-copy-md-strong mr-4 cursor-pointer px-6 py-4"
                            onClick={() => openOverviewPage()}
                        >
                            {t('sections.summary.buttons.overviewPage')}
                            <span className="ml-4">&#8250;</span>
                        </a>
                        <a
                            className="font-copy-md-strong cursor-pointer bg-white px-6 py-4"
                            onClick={() => {
                                setShowConfirmation(false);
                                onComplete();
                            }}
                        >
                            {t('sections.summary.buttons.newOrder')}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};
