import { useSuspenseQuery } from '@tanstack/react-query';
import { getFulfillmentSummaryById, GetFulfillmentSummaryPayload } from './order';

export const useFulfillmentSummary = (orderId: string, payload: GetFulfillmentSummaryPayload) => {
    return useSuspenseQuery({
        queryKey: ['fulfillment-summary', orderId, payload],
        queryFn: async () => getFulfillmentSummaryById(orderId, payload),
        refetchOnWindowFocus: false,
    });
};
