import { QueryErrorResetBoundary, useQueryClient } from '@tanstack/react-query';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { ComponentType, ErrorInfo, PropsWithChildren } from 'react';

type QueryErrorBoundaryProps = PropsWithChildren & {
    fallbackComponent: ComponentType<FallbackProps>;
    onError?: (error: Error, info: ErrorInfo) => void;
    onReset?: (
        details:
            | {
                  reason: 'imperative-api';
                  args: any[];
              }
            | {
                  reason: 'keys';
                  prev: any[] | undefined;
                  next: any[] | undefined;
              }
    ) => void;
};

export function QueryErrorBoundary({ children, fallbackComponent, onError, onReset }: QueryErrorBoundaryProps) {
    const queryClient = useQueryClient();

    // NOTE: react query has (probably) issues with suspense mode, where an infinite retry is enabled on first reset.
    // We have to remove all inactive queries, this prevents the infinite error-retry behavior.
    const removeInactiveQueries = () => queryClient.removeQueries({ type: 'inactive' });

    return (
        <QueryErrorResetBoundary>
            {() => (
                <ErrorBoundary
                    FallbackComponent={fallbackComponent}
                    onError={onError}
                    onReset={details => {
                        removeInactiveQueries();
                        if (onReset) onReset(details);
                    }}
                >
                    {children}
                </ErrorBoundary>
            )}
        </QueryErrorResetBoundary>
    );
}
