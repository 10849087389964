import { z } from 'zod';
import { PRODUCT_GROUP_TYPE_SCHEMA } from '../product-group/product-group-type';
import { PRODUCT_CATEGORY_SCHEMA } from '../selected-product/product-category';
import { PRODUCT_TYPE_SCHEMA } from '../product-type/product-types';

export const GetPositionProductGroupProductsSchema = z.object({
    id: z.string().uuid(),
    index: z.number().nonnegative(),
    category: PRODUCT_CATEGORY_SCHEMA,
    type: PRODUCT_TYPE_SCHEMA,
    templateId: z.string().uuid().nullable(),
    positionProductGroupId: z.string().uuid(),
    createdAt: z.coerce.date(),
    updatedAt: z.coerce.date().nullable(),
});

export const GetPositionProductGroupUrlSchema = z.object({
    positionId: z.string().uuid(),
});

export const GetPositionProductGroupsResponseSchema = z.array(
    z.strictObject({
        id: z.string().uuid(),
        index: z.number().nonnegative(),
        name: z.string(),
        templateId: z.string().uuid(),
        type: PRODUCT_GROUP_TYPE_SCHEMA,
        positionId: z.string().uuid(),
        createdAt: z.coerce.date(),
        updatedAt: z.coerce.date(),
        products: z.array(GetPositionProductGroupProductsSchema).optional(),
    })
);
