import React from 'react';
import { useMarketAwarePosition } from '@/clients/position/useMarketAwarePosition.ts';
import { usePlatformInfo } from '@/clients/platformInfo/usePlatformInfo.ts';
import { PageHeader } from '@/shared/components/PageHeader.tsx';
import { useTranslation } from 'react-i18next';
import { SectionSummary } from '@/shared/components/SectionSummary.tsx';
import { ClientInformationSummaryContent } from '@/shared/components/ClientInformationSummaryContent.tsx';
import { PositionSettings } from '@/modules/position/components/PositionSettings/PositionSettings.tsx';
import { PositionSummary } from '@/modules/position/components/PositionSummary/PositionSummary.tsx';
import { PositionSectionWrapper } from './PositionSectionWrapper.tsx';
import { PositionProductSelection } from './PositionProductSelection.tsx';
import { useEditPositionState } from '@/modules/position/hooks/useEditPositionState.ts';
import {
    POSITION_SETTINGS_SECTION_NUMBER,
    POSITION_SUMMARY_SECTION_NUMBER,
    PRODUCT_SELECTION_SECTION_NUMBER,
    useCurrentSection,
} from '@/modules/position/hooks/useCurrentSection.ts';

export interface PositionSection {
    isSectionOpen: boolean;
    onEditCurrentSection: () => void;
    onMoveToNextSection?: () => void;
}

export interface PositionWizardProps {
    positionId: string;
}

export const PositionWizard: React.FC<PositionWizardProps> = ({ positionId }) => {
    const { t } = useTranslation();
    const { isEditing } = useEditPositionState();
    const { data: position } = useMarketAwarePosition(positionId);
    const { data: platformInfo } = usePlatformInfo();
    const platformOrganizationId = platformInfo?.platform.market.platformOrganizationId;
    const [currentSectionNumber, setCurrentSectionNumber] = useCurrentSection(PRODUCT_SELECTION_SECTION_NUMBER);

    if (!position || !platformOrganizationId) {
        return null;
    }

    return (
        <div className="bg-light-gray-200 min-h-screen">
            <PageHeader title={isEditing ? t('positions.edit-position') : t('positions.create-position')} />
            <main className="py-18 flex flex-col items-center gap-4 px-6">
                <PositionSectionWrapper>
                    <SectionSummary title={t('sections.clientInformation.title')}>
                        <ClientInformationSummaryContent
                            organizationId={position.customerInfo.organizationId}
                            constructionProjectId={position.customerInfo.constructionProjectId}
                            manualCostCenter={position.customerInfo.manualCostCenter}
                        />
                    </SectionSummary>
                </PositionSectionWrapper>

                <PositionSectionWrapper className="sm:max-w-[1280px]">
                    <PositionProductSelection
                        positionId={positionId}
                        isSectionOpen={currentSectionNumber === PRODUCT_SELECTION_SECTION_NUMBER}
                        onMoveToNextSection={() => setCurrentSectionNumber(POSITION_SETTINGS_SECTION_NUMBER)}
                        onEditCurrentSection={() => setCurrentSectionNumber(PRODUCT_SELECTION_SECTION_NUMBER)}
                    />
                </PositionSectionWrapper>

                <PositionSectionWrapper>
                    <PositionSettings
                        positionId={positionId}
                        isSectionOpen={currentSectionNumber === POSITION_SETTINGS_SECTION_NUMBER}
                        onMoveToNextSection={() => setCurrentSectionNumber(POSITION_SUMMARY_SECTION_NUMBER)}
                        onEditCurrentSection={() => setCurrentSectionNumber(POSITION_SETTINGS_SECTION_NUMBER)}
                    />
                </PositionSectionWrapper>

                <PositionSectionWrapper>
                    <PositionSummary
                        positionId={positionId}
                        isSectionOpen={currentSectionNumber === POSITION_SUMMARY_SECTION_NUMBER}
                        onEditCurrentSection={() => setCurrentSectionNumber(POSITION_SUMMARY_SECTION_NUMBER)}
                    />
                </PositionSectionWrapper>
            </main>
        </div>
    );
};
