import { z } from 'zod';
import { POSITION_STATUS_SCHEMA } from './shared.ts';

export const GetPositionsCountQueryParamsSchema = z.strictObject({
    constructionProjectId: z.string().uuid(),
    status: POSITION_STATUS_SCHEMA.optional().nullable(),
    active: z
        .enum(['1', '0'])
        .transform(value => value === '1')
        .optional(),
});

export const GetPositionsCountResponseSchema = z.object({
    total: z.number(),
});
