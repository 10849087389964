import React from 'react';
import { Tag } from '@schuettflix/react-components';
import { cn } from '@/shared/utils/cn.ts';

export interface TagSectionProps {
    title: string;
    description?: string;
    label?: string;
    type?: 'default' | 'error' | 'positive';
    className?: string;
    onChange?: (e: any) => any;
}

export const TagSection: React.FC<TagSectionProps> = ({ title, description, label, type = 'default', className }) => {
    return (
        <div className={cn(['font-copy-sm flex flex-col', className])}>
            <span className="text-subdued pb-2">{title}</span>
            <div className="flex items-center justify-center gap-4">
                {!!description && <p className="text whitespace-nowrap font-bold">{description}</p>}
                {!!label && (
                    <Tag
                        label={label}
                        subdued
                        type={type}
                        className={cn('mr-auto whitespace-nowrap font-bold', {
                            '!text-critical': type === 'error',
                            'text-success': type === 'positive',
                        })}
                    />
                )}
            </div>
        </div>
    );
};
