import { z } from 'zod';
import {
    MATERIAL_PRODUCT_CATEGORY_SCHEMA,
    MATERIAL_PRODUCT_TYPES_SCHEMA,
    MATERIAL_SUPPORTED_UNITS_SCHEMA,
} from './quote.ts';
import { AbstractPostQuoteRequestSchema } from '../quote/post-quote.ts';

// todo: merge with post-quote from custom request, see: MAR-2949
export const InternalMaterialPostQuoteRequestSchema = z.object({
    meta: z
        .object({
            id: z.string(),
            createdAt: z.coerce.date(),
        })
        .merge(AbstractPostQuoteRequestSchema.shape.meta)
        .merge(
            z.object({
                unit: MATERIAL_SUPPORTED_UNITS_SCHEMA,
                productCategory: MATERIAL_PRODUCT_CATEGORY_SCHEMA,
                productType: MATERIAL_PRODUCT_TYPES_SCHEMA,
                projectPositionNumber: z.string().optional(),
                partnerOrganizationId: z.number().int(),
            })
        )
        .omit({ callbackUrl: true }),
    payload: z.object({
        // add custom fields of the fulfillment channel here
        name: z.string(),
    }),
});

export type InternalMaterialPostQuoteRequest = z.infer<typeof InternalMaterialPostQuoteRequestSchema>;
