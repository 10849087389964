import { getOrderClient } from '@/clients/httpClients.ts';
import { ListResponseBody, GetTemplateResponseSchema, GetTemplateGroupResponseSchema } from '@schuettflix/interfaces';
import { z } from 'zod';

export async function getTemplateGroupsApi() {
    return getOrderClient()
        .get<ListResponseBody<z.input<typeof GetTemplateGroupResponseSchema>>>(`/v1/template-groups`)
        .then(response => {
            return response.data.items.map(templateGroup => GetTemplateGroupResponseSchema.parse(templateGroup));
        });
}

export async function getTemplateApi(templateId: string) {
    return getOrderClient()
        .get<ListResponseBody<z.input<typeof GetTemplateResponseSchema>>>(`/v1/templates/${templateId}`)
        .then(response => GetTemplateResponseSchema.parse(response.data));
}
