import { z } from 'zod';
import { PRODUCT_CATEGORY_SCHEMA } from '../selected-product/product-category';
import { PRODUCT_TYPE_SCHEMA } from '../product-type/product-types';
import { SUPPORTED_UNITS_SCHEMA } from '../quote-unit/unit-types.ts';

export const POSITION_PRODUCT_STATUS_SCHEMA = z.enum(['DRAFT', 'SUBMITTED', 'CANCELED']);
export type POSITION_PRODUCT_STATUS = z.infer<typeof POSITION_PRODUCT_STATUS_SCHEMA>;
export const floatingPointPrecisionSchema = z.number().refine(
    val => {
        return val >= 0 && Number.isFinite(val) && Math.abs(val - parseFloat(val.toFixed(3))) < 1e-10;
    },
    {
        message: 'Floating point numbers must be non-negative and have at most three decimal places!',
    }
);

export const PostPositionQuoteRequestSchema = z.object({
    positionId: z.string().uuid(),
    positionProductId: z.string().uuid(),
    name: z.string(),
    quota: floatingPointPrecisionSchema,
    unit: SUPPORTED_UNITS_SCHEMA,
    partnerOrganizationId: z.number().positive(),
    projectOwnerOrganizationId: z.number().positive(),
    platformOrganizationId: z.number().positive(),
    purchasePrice: z.number(),
    purchaseTaxClassId: z.string(),
    salesPrice: z.number(),
    salesTaxClassId: z.string(),
    currencyCode: z.string().max(3),
    productCategory: PRODUCT_CATEGORY_SCHEMA,
    productType: PRODUCT_TYPE_SCHEMA,
});
