import { useFormatters } from '@/shared/hooks/useFormatters/useFormatters.ts';
import {
    BILLING_TYPE,
    BILLING_TYPE_LABEL,
    ORDER_ORGANIZATION_TRANSACTIONAL_ROLE,
    ORGANIZATION_ROLE_LABEL,
    PRODUCT_CATEGORY_LABEL,
    SUPPORTED_UNIT_LABEL,
    Transaction,
} from '@schuettflix/interfaces';
import { useTranslation } from 'react-i18next';
import { Combobox, LoadingSpinner, Tag } from '@schuettflix/react-components';
import { usePaymentTerms } from '@/clients/finance/usePaymentTerms.ts';
import { Accordion } from '@/shared/components/Accordion.tsx';
import { usePositionSummary } from '@/clients/position/usePositionSummary.ts';
import { useUpdateOrganizationFinanceSettings } from './hooks/useUpdateOrganizationFinanceSettings.ts';
import { getOrganizationTotal } from './utils/getOrganizationTotal.ts';
import { getOrganizationMappedTransactions } from './utils/getOrganizationMappedTransactions.ts';
import { getSelectablePaymentTerms } from './utils/getSelectablePaymentTerms.ts';

type FinanceSettingsProps = {
    referenceId: string;
    showErrorMessages: boolean;
    disabled?: boolean;
    groups?: NonNullable<ReturnType<typeof usePositionSummary>['data']>['organizations'];
    context: 'ORDER' | 'FULFILLMENT' | 'POSITION';
};

export function FinanceSettings({
    groups = [],
    referenceId,
    showErrorMessages,
    disabled,
    context,
}: FinanceSettingsProps) {
    const { t } = useTranslation();
    const { formatNumberWithCurrency, formatNumberWithCurrencyPerUnit, formatNumber } = useFormatters();
    const { data: paymentTermsSettings, isLoading: isPaymentTermsSettingsLoading } = usePaymentTerms();
    const { updateOrganizationFinanceSettings } = useUpdateOrganizationFinanceSettings(referenceId);

    const updateFinanceSettings = (
        billingMethod: BILLING_TYPE,
        paymentTermId: number | null,
        organizationId: number,
        transactionalRole: ORDER_ORGANIZATION_TRANSACTIONAL_ROLE
    ) => {
        updateOrganizationFinanceSettings.mutate({
            financeSettings: { billingMethod, paymentTermId, transactionalRole },
            organizationId,
        });
    };

    const getProductCategoriesLabels = (
        transactionalRole: ORDER_ORGANIZATION_TRANSACTIONAL_ROLE,
        mappedTransactions: Map<'DEBTOR' | 'CREDITOR', Transaction[]>
    ) => {
        return [
            ...new Set(mappedTransactions.get(transactionalRole)?.flatMap(({ productCategory }) => productCategory)),
        ]
            .map(role => t(PRODUCT_CATEGORY_LABEL[role]))
            .join(', ');
    };

    if (!paymentTermsSettings) {
        return <LoadingSpinner block />;
    }

    return (
        <div className="mb-2 flex flex-col gap-12">
            {groups.map(group => {
                const total = getOrganizationTotal(group, context);
                const mappedTransactions = getOrganizationMappedTransactions(group);

                return (
                    <Accordion
                        key={group.orgId}
                        headline={group.orgName}
                        organisationType={group.functionalRoles
                            .map(role => t(ORGANIZATION_ROLE_LABEL[role]))
                            .join(', ')}
                        priceTag={
                            <Tag
                                label={formatNumberWithCurrency(Math.abs(total))}
                                subdued
                                type={total < 0 ? 'error' : 'positive'}
                                className={`whitespace-nowrap font-bold ${total < 0 ? '!text-critical' : 'text-success'}`}
                            />
                        }
                    >
                        <div className="flex flex-col gap-6">
                            {group.financeSettings.map(({ transactionalRole, selected, options }) => {
                                return (
                                    <div className="flex flex-col" key={transactionalRole}>
                                        {mappedTransactions.size > 1 && (
                                            <h3 className="font-copy-sm-strong mb-2">
                                                {getProductCategoriesLabels(transactionalRole, mappedTransactions)}
                                            </h3>
                                        )}

                                        <div className="font-copy-md">
                                            <table className="mb-4 w-full">
                                                <tbody>
                                                    {mappedTransactions.get(transactionalRole)?.map(transaction => {
                                                        return (
                                                            <tr key={transaction.productId} className="-mx-1">
                                                                <td className="min-w-[5.5rem] whitespace-nowrap px-1 py-2">
                                                                    {`${formatNumber(transaction.quantity)} ${t(SUPPORTED_UNIT_LABEL[transaction.unit])}`}
                                                                </td>
                                                                <td className="w-full max-w-0 truncate px-1 py-2">
                                                                    {transaction.productName}
                                                                </td>
                                                                <td className="min-w-[6.5rem] whitespace-nowrap px-1 py-2 text-right">
                                                                    {formatNumberWithCurrencyPerUnit(
                                                                        transaction.unitPriceAmount,
                                                                        transaction.unit,
                                                                        transaction.unitPriceCurrency
                                                                    )}
                                                                </td>
                                                                <td
                                                                    className={`min-w-[6.5rem] whitespace-nowrap px-1 py-2 text-right ${transactionalRole === 'DEBTOR' ? 'text-success' : 'text-critical'}`}
                                                                >
                                                                    {formatNumberWithCurrency(
                                                                        transaction.unitPriceAmount *
                                                                            transaction.quantity
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="flex justify-between">
                                            <Combobox
                                                options={
                                                    getSelectablePaymentTerms(
                                                        selected.billingMethod,
                                                        paymentTermsSettings.paymentTerms
                                                    ) || []
                                                }
                                                value={selected.paymentTermId || null}
                                                name={`paymentTerm-${group.orgId}-${transactionalRole}`}
                                                onChange={(paymentTermId: number | null) => {
                                                    updateFinanceSettings(
                                                        selected.billingMethod,
                                                        paymentTermId,
                                                        group.orgId,
                                                        transactionalRole
                                                    );
                                                }}
                                                errorMessage={
                                                    !selected.paymentTermId &&
                                                    selected.billingMethod !== 'PARTNER_INVOICE' &&
                                                    showErrorMessages
                                                        ? t('order.summary.comboboxPlaceholder')
                                                        : undefined
                                                }
                                                placeholder={
                                                    selected.billingMethod !== 'PARTNER_INVOICE'
                                                        ? t('order.summary.comboboxPlaceholder')
                                                        : '-'
                                                }
                                                label={t('orderSummary.paymentTerms')}
                                                className="mr-2 w-full"
                                                variant="md"
                                                disabled={
                                                    selected.billingMethod === 'PARTNER_INVOICE' ||
                                                    isPaymentTermsSettingsLoading ||
                                                    disabled
                                                }
                                            />
                                            <Combobox
                                                options={options.map(option => {
                                                    return {
                                                        value: option.billingMethod,
                                                        label: t(BILLING_TYPE_LABEL[option.billingMethod]),
                                                    };
                                                })}
                                                value={selected.billingMethod || null}
                                                onChange={(billingMethod: BILLING_TYPE | null) => {
                                                    if (!billingMethod) return;
                                                    updateFinanceSettings(
                                                        billingMethod,
                                                        billingMethod === 'PARTNER_INVOICE'
                                                            ? null
                                                            : selected.paymentTermId,
                                                        group.orgId,
                                                        transactionalRole
                                                    );
                                                }}
                                                label={t('orderSummary.billingMethod')}
                                                className="w-full"
                                                disabled={isPaymentTermsSettingsLoading || disabled}
                                                variant="md"
                                            />
                                        </div>
                                        {selected.billingMethod === 'PARTNER_INVOICE' && (
                                            <span className="font-copy-sm text-subdued mt-2">
                                                {t('orderSummary.disabledPaymentTerm.hint')}
                                            </span>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    </Accordion>
                );
            })}
        </div>
    );
}
