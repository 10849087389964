import React, { useState } from 'react';
import { createRoot } from 'react-dom/client';
import { createModule } from '@schuettflix/util-app-wrapper';

import { installI18n } from '@/tools/i18n/config';
import { appendStyles } from '@/shared/utils';
import { ExternalGlobals, installGlobals } from '@/shared/hooks/useGlobals';
import { QueryClient, QueryClientProvider, QueryCache } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { setupHttpClients } from './clients/httpClients';
import { BrowserRouter } from 'react-router-dom';
import { App } from '@/App';
import { useQueryErrorHandler } from '@/shared/hooks/useQueryErrorHandler.ts';
import { ToasterContextProvider } from '@/shared/context/ToasterContext.tsx';
import { Toaster } from '@/shared/components/Toaster.tsx';
import { FeatureFlagProvider } from '@/tools/featureFlags/FeatureFlagProvider.tsx';
import { initializeSentry } from '@/tools/sentry/initializeSentry';
import { useTranslation } from 'react-i18next';
import { I18nProvider } from '@schuettflix/planum-react';

initializeSentry();

export const appOrder = createModule<ExternalGlobals & { [key: string]: any }>({
    name: 'app-order',
    onInstall: options => {
        installGlobals(options);
        installI18n({ userLocale: options.userLocale });
    },
    onMount: (mountElement, { styleRoot, propsFromInstall }) => {
        const removeStyles = appendStyles({ target: styleRoot });
        const root = createRoot(mountElement);

        root.render(
            <React.StrictMode>
                <BrowserRouter>
                    <FeatureFlagProvider>
                        <ToasterContextProvider>
                            <ContextWrapper propsFromInstall={propsFromInstall} />
                        </ToasterContextProvider>
                    </FeatureFlagProvider>
                </BrowserRouter>
            </React.StrictMode>
        );

        return () => {
            root.unmount();
            removeStyles();
        };
    },
});

const ContextWrapper: React.FC<{ propsFromInstall: ExternalGlobals }> = ({ propsFromInstall }) => {
    const { handleCacheErrors } = useQueryErrorHandler();
    const [queryClient, setQueryClient] = useState<QueryClient>();

    if (!queryClient) {
        setQueryClient(
            new QueryClient({
                queryCache: new QueryCache({
                    ...handleCacheErrors,
                }),
            })
        );
    }

    const setupQueryClient = (globals: ExternalGlobals) => {
        const clear = () => {
            queryClient!.clear();
        };

        const uninstalls = [
            globals.accessToken.onUpdate(() => clear()),
            globals.baseUrl.backend.onUpdate(() => clear()),
            globals.baseUrl.constructionProject.onUpdate(() => clear()),
            globals.environment.onUpdate(() => clear()),
        ];

        return () => {
            uninstalls.forEach(uninstall => uninstall());
        };
    };

    setupHttpClients(propsFromInstall);
    setupQueryClient(propsFromInstall);

    const { i18n } = useTranslation();

    return (
        <QueryClientProvider client={queryClient!}>
            <I18nProvider locale={i18n.language}>
                <App />
                <Toaster />
                <ReactQueryDevtools />
            </I18nProvider>
        </QueryClientProvider>
    );
};
