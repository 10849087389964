import { Combobox } from '@headlessui/react';
import { TextField } from '@schuettflix/react-components';
import { forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterOptionsUser, User } from '@/clients/users/Users.ts';
import { useInternalRef } from '@/shared/hooks/useInternalRef.ts';
import { SearchOption } from '@/shared/components/SearchOption.tsx';
import { useFilterUsers, useUser } from '@/clients/users/useUsers.ts';

export interface SelectUserProps {
    disabled?: boolean;
    label: string;
    value: User['id'] | null;
    criticalized?: boolean;
    helperText?: string;
    onChange: (user: User | null) => void;
    filters: FilterOptionsUser;
}

export const SelectUser = forwardRef<HTMLInputElement, SelectUserProps>(
    ({ filters, onChange, disabled, value, criticalized, helperText, label }, forwardRef) => {
        const { t } = useTranslation();
        const ref = useInternalRef(forwardRef);

        const { data: user } = useUser(value);
        const [query, setQuery] = useState(user ? `${user.firstName} ${user.lastName}` : '');

        useEffect(() => {
            if (user) {
                setQuery(`${user.firstName} ${user.lastName}`);
            }
        }, [user]);

        const { data, isFetched } = useFilterUsers({
            ...filters,
            search: query,
        });
        const options = data?.items ?? [];

        return (
            <div>
                <Combobox
                    value={user || null}
                    onChange={user => {
                        if (!user) return;
                        setQuery(`${user.firstName} ${user.lastName}`);
                        onChange(user);
                    }}
                    disabled={disabled}
                >
                    {/* https://github.com/tailwindlabs/headlessui/discussions/1236#discussioncomment-2970969 */}
                    <Combobox.Button as="div">
                        <Combobox.Input
                            data-test="user-input"
                            as={TextField}
                            ref={ref}
                            label={label}
                            value={query}
                            displayValue={(user?: User) => user?.username ?? ''}
                            errorMessage={criticalized ? helperText : undefined}
                            isCritical={criticalized}
                            onClick={() => {
                                ref.current?.select();
                            }}
                            onChange={event => {
                                const { value } = event.target;
                                setQuery(value);
                                if (value.length === 0) {
                                    onChange(null);
                                }
                            }}
                        />
                    </Combobox.Button>
                    <div className="relative" data-test="user-list">
                        <Combobox.Options className="scroll font-copy-md bg-surface shadow-high absolute top-2 z-10 max-h-[250px] w-full overflow-y-auto rounded">
                            {options.length === 0 && isFetched ? (
                                <Combobox.Option value={null} disabled>
                                    <SearchOption
                                        active={false}
                                        selected={false}
                                        title={t('errorMessages.common.noResults') ?? ''}
                                    />
                                </Combobox.Option>
                            ) : (
                                options.map(u => (
                                    <Combobox.Option key={u.id} value={u}>
                                        {props => (
                                            <SearchOption
                                                {...props}
                                                title={`${u.firstName} ${u.lastName}`}
                                                description={<p className="font-normal not-italic">{u.username}</p>}
                                                side={u.id.toString()}
                                            />
                                        )}
                                    </Combobox.Option>
                                ))
                            )}
                        </Combobox.Options>
                    </div>
                </Combobox>
            </div>
        );
    }
);
