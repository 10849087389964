import { z } from 'zod';
import { GetCustomerInfoResponseSchema } from '../customer-info/get-customer-info.ts';
import { ORDER_STATUS_SCHEMA } from './order-status.ts';

/**
 * validation schema for response payload
 */
export const GetOrderListResponseSchema = z.object({
    id: z.string().uuid(),
    humanReadableId: z.string().length(11).nullable(),
    createdAt: z.coerce.date(),
    status: ORDER_STATUS_SCHEMA,
    customerInfo: GetCustomerInfoResponseSchema.nullable(),
});
