import { useSuspenseQuery } from '@tanstack/react-query';
import { getOrderById } from './order';
import { useMarketCode } from '@/shared/hooks/useMarketCode.ts';

export const useMarketAwareOrder = (orderId: string, fetchOnce = false) => {
    const query = useSuspenseQuery({
        queryKey: ['order', orderId],
        queryFn: async () => getOrderById(orderId),
        ...(fetchOnce && { staleTime: Infinity, cacheTime: Infinity }),
        refetchOnWindowFocus: false,
    });

    useMarketCode(query.data?.marketCode);

    return query;
};

export const useMarketOfOrder = (orderId: string) => {
    const query = useSuspenseQuery({
        queryKey: ['order', orderId],
        queryFn: async () => getOrderById(orderId),
    });

    return query.data?.marketCode;
};
