import { SummaryOrganization } from '@schuettflix/interfaces';

export function getOrganizationMappedTransactions(organization: SummaryOrganization) {
    return organization.transactions.reduce<Map<'DEBTOR' | 'CREDITOR', typeof organization.transactions>>(
        (acc, transaction) => {
            if (transaction.transactionalRole === 'DEBTOR') {
                return acc.set('DEBTOR', [...(acc.get('DEBTOR') || []), transaction]);
            }

            return acc.set('CREDITOR', [...(acc.get('CREDITOR') || []), transaction]);
        },
        new Map()
    );
}
