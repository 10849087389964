import { z } from 'zod';
import { PROJECT_CODE_SCHEMA } from '../project/project.ts';
import { SUPPORTED_UNITS_SCHEMA } from '../quote-unit/unit-types.ts';

export const PostOrderFulfillUrlSchema = z.object({
    orderId: z.string().uuid(),
});

export const PostOrderFulfillRequestSchema = z.object({
    clientInformation: z.object({
        projectCode: PROJECT_CODE_SCHEMA,
        userId: z.number().int(),
        manualCostCenter: z.string(),
    }),
    products: z
        .object({
            id: z.string().uuid(),
            quoteId: z.string().uuid(),
            name: z.string(),
            amount: z.number(),
            unit: SUPPORTED_UNITS_SCHEMA,
            currencyCode: z.string().length(3),
            partnerOrganizationId: z.number().int().positive(),
            orderingOrganizationId: z.number().int().positive(),
            platformOrganizationId: z.number().int().positive(),
            purchasePrice: z.number(),
            purchaseTaxClassId: z.string(),
            salesPrice: z.number(),
            salesTaxClassId: z.string(),
            // Check that service date is in the past
            serviceDate: z.coerce.date().refine(date => new Date() > date),
            supportingDocuments: z
                .array(
                    z.object({
                        description: z.string(),
                        urlPath: z.string(),
                        fileName: z.string(),
                    })
                )
                .nonempty(),
        })
        .array(),
});
