import React, { useState } from 'react';
import { PRODUCT_CATEGORIES, PRODUCT_CATEGORY, PRODUCT_GROUP_TYPE, PRODUCT_GROUP_TYPES } from '@schuettflix/interfaces';
import { useTranslation } from 'react-i18next';
import { useOrganizationSuspenseQuery } from '@/clients/organization/useOrganization.ts';
import { FulfillmentQuote, useFulfillmentContext } from '@/modules/fulfillment/context/FulfillmentContext.tsx';
import { FulfillmentCustomRequestProduct } from '@/CustomRequestProductChannel/modules/fulfillment/FulfillmentCustomRequestProduct.tsx';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteFulfillmentQuote } from '@/CustomRequestProductChannel/clients/CustomRequestClient.ts';
import { useSectionsValidity, useSetSectionsValidity } from '@/modules/fulfillment/hooks/useSectionsValidity.ts';
import { Button, Modal, ModalTrigger } from '@schuettflix/planum-react';
import { useFranco } from '@/modules/fulfillment/hooks/useFranco.ts';
import { FulfillmentCustomRequestProductForOrderedProduct } from '@/CustomRequestProductChannel/modules/fulfillment/FulfillmentCustomRequestProductForOrderedProduct.tsx';
import { AddProductButton } from '@/shared/components/AddProductButton/AddProductButton.tsx';
import { CloseIcon } from '@schuettflix/planum-icons-react';
import { ProductGroupHeader } from '@/shared/components/ProductGroupHeader/ProductGroupHeader.tsx';

type DraftFulfillmentProduct =
    | (Required<Pick<FulfillmentQuote, 'category' | 'type'>> &
          Partial<Omit<FulfillmentQuote, 'id'>> & { id: undefined })
    | (FulfillmentQuote & { index: number });

interface ProductSelectionSectionProps {
    disabled?: boolean;
}

export const ProductSelectionSection: React.FC<ProductSelectionSectionProps> = ({ disabled }) => {
    const { order, quotes, productGroup, isSubmitted } = useFulfillmentContext();
    const { data: orderingOrganization } = useOrganizationSuspenseQuery(order?.customerInfo?.organizationId);

    const originalOrderQuotes = quotes.filter(item => item.inOriginalOrder);
    const nonOriginalOrderQuotes = quotes.filter(item => !item.inOriginalOrder);

    const isFrancoCase = productGroup.type == PRODUCT_GROUP_TYPES.FRANCO;
    const isMerchantCase = productGroup.type == PRODUCT_GROUP_TYPES.MERCHANT;

    const { t } = useTranslation();
    const [draftQuotes, setDraftQuotes] = useState<DraftFulfillmentProduct[]>([]);
    const [deleteModal, setDeleteModal] = useState<
        { open: false } | { open: true; quoteId?: string | null; index?: number | null }
    >({ open: false });
    const queryClient = useQueryClient();
    const setSectionsValidity = useSetSectionsValidity();

    const deleteQuoteMutation = useMutation({
        mutationKey: ['selected-product-group', 'product-quote'],
        mutationFn: deleteFulfillmentQuote,
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ['quotes-by-order', productGroup.orderId] });
            void queryClient.invalidateQueries({
                queryKey: ['fulfillment-summary', productGroup.orderId],
            });
            setDeleteModal({ open: false });
        },
    });

    const getFrancoProductPerCategory = (category: PRODUCT_CATEGORY) => {
        if (!isFrancoCase) {
            return;
        }

        return originalOrderQuotes.find(quote => quote.category === category);
    };

    const francoMaterialQuote = getFrancoProductPerCategory(PRODUCT_CATEGORIES.MATERIAL);
    const francoTransportQuote = getFrancoProductPerCategory(PRODUCT_CATEGORIES.TRANSPORT);

    const { francoFormErrors, onSalesPriceChange } = useFranco({
        initialMaterialSalesPrice: francoMaterialQuote?.salesPrice.toNumber(),
        initialTransportSalesPrice: francoTransportQuote?.salesPrice.toNumber(),
        initialFrancoFormErrors: {},
    });

    const francoMaterialProductFormErrors = {
        ...(francoFormErrors.materialSalesPrice && { salesPrice: francoFormErrors.materialSalesPrice }),
    };

    const francoTransportProductFormErrors = {
        ...(francoFormErrors.transportSalesPrice && { salesPrice: francoFormErrors.transportSalesPrice }),
    };

    useSectionsValidity('product-selection-draft-quotes', draftQuotes.length === 0);

    if (!quotes || !orderingOrganization) {
        return null;
    }

    const getHintByProduct = (product: FulfillmentQuote) => {
        if (isFrancoCase) {
            return productGroup.templateId !== product.templateId
                ? t('product.fulfillment.productSelection.productIsNotFrancoHint')
                : t('product.fulfillment.productSelection.productIsFrancoHint');
        }

        if (isMerchantCase && productGroup.templateId !== product.templateId) {
            return t('product.fulfillment.productSelection.productIsNotFrancoHint');
        }
    };

    const getFrancoOverrideFormErrors = (productCategory: PRODUCT_CATEGORY) => {
        if (!isFrancoCase) {
            return;
        }

        if (productCategory === PRODUCT_CATEGORIES.MATERIAL) {
            return francoMaterialProductFormErrors;
        }

        if (productCategory === PRODUCT_CATEGORIES.TRANSPORT) {
            return francoTransportProductFormErrors;
        }
    };

    const handleFrancoSalesPriceChange = (salesPrice: number, productCategory: PRODUCT_CATEGORY) => {
        if (!isFrancoCase) {
            return;
        }

        if (productCategory === PRODUCT_CATEGORIES.MATERIAL) {
            onSalesPriceChange(salesPrice, 'materialSalesPrice');
        }

        if (productCategory === PRODUCT_CATEGORIES.TRANSPORT) {
            onSalesPriceChange(salesPrice, 'transportSalesPrice');
        }
    };

    return (
        <div className="rounded bg-white [counter-reset:productCounter]">
            <div className="border-b p-4">
                <Header
                    name={productGroup.name ?? ''}
                    templateId={productGroup.templateId}
                    groupType={productGroup.type}
                />
            </div>
            <div className="divide-y">
                {originalOrderQuotes.map(product => {
                    return (
                        <FulfillmentCustomRequestProductForOrderedProduct
                            key={product.id}
                            defaultValue={product}
                            orderingOrganization={orderingOrganization}
                            hint={getHintByProduct(product)}
                            groupType={productGroup.type}
                            disabled={disabled}
                            overrideFormErrors={getFrancoOverrideFormErrors(product.category)}
                            positionProductId={product.positionProductId}
                            onSalesPriceChange={salesPrice =>
                                handleFrancoSalesPriceChange(salesPrice, product.category)
                            }
                        />
                    );
                })}

                {nonOriginalOrderQuotes.map(quote => {
                    return (
                        <FulfillmentCustomRequestProduct
                            key={quote.id}
                            product={{
                                id: quote.id,
                                selectedProductGroupId: '',
                                category: quote.category,
                                type: quote.type,
                                positionProductId: null,
                                index: 0,
                            }}
                            quote={{
                                ...quote,
                                partnerOrganizationId: quote.partnerOrganizationId ?? null,
                                purchasePrice: quote.purchasePrice.toNumber(),
                                salesPrice: quote.salesPrice.toNumber(),
                                name: quote.name,
                            }}
                            templateId={productGroup.templateId}
                            orderId={productGroup.orderId}
                            showValidation={true}
                            enableDelete={!disabled}
                            disabled={disabled}
                            showProductPriceNotAddedHint={productGroup.type === PRODUCT_GROUP_TYPES.FRANCO}
                            onCompleteChange={() => {
                                void queryClient.invalidateQueries({
                                    queryKey: ['payment-terms-fulfillment', productGroup.orderId],
                                });
                            }}
                            onQuoteChange={() => {
                                void queryClient.invalidateQueries({
                                    queryKey: ['quotes-by-order', productGroup.orderId],
                                });
                                void queryClient.invalidateQueries({
                                    queryKey: ['fulfillment-summary', productGroup.orderId],
                                });
                            }}
                            onDeleteProduct={() => {
                                setDeleteModal({
                                    open: true,
                                    quoteId: quote.quoteId,
                                });
                            }}
                            onFormChange={form => {
                                setSectionsValidity(`product-selection-custom-${quote.id}`, form.isValid);
                            }}
                        />
                    );
                })}

                {draftQuotes.map((quote, index) => {
                    const draftIndex = originalOrderQuotes.length + nonOriginalOrderQuotes.length + index + 1;

                    return (
                        <FulfillmentCustomRequestProduct
                            key={`draft-${index}`}
                            orderId={productGroup.orderId}
                            templateId={productGroup.templateId}
                            product={{
                                id: quote.id ?? '',
                                selectedProductGroupId: productGroup.id,
                                category: quote.category,
                                type: quote.type,
                                positionProductId: null,
                                index: draftIndex,
                            }}
                            quote={
                                quote.id
                                    ? {
                                          ...quote,
                                          partnerOrganizationId: quote.partnerOrganizationId ?? null,
                                          purchasePrice: quote.purchasePrice.toNumber(),
                                          salesPrice: quote.salesPrice.toNumber(),
                                          name: quote.name,
                                      }
                                    : undefined
                            }
                            showValidation={isSubmitted}
                            enableDelete
                            showProductPriceNotAddedHint={productGroup.type === PRODUCT_GROUP_TYPES.FRANCO}
                            onCompleteChange={(_, valid) => {
                                if (valid) {
                                    setDraftQuotes(draftQuotes.filter((_, i) => i !== index));
                                    void queryClient.invalidateQueries({
                                        queryKey: ['payment-terms-fulfillment', productGroup.orderId],
                                    });
                                } else {
                                    // show validation
                                }
                            }}
                            onQuoteChange={() => {
                                void queryClient.invalidateQueries({
                                    queryKey: ['quotes-by-order', productGroup.orderId],
                                });
                                void queryClient.invalidateQueries({
                                    queryKey: ['fulfillment-summary', productGroup.orderId],
                                });
                            }}
                            onDeleteProduct={() => {
                                setDeleteModal({
                                    open: true,
                                    index,
                                });
                            }}
                        />
                    );
                })}
            </div>

            {!disabled && (
                <>
                    <AddProductButton
                        templateId={productGroup.templateId}
                        onAddProduct={product =>
                            setDraftQuotes([
                                ...draftQuotes,
                                {
                                    id: undefined,
                                    category: product.category,
                                    type: product.type,
                                },
                            ])
                        }
                    />
                    <ModalTrigger
                        isOpen={deleteModal.open}
                        onOpenChange={() => setDeleteModal({ open: !deleteModal.open })}
                    >
                        <Modal>
                            {({ close }) => (
                                <>
                                    <Modal.Heading onAbort={close}>
                                        {t('sections.productGroupSelection.askProductDeletion')}
                                    </Modal.Heading>
                                    <Modal.Content>
                                        {t('sections.productGroupSelection.productDeletionHint')}
                                    </Modal.Content>
                                    <Modal.Actions>
                                        <Button onPress={close} prominence="secondary" leadingSlot={<CloseIcon />}>
                                            {t('sections.productGroupSelection.cancelProductDeletion')}
                                        </Button>
                                        <Button
                                            isLoading={deleteQuoteMutation.isPending}
                                            onPress={() => {
                                                if (deleteModal.open && deleteModal.quoteId) {
                                                    deleteQuoteMutation.mutate({ quoteId: deleteModal.quoteId });
                                                }

                                                if (deleteModal.open && deleteModal.index !== undefined) {
                                                    setDraftQuotes(
                                                        draftQuotes.filter((_, i) => i !== deleteModal.index)
                                                    );
                                                    setDeleteModal({ open: false });
                                                }
                                            }}
                                        >
                                            {t('sections.productGroupSelection.confirmProductDeletion')}
                                        </Button>
                                    </Modal.Actions>
                                </>
                            )}
                        </Modal>
                    </ModalTrigger>
                </>
            )}
        </div>
    );
};

interface HeaderProps {
    name: string;
    templateId: string;
    groupType: PRODUCT_GROUP_TYPE;
}

const Header: React.FC<HeaderProps> = ({ name, templateId, groupType }) => {
    const { quotes: data } = useFulfillmentContext();

    return (
        <ProductGroupHeader
            products={data.map(quote => ({
                category: quote.category,
                type: quote.type,
                templateId: quote.templateId ?? null,
                createdAt: new Date(),
                updatedAt: new Date(),
                quote: {
                    amount: quote.amount,
                    category: quote.category,
                    currencyCode: quote.currencyCode,
                    salesPrice: quote.salesPrice.toNumber(),
                    purchasePrice: quote.purchasePrice.toNumber(),
                    unit: quote.unit,
                    templateId: quote.templateId,
                },
            }))}
            productGroupTemplateId={templateId}
            productGroupType={groupType}
            productGroupName={name}
            productGroupNameDisabled={true}
        />
    );
};
