import { z } from 'zod';

export const TEMPLATE_TYPE_SCHEMA = z.enum(['FRANCO', 'MERCHANT', 'STANDARD']);

export type TEMPLATE_TYPES = z.infer<typeof TEMPLATE_TYPE_SCHEMA>;

export const TEMPLATE_GROUP_TITLE_SCHEMA = z.enum([
    'BULK_GOODS_DELIVERY',
    'RECYCLING_BULK_GOODS_DELIVERY',
    'ASPHALT_DELIVERY',
    'CONCRETE_DELIVERY',
    'NON_HAZARDOUS_WASTE_RECYCLING',
    'HAZARDOUS_WASTE_RECYCLING',
    'NON_HAZARDOUS_WASTE_DISPOSAL',
    'HAZARDOUS_WASTE_DISPOSAL',
    'NON_HAZARDOUS_WASTE_RECYCLING_MATERIAL',
    'HAZARDOUS_WASTE_RECYCLING_MATERIAL',
    'NON_HAZARDOUS_WASTE_DISPOSAL_MATERIAL',
    'HAZARDOUS_WASTE_DISPOSAL_MATERIAL',
    'BULK_GOODS',
    'RECYCLING_BULK_GOODS',
    'ASPHALT',
    'CONCRETE',
    'TRANSPORT_PER_HOUR',
    'TRANSPORT_PER_TON',
    'TRANSPORT',
    'SERVICE_ANALYSIS',
    'SERVICE_ACCOMPANYING_DOCUMENT',
    'SERVICE_WAITING_TIME',
    'SERVICE_TOLL',
    'SERVICE_MATERIAL',
    'SERVICE_TRANSPORT',
]);

export type TEMPLATE_GROUP_TITLE = z.infer<typeof TEMPLATE_GROUP_TITLE_SCHEMA>;

export const TEMPLATE_GROUP_TITLE_LABEL = {
    BULK_GOODS_DELIVERY: 'productTemplate.bulkGoodsDelivery',
    RECYCLING_BULK_GOODS_DELIVERY: 'productTemplate.recyclingBulkGoodsDelivery',
    ASPHALT_DELIVERY: 'productTemplate.asphaltDelivery',
    CONCRETE_DELIVERY: 'productTemplate.concreteDelivery',
    NON_HAZARDOUS_WASTE_RECYCLING: 'productTemplate.nonHazardousWasteIncludingTransport',
    HAZARDOUS_WASTE_RECYCLING: 'productTemplate.hazardousWasteIncludingTransport',
    NON_HAZARDOUS_WASTE_DISPOSAL: 'productTemplate.nonHazardousWasteIncludingTransport',
    HAZARDOUS_WASTE_DISPOSAL: 'productTemplate.hazardousWasteIncludingTransport',
    NON_HAZARDOUS_WASTE_RECYCLING_MATERIAL: 'productTemplate.nonHazardousWasteMaterial',
    HAZARDOUS_WASTE_RECYCLING_MATERIAL: 'productTemplate.hazardousWasteMaterial',
    NON_HAZARDOUS_WASTE_DISPOSAL_MATERIAL: 'productTemplate.nonHazardousWasteMaterial',
    HAZARDOUS_WASTE_DISPOSAL_MATERIAL: 'productTemplate.hazardousWasteMaterial',
    BULK_GOODS: 'productTemplate.bulkGoods',
    RECYCLING_BULK_GOODS: 'productTemplate.recyclingBulkGoods',
    ASPHALT: 'productTemplate.asphalt',
    CONCRETE: 'productTemplate.concrete',
    SERVICE_MATERIAL: 'productTemplate.customService',
    TRANSPORT_PER_HOUR: 'productTemplate.transportPerHour',
    TRANSPORT_PER_TON: 'productTemplate.transportPerTon',
    TRANSPORT: 'productTemplate.transport',
    SERVICE_ACCOMPANYING_DOCUMENT: 'productTemplate.customServiceAccompanyingDocument',
    SERVICE_ANALYSIS: 'productTemplate.customServiceAnalysis',
    SERVICE_WAITING_TIME: 'productTemplate.customServiceWaitingTime',
    SERVICE_TOLL: 'productTemplate.customServiceToll',
    SERVICE_TRANSPORT: 'productTemplate.customServiceTransport',
} as const satisfies Record<TEMPLATE_GROUP_TITLE, string>;

export const PRODUCT_GROUP_TEMPLATE_TITLE_SCHEMA = z.enum([
    'BULK_GOODS_DELIVERY',
    'RECYCLING_BULK_GOODS_DELIVERY',
    'ASPHALT_DELIVERY',
    'CONCRETE_DELIVERY',
    'NON_HAZARDOUS_WASTE_RECYCLING',
    'HAZARDOUS_WASTE_RECYCLING',
    'NON_HAZARDOUS_WASTE_DISPOSAL',
    'HAZARDOUS_WASTE_DISPOSAL',
    'NON_HAZARDOUS_WASTE_RECYCLING_MATERIAL',
    'HAZARDOUS_WASTE_RECYCLING_MATERIAL',
    'NON_HAZARDOUS_WASTE_DISPOSAL_MATERIAL',
    'HAZARDOUS_WASTE_DISPOSAL_MATERIAL',
    'BULK_GOODS',
    'RECYCLING_BULK_GOODS',
    'ASPHALT',
    'CONCRETE',
    'NON_HAZARDOUS_WASTE',
    'HAZARDOUS_WASTE',
    'BILLED_PER_HOUR',
    'BILLED_PER_TON',
    'FLAT_RATE',
    'SERVICE_ANALYSIS',
    'SERVICE_ACCOMPANYING_DOCUMENT',
    'SERVICE_TOLL',
    'SERVICE_WAITING_TIME',
    'SERVICE_MATERIAL',
    'SERVICE_TRANSPORT',
]);

export type PRODUCT_GROUP_TEMPLATE_TITLE = z.infer<typeof PRODUCT_GROUP_TEMPLATE_TITLE_SCHEMA>;

export const PRODUCT_GROUP_TEMPLATE_TITLE_LABEL = {
    BULK_GOODS_DELIVERY: 'productTemplate.bulkGoodsDelivery',
    RECYCLING_BULK_GOODS_DELIVERY: 'productTemplate.recyclingBulkGoodsDelivery',
    ASPHALT_DELIVERY: 'productTemplate.asphaltDelivery',
    CONCRETE_DELIVERY: 'productTemplate.concreteDelivery',
    NON_HAZARDOUS_WASTE_RECYCLING: 'productTemplate.nonHazardousWasteIncludingTransport',
    HAZARDOUS_WASTE_RECYCLING: 'productTemplate.hazardousWasteIncludingTransport',
    NON_HAZARDOUS_WASTE_DISPOSAL: 'productTemplate.nonHazardousWasteIncludingTransport',
    HAZARDOUS_WASTE_DISPOSAL: 'productTemplate.hazardousWasteIncludingTransport',
    NON_HAZARDOUS_WASTE_RECYCLING_MATERIAL: 'productTemplate.nonHazardousWasteMaterial',
    HAZARDOUS_WASTE_RECYCLING_MATERIAL: 'productTemplate.hazardousWasteMaterial',
    NON_HAZARDOUS_WASTE_DISPOSAL_MATERIAL: 'productTemplate.nonHazardousWasteMaterial',
    HAZARDOUS_WASTE_DISPOSAL_MATERIAL: 'productTemplate.hazardousWasteMaterial',
    BULK_GOODS: 'productTemplate.bulkGoods',
    RECYCLING_BULK_GOODS: 'productTemplate.recyclingBulkGoods',
    ASPHALT: 'productTemplate.asphalt',
    CONCRETE: 'productTemplate.concrete',
    NON_HAZARDOUS_WASTE: 'productTemplate.nonHazardousWaste',
    HAZARDOUS_WASTE: 'productTemplate.hazardousWaste',
    BILLED_PER_HOUR: 'productTemplate.billedPerHour',
    BILLED_PER_TON: 'productTemplate.billedPerTon',
    FLAT_RATE: 'productTemplate.flatrate',
    SERVICE_ACCOMPANYING_DOCUMENT: 'productTemplate.customServiceAccompanyingDocument',
    SERVICE_ANALYSIS: 'productTemplate.customServiceAnalysis',
    SERVICE_TOLL: 'productTemplate.customServiceToll',
    SERVICE_WAITING_TIME: 'productTemplate.customServiceWaitingTime',
    SERVICE_MATERIAL: 'productTemplate.serviceMaterial',
    SERVICE_TRANSPORT: 'productTemplate.serviceTransport',
} as const satisfies Record<PRODUCT_GROUP_TEMPLATE_TITLE, string>;

export const TEMPLATE_NAME_LABEL = {
    FRANCO: 'template.name.franco',
    MERCHANT: 'template.name.merchant',
    STANDARD: 'template.name.standard',
} as const satisfies Record<TEMPLATE_TYPES, string>;

export const TEMPLATE_GROUP_DESCRIPTION_SCHEMA = z.enum([
    'FRANCO_DESCRIPTION',
    'MERCHANT_DESCRIPTION',
    'TRANSPORT_DESCRIPTION',
    'RECYCLING_DESCRIPTION',
    'DISPOSAL_DESCRIPTION',
]);

export type TEMPLATE_GROUP_DESCRIPTION = z.infer<typeof TEMPLATE_GROUP_DESCRIPTION_SCHEMA>;

export const TEMPLATE_GROUP_DESCRIPTION_LABEL = {
    FRANCO_DESCRIPTION: 'productTemplateGroup.description.franco',
    MERCHANT_DESCRIPTION: 'productTemplateGroup.description.merchant',
    TRANSPORT_DESCRIPTION: 'productTemplateGroup.description.transport',
    RECYCLING_DESCRIPTION: 'productTemplateGroup.description.recycling',
    DISPOSAL_DESCRIPTION: 'productTemplateGroup.description.disposal',
} as const satisfies Record<TEMPLATE_GROUP_DESCRIPTION, string>;
