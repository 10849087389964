import { LoadingSpinner } from '@schuettflix/react-components';
import React from 'react';

export const FullscreenLoading: React.FC = () => {
    return (
        <div className="flex h-screen items-center justify-center">
            <LoadingSpinner />
        </div>
    );
};
