import { useMutation } from '@tanstack/react-query';
import { fulfillOrder } from './order';
import { z } from 'zod';
import { PostOrderFulfillRequestSchema } from '@schuettflix/interfaces';
import { useQueryErrorHandler } from '@/shared/hooks/useQueryErrorHandler.ts';

export const useFulfillOrderMutation = (orderId: string) => {
    const { handleMutationErrors } = useQueryErrorHandler();

    return useMutation({
        ...handleMutationErrors,
        mutationFn: (payload: z.input<typeof PostOrderFulfillRequestSchema>) => fulfillOrder(orderId, payload),
    });
};
