import { useState } from 'react';
import type {
    CustomRequestProductForm,
    CustomRequestProduct,
    BaseCustomRequestProductProps,
} from '@/CustomRequestProductChannel/components/CustomRequestProduct';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';

// This "FastMap" hooks is needed to ensure that whenever the maps values are read,
// the latest values are returned. Otherwise race conditions can happen, as the update
// of the state would take too long until the hooked component rerenders.
// As we do not have a requirement to rerender the components when the map values changes
// we can waive the rerender and therefore are not depended to to "set" the state.
function useFastMap<T, Z>(v: Map<T, Z>) {
    const [map] = useState(v);
    return map;
}

type UseFrancoReturn = {
    storeProductFormValues: (product: CustomRequestProduct, values: Partial<CustomRequestProductForm>) => void;
    leadingValues: BaseCustomRequestProductProps['leadingValues'];
    allSalesPricesHaveSameSign: boolean;
    FrancoSchema: BaseCustomRequestProductProps['intersectionSchema'];
};
export function useFranco(): UseFrancoReturn {
    const { t } = useTranslation();
    const [productFormValuesStateMap, setProductFormValuesStateMap] = useState<
        Map<CustomRequestProduct, Partial<CustomRequestProductForm>>
    >(new Map());
    const productFormValuesFastMap = useFastMap<CustomRequestProduct, Partial<CustomRequestProductForm>>(new Map());

    const storeProductFormValues = (product: CustomRequestProduct, values: Partial<CustomRequestProductForm>) => {
        for (const _p of productFormValuesFastMap.keys()) {
            if (_p.id === product.id) {
                product = _p;
                break;
            }
        }
        productFormValuesFastMap.set(product, values);
        setProductFormValuesStateMap(productFormValuesFastMap);
    };

    const materialValues = Array.from(productFormValuesFastMap.entries()).find(
        ([{ category }]) => category === 'MATERIAL'
    )?.[1];

    const determineSameSalesPriceSign = (map?: Map<CustomRequestProduct, Partial<CustomRequestProductForm>>) => {
        const formValues = Array.from((map ?? productFormValuesFastMap).values());
        const referenceSalesPrice = formValues.find(({ salesPrice }) => salesPrice !== undefined)?.salesPrice;
        return formValues.length > 0
            ? formValues.every(
                  ({ salesPrice }) =>
                      salesPrice === undefined ||
                      Math.sign(Number(salesPrice) || 1) === Math.sign(Number(referenceSalesPrice) || 1)
              )
            : true;
    };

    return {
        storeProductFormValues,
        leadingValues: {
            amount: materialValues?.amount,
            unit: materialValues?.unit,
        },
        allSalesPricesHaveSameSign: determineSameSalesPriceSign(productFormValuesStateMap),
        // The schema that is intersected with the custom request product form schema.
        // This schema is used to apply a franco case specific validation (salesPrice must have the same sign).
        FrancoSchema: z.object({
            salesPrice: z.string().superRefine((value, ctx) => {
                const allSalesPricesHaveSameSign = determineSameSalesPriceSign();
                if (!allSalesPricesHaveSameSign) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: t('product.addPosition.errorMessage.francoSalesPrice'),
                    });
                }
            }),
        }),
    };
}
