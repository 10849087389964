import z from 'zod';
import { PROJECT_CODE_SCHEMA } from '../project/project.ts';

export const PostCustomerInfoUrlSchema = z.strictObject({
    orderId: z.string().uuid(),
});

/**
 * validation schema for request payload
 */
export const PostCustomerInfoRequestSchema = z.strictObject({
    organizationId: z.number(),
    userId: z.number(),
    manualCostCenter: z.string().optional().nullable(),
    constructionProjectId: z.string().uuid(),
    projectCode: PROJECT_CODE_SCHEMA,
});

/**
 * validation schema for response payload
 */
export const PostCustomerInfoResponseSchema = z.strictObject({
    id: z.string(),
    orderId: z.string().uuid(),
    organizationId: z.number(),
    userId: z.number(),
    manualCostCenter: z.string().optional().nullable(),
    constructionProjectId: z.string(),
    projectCode: PROJECT_CODE_SCHEMA,
});
