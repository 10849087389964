import { z } from 'zod';
import { POSITION_STATUS_SCHEMA } from './shared';
import { GetPositionResponseSchema } from './get-position';

export const GetPositionsQueryParamsSchema = z.strictObject({
    constructionProjectId: z.string().uuid(),
    page: z.string().regex(/^\d+$/).transform(Number).pipe(z.number().positive()).default('1'),
    perPage: z.string().regex(/^\d+$/).transform(Number).pipe(z.number().positive()).default('10'),
    status: POSITION_STATUS_SCHEMA.optional().nullable(),
    active: z
        .enum(['1', '0'])
        .transform(value => value === '1')
        .optional(),
    sortBy: z.string().optional().nullable().default('createdAt'),
    sortDirection: z.enum(['asc', 'desc']).optional().nullable().default('desc'),
});

export const GetPositionsResponseSchema = z.object({
    positions: z.array(GetPositionResponseSchema),
    page: z.number(),
    perPage: z.number(),
    total: z.number(),
    totalPages: z.number(),
});
