import { z } from 'zod';
import { QUOTE_STATUS_SCHEMA } from '../quote/quote-status.ts';
import { SUPPORTED_UNITS_SCHEMA } from '../quote-unit/unit-types.ts';
import { CURRENCY_SCHEMA } from '../currency/currency.ts';

/**
 * default schema for nested product quote in responses
 */
export const NestedProductQuoteResponseSchema = z.strictObject({
    id: z.string().uuid(),
    status: QUOTE_STATUS_SCHEMA,
    createdAt: z.coerce.date(),
    updatedAt: z.coerce.date(),
    orderId: z.string().uuid(),
    selectedProductGroupId: z.string().uuid(),
    selectedProductId: z.string().uuid(),
    amount: z.number(),
    unit: SUPPORTED_UNITS_SCHEMA,
    serviceDate: z.string(),
    partnerOrganizationId: z.number().int().positive().nullable(),
    orderingOrganizationId: z.number().int().positive(),
    platformOrganizationId: z.number().int().positive(),
    purchasePrice: z.number(),
    purchaseTaxClassId: z.string(),
    salesPrice: z.number(),
    salesTaxClassId: z.string(),
    currencyCode: CURRENCY_SCHEMA,
    quoteData: z.any(),
});

export type NestedProductQuoteResponse = z.input<typeof NestedProductQuoteResponseSchema>;
