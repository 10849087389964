import z from 'zod';
import { ORDER_ORGANIZATION_ROLE_SCHEMA } from '../finance-settings/role.ts';
import { PaymentSettingsWithOptionsResponseSchema } from '../finance-settings/payment-settings.ts';
import { TransactionalTotalsSchema } from '../order-summary/totals.ts';
import { TransactionSchema } from '../order-summary/transactions.ts';

export const SummaryOrganizationSchema = z.strictObject({
    orgId: z.number(),
    functionalRoles: z.array(ORDER_ORGANIZATION_ROLE_SCHEMA),
    totals: z.array(TransactionalTotalsSchema),
    transactions: z.array(TransactionSchema),
    financeSettings: z.array(PaymentSettingsWithOptionsResponseSchema),
});

export const GetPositionSummaryUrlSchema = z.strictObject({
    positionId: z.string().uuid(),
});

export const GetPositionSummaryResponseSchema = z.strictObject({
    organizations: z.array(SummaryOrganizationSchema),
});

export type SummaryOrganization = z.infer<typeof SummaryOrganizationSchema>;
