import z, { string } from 'zod';
import { ACTIVE_QUOTE_STATUS_SCHEMA } from '../quote/quote-status.ts';
import { SUPPORTED_UNITS_SCHEMA } from '../quote-unit/unit-types.ts';
import { PRODUCT_TYPE_SCHEMA } from '../product-type/product-types.ts';
import { PRODUCT_CATEGORY_SCHEMA } from '../selected-product/product-category.ts';

export const GetQuoteListByOrderIdQuerySchema = z.object({
    id: z.string().uuid(),
});

export const GetQuoteListByOrderIdResponsePayload = z.object({
    payload: z.object({
        id: z.string().uuid(),
        selectedProductId: z.string().uuid(),
        name: z.string(),
        currencyCode: string(),
        createdAt: z.coerce.date(),
        updatedAt: z.coerce.date().optional(),
        selectedProductGroupId: z.string().uuid(),
        amount: z.number(),
        partnerOrganizationId: z.number().int().positive(),
        orderingOrganizationId: z.number().int().positive(),
        platformOrganizationId: z.number().int().positive(),
        orderId: z.string().uuid(),
        purchasePrice: z.number(),
        purchaseTaxClassId: z.string(),
        salesPrice: z.number(),
        salesTaxClassId: z.string(),
        status: ACTIVE_QUOTE_STATUS_SCHEMA,
        serviceDate: z.coerce.date(),
        unit: SUPPORTED_UNITS_SCHEMA,
        productType: PRODUCT_TYPE_SCHEMA,
        productCategory: PRODUCT_CATEGORY_SCHEMA,
    }),
});
