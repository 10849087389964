import { getMarketsFromToken } from './getMarketsFromToken.ts';

export const marketFromLocalStorage = () => {
    const platform = localStorage.getItem('platform');

    const authToken = authTokenFromLocalStorage();
    if (authToken) {
        const market = getMarketsFromToken(authToken).shift();
        return platform || market || '';
    }
    return platform || '';
};

export const authTokenFromLocalStorage = () => {
    const oktaTokenStorage = localStorage.getItem('okta-token-storage');
    if (oktaTokenStorage?.includes('idToken')) {
        return oktaTokenStorage.split('"idToken":"')[1].split('"')[0];
    }
    return '';
};

export const isAuthenticated = () =>
    !!localStorage.getItem('okta-token-storage')?.split('"idToken":"')[1]?.split('"')[0];

export const getUserLocaleFromLocalStorage = () => localStorage.getItem('locale');

export const setUserLocaleToLocalStorage = (locale: string) => localStorage.setItem('locale', locale);
