import { z } from 'zod';
import { GetCustomerInfoResponseSchema } from '../customer-info/get-customer-info.ts';
import { ORDER_STATUS_SCHEMA } from './order-status.ts';

/**
 * validation schema for response payload
 */
export const PostOrderResponseSchema = z.object({
    id: z.string().uuid(),
    positionId: z.string().uuid().nullable(),
    humanReadableId: z.string().length(11).nullable(),
    createdBy: z.number().gt(0),
    createdAt: z.coerce.date(),
    status: ORDER_STATUS_SCHEMA,
    customerInfo: GetCustomerInfoResponseSchema.nullable(),
    marketCode: z.string(),
});
