import { z } from 'zod';
import { GetQuoteResponseSchema } from './get-quote.ts';

/**
 * validation schema for whole (merged) request payload
 */
export const PostQuoteCancelRequestSchema = z.strictObject({
    productId: z.string().uuid(),
    callbackUrl: z.string().url(),
});

export const PostQuoteCancelResponseSchema = GetQuoteResponseSchema;
