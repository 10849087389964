import { z } from 'zod';
import { NestedSelectedProductResponseSchema } from '../selected-product/NestedProductResponseEntity.ts';
import { PRODUCT_GROUP_TYPE_SCHEMA } from './product-group-type.ts';

export const GetProductGroupUrlSchema = z.strictObject({
    groupId: z.string().uuid(),
});

/**
 * validation schema for response payload
 */
export const GetProductGroupResponseSchema = z.strictObject({
    id: z.string(),
    index: z.number(),
    name: z.string().nullable(),
    orderId: z.string(),
    templateId: z.string().uuid(),
    createdAt: z.coerce.date(),
    updatedAt: z.coerce.date(),
    selectedProducts: NestedSelectedProductResponseSchema.array().optional(),
    type: PRODUCT_GROUP_TYPE_SCHEMA,
});

export type GetProductGroupResponse = z.infer<typeof GetProductGroupResponseSchema>;
