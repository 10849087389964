import z from 'zod';
import {
    ORDER_ORGANIZATION_ROLE_SCHEMA,
    ORDER_ORGANIZATION_TRANSACTIONAL_ROLE_SCHEMA,
} from '../finance-settings/role.ts';
import { SUPPORTED_UNITS_SCHEMA } from '../quote-unit/unit-types.ts';
import { QUOTE_STATUS_SCHEMA } from '../quote/quote-status.ts';
import { PRODUCT_CATEGORY_SCHEMA } from '../selected-product/product-category.ts';

export const TransactionSchema = z.object({
    productId: z.string().uuid(),
    transactionalRole: ORDER_ORGANIZATION_TRANSACTIONAL_ROLE_SCHEMA,
    functionalRoles: z.array(ORDER_ORGANIZATION_ROLE_SCHEMA),
    quantity: z.number(),
    unit: SUPPORTED_UNITS_SCHEMA,
    unitPriceAmount: z.number(),
    unitTaxClassId: z.string().optional(),
    unitPriceCurrency: z.string().length(3),
    productName: z.string(),
    productCategory: PRODUCT_CATEGORY_SCHEMA,
});

export type Transaction = z.infer<typeof TransactionSchema>;

export const TransactionWithQuoteStatusSchema = z.intersection(
    TransactionSchema,
    z.object({
        quoteStatus: QUOTE_STATUS_SCHEMA,
    })
);

export type TransactionWithQuoteStatus = z.infer<typeof TransactionWithQuoteStatusSchema>;
