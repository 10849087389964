import React, { useEffect } from 'react';
import { patchProductQuote, postProductQuote } from '@/CustomRequestProductChannel/clients/CustomRequestClient';
import { Price } from '@schuettflix/conversion';
import { getOrderClient } from '@/clients/httpClients';
import {
    DebouncedCustomRequestProduct,
    DebouncedCustomRequestProductProps,
    SimpleDebouncedCustomRequestProductProps,
} from '../../components/DebouncedCustomRequestProduct';
import { useFormattedActiveTaxClassesByOrder } from '@/clients/tax-classes/useTaxClasses';
import { usePlatformOrganizationId } from '@/shared/hooks/usePlatformOrganizationId';
import { useMarketAwareOrder } from '@/clients/order/useOrder';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useProductQuote } from '@/CustomRequestProductChannel/hooks/useProductQuote';
import { usePositionCustomRequestProduct } from '@/CustomRequestProductChannel/hooks/usePositionCustomRequestProduct.ts';
import { constructProductQuote } from '@/CustomRequestProductChannel/utils/constructProductQuote.ts';
import { getOrderSummaryQueryKey } from '@/clients/order/useOrderSummary.ts';

export type OrderCustomRequestProductProps = SimpleDebouncedCustomRequestProductProps & {
    orderId: string;
};

export const OrderCustomRequestProduct: React.FC<OrderCustomRequestProductProps> = ({ orderId, ...props }) => {
    const queryClient = useQueryClient();
    const taxInfo = useFormattedActiveTaxClassesByOrder(orderId);
    const platformOrganizationId = usePlatformOrganizationId();

    const { data: order } = useMarketAwareOrder(orderId);
    const { data: quote, isLoading } = useProductQuote(props.product.id);
    const { data: positionQuote } = usePositionCustomRequestProduct(props.product.positionProductId);

    const orderingOrganizationId = order?.customerInfo?.organizationId;
    async function invalidateAfterProductChange() {
        return Promise.all([
            queryClient.invalidateQueries({ queryKey: ['selected-product-group', 'get', orderId] }),
            queryClient.invalidateQueries({ queryKey: ['custom-request', 'quote', props.product.id] }),
            queryClient.invalidateQueries({ queryKey: getOrderSummaryQueryKey(orderId) }),
        ]);
    }

    const { mutateAsync: mutateAsyncPostProduct, isPending: postProductIsPending } = useMutation({
        mutationKey: ['selected-product-group', 'product-quote'],
        mutationFn: postProductQuote,
        onSuccess: invalidateAfterProductChange,
    });

    const { mutateAsync: mutateAsyncPatchProduct, isPending: patchProductIsPending } = useMutation({
        mutationKey: ['selected-product-group', 'product-quote'],
        mutationFn: patchProductQuote,
        onSuccess: invalidateAfterProductChange,
    });

    const handleSubmit: DebouncedCustomRequestProductProps['onSubmit'] = async formValues => {
        if (quote?.meta.id && !patchProductIsPending) {
            await mutateAsyncPatchProduct({
                body: {
                    meta: {
                        orderingOrganizationId,
                        purchaseTaxClassId: formValues.purchaseTaxClassId,
                        salesTaxClassId: formValues.salesTaxClassId,
                        purchasePrice: new Price(formValues.purchasePrice, formValues.currencyCode).toNumber(),
                        amount: formValues.amount,
                        unit: formValues.unit,
                        partnerOrganizationId: formValues.partnerOrganizationId,
                        currencyCode: formValues.currencyCode,
                        salesPrice: new Price(formValues.salesPrice, formValues.currencyCode).toNumber(),
                        serviceDate: new Date(formValues.serviceDate),
                        callbackUrl: `${getOrderClient().defaults.baseURL}/v1/product-quote`,
                    },
                    payload: {
                        name: formValues.productName,
                    },
                },
                quoteId: quote.meta.id,
            });
            props.onCompleteChange(props.product.id, true);
        } else if (!postProductIsPending) {
            if (!platformOrganizationId || !orderingOrganizationId) return;
            try {
                await mutateAsyncPostProduct({
                    meta: {
                        orderId,
                        orderingOrganizationId,
                        selectedProductId: props.product.id,
                        selectedProductGroupId: props.product.selectedProductGroupId,
                        positionProductId: props.product.positionProductId,
                        purchaseTaxClassId: formValues.purchaseTaxClassId,
                        salesTaxClassId: formValues.salesTaxClassId,
                        purchasePrice: new Price(formValues.purchasePrice, formValues.currencyCode).toNumber(),
                        amount: formValues.amount,
                        unit: formValues.unit,
                        partnerOrganizationId: formValues.partnerOrganizationId,
                        currencyCode: formValues.currencyCode,
                        salesPrice: new Price(formValues.salesPrice, formValues.currencyCode).toNumber(),
                        serviceDate: new Date(formValues.serviceDate),
                        callbackUrl: `${getOrderClient().defaults.baseURL}/v1/product-quote`,
                        productCategory: props.product.category,
                        productType: props.product.type,
                        platformOrganizationId,
                    },
                    payload: {
                        name: formValues.productName,
                    },
                });
                props.onCompleteChange(props.product.id, true);
            } catch {
                props.onCompleteChange(props.product.id, false);
                return;
            }
        }
    };

    useEffect(() => {
        if (quote) {
            props.onCompleteChange(props.product.id, true);
        }
    }, [quote]);

    return order?.customerInfo && !isLoading ? (
        <DebouncedCustomRequestProduct
            {...props}
            disableUnit={!!order?.positionId}
            quote={constructProductQuote({ taxInfo, quote, positionQuote, includePositionQuota: false })}
            customerId={order.customerInfo.organizationId}
            taxInfo={taxInfo}
            onSubmit={handleSubmit}
        />
    ) : null;
};
