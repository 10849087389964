import React from 'react';
import { Tag } from '@schuettflix/react-components';
import { TagSectionProps } from '@/shared/components/TagSection.tsx';
import { cn } from '@/shared/utils/cn.ts';

type SummaryItemProps = {
    label: string;
    value: string;
    beforeValue?: string;
    type: TagSectionProps['type'];
    className?: string;
};

export const SummaryItem: React.FC<SummaryItemProps> = ({ label, value, type, beforeValue, className }) => (
    <div className={cn('flex flex-col gap-2 px-6', className)}>
        <p className="text-subdued font-copy-sm">{label}</p>
        {beforeValue && (
            <Tag
                size="md"
                label={beforeValue}
                subdued
                type={'default'}
                disabled
                className="mr-auto whitespace-nowrap font-bold"
            />
        )}
        <Tag
            label={value}
            subdued
            size="md"
            type={type}
            className={cn('mr-auto whitespace-nowrap font-bold', {
                '!text-critical': type === 'error',
                'text-success': type === 'positive',
            })}
        />
    </div>
);
