import { z } from 'zod';
import { PRODUCT_CATEGORY_SCHEMA } from '../selected-product/product-category.ts';

export const PostOrgPaymentTermRequestSchema = z.object({
    orderId: z.string().uuid(),
    productId: z.string().uuid(),
    productCategory: PRODUCT_CATEGORY_SCHEMA,
    orderingOrganizationId: z.number(),
    partnerOrganizationId: z.number(),
    customerOrganizationId: z.number(),
});
