import * as Sentry from '@sentry/react';
import type { FilterOptionsOrganization, FilterResultOrganization, Organization } from './types/organization';
import { LoggedInUser, LoggedInUserSchema } from '@/clients/organization/types/loggedInUser';
import { getMonolithClient } from '../httpClients';

export * from './types/organization';

export async function filter(filterOptions: FilterOptionsOrganization) {
    return getMonolithClient()
        .get<FilterResultOrganization>('/v3/organization', { params: filterOptions })
        .then(res => res.data);
}

export async function getOrganizationById(id: number) {
    return getMonolithClient()
        .get<Organization>(`/v3/organization/${id}`)
        .then(res => res.data);
}

export async function getLoggedInUser() {
    try {
        return getMonolithClient()
            .get<LoggedInUser>('/v2/me')
            .then(res => LoggedInUserSchema.parse(res.data));
    } catch (error) {
        Sentry.captureException(error);
    }
}

export async function decorateOrganizationNamesByOrganizationIds<Organization extends { orgId: number }>(
    organizations: Organization[]
): Promise<{
    organizations: (Organization & { orgName: string })[];
}> {
    return {
        organizations: await Promise.all(
            organizations.map(async org => {
                const orgDetails = await getOrganizationById(org.orgId);
                return {
                    ...org,
                    orgName: orgDetails.name,
                };
            })
        ),
    };
}
