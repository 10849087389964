import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { getTaxClasses } from '@/clients/tax-classes/TaxClasses.ts';
import { useMarketOfOrder } from '@/clients/order/useOrder.ts';

export function useTaxClass(marketCode: string) {
    const query = useQuery({
        queryKey: ['tax-class', marketCode],
        queryFn: () => getTaxClasses(marketCode),
        refetchOnWindowFocus: false,
    });

    return query.data;
}

export function useActiveTaxClasses(marketCode: string) {
    return useQuery({
        queryKey: ['tax-class', marketCode],
        queryFn: () => getTaxClasses(marketCode),
        refetchOnWindowFocus: false,
        select: data => data.filter(taxClass => taxClass.isActive),
    });
}

export function useFormattedActiveTaxClassesByMarket(marketCode: string | undefined) {
    const query = useSuspenseQuery({
        queryKey: ['tax-class', marketCode],
        queryFn: () => (marketCode ? getTaxClasses(marketCode) : null),
        refetchOnWindowFocus: false,
        select: data => {
            if (!data) {
                return;
            }
            const options = data
                .filter(taxClass => taxClass.isActive)
                .map(taxClass => {
                    return {
                        value: taxClass.name,
                        label: taxClass.rate === 0 ? `${taxClass.name}` : `${taxClass.rate} %`,
                        description: `${taxClass.name} ${taxClass.description}`,
                    };
                });
            return {
                defaultTaxClass: data.find(taxClass => taxClass.isDefault)?.name,
                taxClassOptions: options,
            };
        },
    });
    return query.data;
}

export function useFormattedActiveTaxClassesByOrder(orderId: string) {
    const marketCode = useMarketOfOrder(orderId);
    return useFormattedActiveTaxClassesByMarket(marketCode);
}

export type FormattedActiveTaxClasses = ReturnType<typeof useFormattedActiveTaxClassesByMarket>;
