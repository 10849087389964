import { TagSectionProps } from '@/shared/components/TagSection.tsx';

export function getTagType(value: number | null | undefined, reverseFlag = false): TagSectionProps['type'] {
    if (value === null || value === undefined) return;

    const normalizedValue = reverseFlag ? -value : value;

    if (normalizedValue > 0) return 'positive';

    if (normalizedValue < 0) return 'error';

    return 'default';
}
