import z from 'zod';
import { GetOrderSummaryResponseSchema } from './get-order-summary.ts';
import { SUPPORTED_UNITS_SCHEMA } from '../quote-unit/unit-types.ts';

export const GetFulfillmentSummaryUrlSchema = z.strictObject({
    orderId: z.string().uuid(),
});

const ProductQuoteSchema = z.strictObject({
    productId: z.string().uuid(),
    quantity: z.number().optional(),
    unit: SUPPORTED_UNITS_SCHEMA.optional(),
    purchasePrice: z.number(),
    salesPrice: z.number(),
    productName: z.string(),
    orderingOrganizationId: z.number(),
});

export const GetFulfillmentSummaryRequestSchema = z.strictObject({
    products: z.array(ProductQuoteSchema),
});

export const GetFulfillmentSummaryResponseSchema = GetOrderSummaryResponseSchema;
