import { z } from 'zod';
import { ORDER_ORGANIZATION_ROLE_SCHEMA } from '../finance-settings/role';
import { TransactionSchema } from '../order-summary/transactions';
import { TransactionalTotalsSchema } from '../order-summary/totals';
import { PaymentSettingsWithTransactionalRoleResponseSchema } from '../finance-settings/payment-settings';

export const GetPositionOverviewUrlSchema = z.object({
    positionId: z.string().uuid(),
});

export const GetPositionOverviewResponseSchema = z.strictObject({
    organizations: z.array(
        z.strictObject({
            orgId: z.number(),
            functionalRoles: z.array(ORDER_ORGANIZATION_ROLE_SCHEMA),
            financeSettings: z.array(PaymentSettingsWithTransactionalRoleResponseSchema),
            totals: z.array(TransactionalTotalsSchema),
            transactions: z.array(TransactionSchema),
        })
    ),
});

export type GetPositionOverviewResponse = z.infer<typeof GetPositionOverviewResponseSchema>;
