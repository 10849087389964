import { z } from 'zod';

/**
 * Event payload for position quota change event
 * Changes are relative to the previous state
 * @example { positionProductId: '00000000-0000-0000-0000-000000000000', quota: 0, reservedQuantity: -5, fulfilledQuantity: 5 }
 */
export const PositionQuotaChangeEventSchema = z.strictObject({
    positionProductId: z.string().uuid(),
    quota: z.number(),
    reservedQuantity: z.number(),
    fulfilledQuantity: z.number(),
});

export type PositionQuotaChangeEventPayload = z.infer<typeof PositionQuotaChangeEventSchema>;
