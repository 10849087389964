import { z } from 'zod';
import { PRODUCT_GROUP_TYPE_SCHEMA } from '../product-group/product-group-type';
import { PRODUCT_CATEGORY_SCHEMA } from '../selected-product/product-category';
import { PRODUCT_TYPE_SCHEMA } from '../product-type/product-types';

export const PostPositionGroupUrlSchema = z.object({
    positionId: z.string().uuid(),
});

export const PostPositionProductsSchema = z.object({
    index: z.number().nonnegative(),
    category: PRODUCT_CATEGORY_SCHEMA,
    type: PRODUCT_TYPE_SCHEMA,
});

export const PostPositionGroupRequestSchema = z.object({
    index: z.number().nonnegative(),
    name: z.string(),
    templateId: z.string().uuid(),
    type: PRODUCT_GROUP_TYPE_SCHEMA,
    products: z.array(PostPositionProductsSchema).optional(),
});

export const PostPositionGroupResponseSchema = z.object({
    ...PostPositionGroupRequestSchema.shape,
    id: z.string().uuid(),
    positionId: z.string().uuid(),
    createdAt: z.coerce.date(),
    updatedAt: z.coerce.date().nullable(),
});
