import { useQueryErrorHandler } from '@/shared/hooks/useQueryErrorHandler.ts';
import { useMutation } from '@tanstack/react-query';
import { commitEditPosition } from '@/clients/position/position.ts';

export function useCommitEditPosition(positionId: string, temporaryId: string) {
    const { handleMutationErrors } = useQueryErrorHandler();

    return useMutation({
        ...handleMutationErrors,
        mutationKey: ['commit-edit-position', positionId],
        mutationFn: () => commitEditPosition(positionId, temporaryId),
        throwOnError: true,
    });
}
