import z from 'zod';
import { BILLING_TYPE_SCHEMA } from './billing-types.ts';
import { ORDER_ORGANIZATION_TRANSACTIONAL_ROLE_SCHEMA } from './role.ts';

export const PatchPaymentTermUrlSchema = z.strictObject({
    orderId: z.string().uuid(),
    organizationId: z.string().transform(Number),
});

/**
 * validation schema for request payload
 */
export const PatchPaymentTermRequestSchema = z.strictObject({
    billingMethod: BILLING_TYPE_SCHEMA,
    paymentTermId: z.number().nullable(),
    transactionalRole: ORDER_ORGANIZATION_TRANSACTIONAL_ROLE_SCHEMA,
});

/**
 * validation schema for response payload
 */
export const PatchPaymentTermResponseSchema = z.object({
    orderId: z.string(),
    organizationId: z.number(),
    billingMethod: BILLING_TYPE_SCHEMA,
    paymentTermId: z.number().nullable(),
    transactionalRole: ORDER_ORGANIZATION_TRANSACTIONAL_ROLE_SCHEMA,
});
