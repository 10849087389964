import * as process from 'process';

export enum ENVIRONMENT_LONG_NAME {
    DEVELOPMENT = 'development',
    PRODUCTION = 'production',
    STAGE = 'stage',
    // Automatically set by the test runners
    // Test doesn't really exist as an environment, so we map it to development
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    TEST = 'development',
    DEMO = 'demo',
    NETSUITE = 'netsuite',
    TRAINING = 'training',
    SANDBOX = 'sandbox',
    LOCAL = 'local',
}

export enum CODE_PARTITION {
    DEV = 'dev',
    PROD = 'prod',
    STAGE = 'stage',
    DEMO = 'demo',
    NETSUITE = 'netsuite',
    TRAINING = 'training',
    SANDBOX = 'sandbox',
    LOCAL = 'local',
}

// Maps the environment name to the cloud environment that it is hosted in.
// For example, the netsuite environment is hosted in the stage cloud environment.
export const ENV_NAME_TO_PARTITION_MAP: {
    [key in keyof typeof ENVIRONMENT_LONG_NAME]: CODE_PARTITION;
} = {
    DEVELOPMENT: CODE_PARTITION.DEV,
    PRODUCTION: CODE_PARTITION.PROD,
    STAGE: CODE_PARTITION.STAGE,
    // Some environments map to other environments
    TEST: CODE_PARTITION.DEV,
    DEMO: CODE_PARTITION.DEMO,
    NETSUITE: CODE_PARTITION.NETSUITE,
    TRAINING: CODE_PARTITION.TRAINING,
    SANDBOX: CODE_PARTITION.SANDBOX,
    LOCAL: CODE_PARTITION.LOCAL,
};

// TODO: remove this - the default case is not guaranteed to work ¯\_(ツ)_/¯
/*
  Sometimes the development environment is called dev, sometimes development.
  Same for prod and production
  This function normalizes the environment name to the long name
*/
function toLongName(unsanitizedEnvName: string): ENVIRONMENT_LONG_NAME {
    switch (unsanitizedEnvName) {
        case 'prod':
            return ENVIRONMENT_LONG_NAME.PRODUCTION;
        case 'dev':
            return ENVIRONMENT_LONG_NAME.DEVELOPMENT;
        case 'local':
            return ENVIRONMENT_LONG_NAME.LOCAL;
        default:
            return unsanitizedEnvName as ENVIRONMENT_LONG_NAME;
    }
}

export function getUrls(sflxEnv?: ENVIRONMENT_LONG_NAME, branchName?: string) {
    function getEnvironmentAbbreviation(envName: ENVIRONMENT_LONG_NAME): CODE_PARTITION {
        const resolvedShortName =
            ENV_NAME_TO_PARTITION_MAP[envName.toUpperCase() as keyof typeof ENVIRONMENT_LONG_NAME];

        if (!resolvedShortName) {
            console.error(`Unknown environment name: ${envName}`);
        }

        return resolvedShortName;
    }

    function resolveURLEnv(envName: ENVIRONMENT_LONG_NAME): string {
        const prNumber = process?.env?.['PR_NUMBER'];

        if (prNumber && prNumber != 'not-preview') {
            return `${prNumber}`;
        }

        const ignoreBranchList = ['master', 'main', 'develop', 'dev', 'stage', 'prod'];

        /*
  In the following order, we read the branch name from:
    1. The branchName parameter
    2. The BRANCH_NAME_ENV environment variable in case it is actively set by the user
    3. The GITHUB_HEAD_REF environment variable in case it is set by the GitHub Actions runner
 */
        const resolvedBranchName =
            branchName || process?.env?.['BRANCH_NAME_ENV'] || process?.env?.['GITHUB_HEAD_REF'] || '';

        if (resolvedBranchName && !ignoreBranchList.includes(resolvedBranchName)) {
            return `${resolvedBranchName}`;
        }

        return getEnvironmentAbbreviation(envName);
    }

    const SFLX_ENV: ENVIRONMENT_LONG_NAME = sflxEnv || toLongName(process?.env?.['SFLX_ENV'] ?? 'local');
    const CODE_PARTITION_ENV = getEnvironmentAbbreviation(SFLX_ENV);
    // const URL_ENV = process?.env?.['PR_NUMBER'] !== undefined ? process?.env?.['PR_NUMBER'] : resolveURLEnv(SFLX_ENV);
    const URL_ENV = resolveURLEnv(SFLX_ENV);

    // @todo Resolve the base URL also for other environments like demo, Netsuite, etc.
    const BASE_URL =
        CODE_PARTITION_ENV != CODE_PARTITION.LOCAL
            ? `https://apigateway.${CODE_PARTITION_ENV}.schuett.tech`
            : 'http://localhost:4000';

    const ORDER_SERVICE_NAME = 'order-service';
    const POSITION_SERVICE_NAME = 'position-service';
    const FULFILLMENT_SERVICE_NAME = 'order-fulfillment-service';
    const CUSTOM_REQUEST_SERVICE_NAME = 'custom-request-service';
    const MATERIAL_CHANNEL_SERVICE_NAME = 'material-channel-service';

    const ORDER_SERVICE_URL = `${BASE_URL}/${ORDER_SERVICE_NAME}/${URL_ENV}`;
    const POSITION_SERVICE_URL = `${BASE_URL}/${POSITION_SERVICE_NAME}/${URL_ENV}`;
    const FULFILLMENT_SERVICE_URL = `${BASE_URL}/${FULFILLMENT_SERVICE_NAME}/${URL_ENV}`;
    const CUSTOM_REQUEST_SERVICE_URL = `${BASE_URL}/${CUSTOM_REQUEST_SERVICE_NAME}/${URL_ENV}`;
    const MATERIAL_CHANNEL_SERVICE_URL = `${BASE_URL}/${MATERIAL_CHANNEL_SERVICE_NAME}/${URL_ENV}`;

    function resolveMonolithServiceURL(): string {
        // const devFallback =  'https://apigateway.dev.schuett.tech/monolith/master'
        const stageFallback = `${BASE_URL}/monolith`;

        // const fallback = CODE_PARTITION_ENV === CODE_PARTITION.DEV ? devFallback : stageFallback

        // It is very hard to use the dev environment for the monolith service, there is no data, so we always use the stage environment
        // const fallback = stageFallback

        return process?.env?.['MONOLITH_BACKEND'] || stageFallback;
    }

    const MONOLITH_SERVICE_URL = resolveMonolithServiceURL();

    const CONSTRUCTION_PROJECT_SERVICE_URL = `${BASE_URL}/construction-project-public-api-service`;

    return {
        SFLX_ENV,
        CODE_PARTITION_ENV,
        URL_ENV,
        BASE_URL,
        ORDER_SERVICE_NAME,
        FULFILLMENT_SERVICE_NAME,
        CUSTOM_REQUEST_SERVICE_NAME,
        ORDER_SERVICE_URL,
        POSITION_SERVICE_URL,
        FULFILLMENT_SERVICE_URL,
        CUSTOM_REQUEST_SERVICE_URL,
        MATERIAL_CHANNEL_SERVICE_URL,
        MONOLITH_SERVICE_URL,
        CONSTRUCTION_PROJECT_SERVICE_URL,
    };
}

export type Config = ReturnType<typeof getUrls>;

export const config = getUrls();
