import { TemplateGroup, TemplateGroupSection } from '@schuettflix/interfaces';

export function groupTemplateGroupsBySection(
    templateGroups: TemplateGroup[]
): Record<TemplateGroupSection, TemplateGroup[]> {
    const groupTemplates: Record<TemplateGroupSection, TemplateGroup[]> = {
        MATERIAL: [],
        TRANSPORT: [],
        TRANSPORT_MATERIAL: [],
        SERVICE: [],
    };

    templateGroups.forEach(templateGroup => {
        if (templateGroup.sections.includes('TRANSPORT_MATERIAL')) {
            groupTemplates['TRANSPORT_MATERIAL'].push(templateGroup);
        }

        if (templateGroup.sections.includes('MATERIAL')) {
            groupTemplates['MATERIAL'].push(templateGroup);
        }

        if (templateGroup.sections.includes('TRANSPORT')) {
            groupTemplates['TRANSPORT'].push(templateGroup);
        }

        if (templateGroup.sections.includes('SERVICE')) {
            groupTemplates['SERVICE'].push(templateGroup);
        }
    });

    return groupTemplates;
}
