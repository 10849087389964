import { useParams } from 'react-router-dom';

export function useEditPositionState() {
    const { positionId, temporaryId } = useParams<{ positionId: string; temporaryId: string }>();

    return {
        positionId,
        temporaryId,
        isEditing: temporaryId !== undefined,
    };
}
