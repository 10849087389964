import { SummaryOrganization } from '@schuettflix/interfaces';

export function getOrganizationTotal(organization: SummaryOrganization, context: 'ORDER' | 'FULFILLMENT' | 'POSITION') {
    const ctx = context === 'POSITION' ? 'ORDER' : context;
    return (
        organization.totals
            .find(({ totalType }) => totalType === ctx)
            ?.transactionalTotals.reduce(
                (acc, { totalPriceAmount, transactionalRole }) =>
                    transactionalRole === 'DEBTOR' ? acc + totalPriceAmount : acc - totalPriceAmount,
                0
            ) ?? 0
    );
}
