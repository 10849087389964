import React from 'react';
import { FinanceSettings } from '@/shared/components/FinanceSettings/FinanceSettings.tsx';
import { SectionPreview } from '@/shared/components/SectionPreview.tsx';
import { useTranslation } from 'react-i18next';
import { useBalanceSheet } from '@/shared/hooks/useBalanceSheet.ts';
import { SummaryItems } from '@/shared/components/SummaryItems.tsx';
import { PositionSection } from '@/modules/position/components/PositionWizard.tsx';
import { usePositionSummary } from '@/clients/position/usePositionSummary.ts';
import { useMarketAwarePosition } from '@/clients/position/useMarketAwarePosition.ts';
import { PositionSubmissionButton } from './components/PositionSubmissionButton.tsx';
import { SectionSummaryPreview } from '@/shared/components/SectionSummaryPreview.tsx';
import { POSITION_STATUSES } from '@schuettflix/interfaces';
import { useEditPositionState } from '@/modules/position/hooks/useEditPositionState.ts';
import { PositionSaveButton } from '@/modules/position/components/PositionSummary/components/PositionSaveButton.tsx';

export interface PositionSummaryProps extends PositionSection {
    positionId: string;
}

export const PositionSummary: React.FC<PositionSummaryProps> = ({
    isSectionOpen,
    positionId,
    onEditCurrentSection,
}) => {
    const { t } = useTranslation();
    const { data: position } = useMarketAwarePosition(positionId);
    const { data: positionSummary } = usePositionSummary(positionId, isSectionOpen);
    const balanceSheetMap = useBalanceSheet(positionSummary?.organizations?.flatMap(({ totals }) => totals) || []);
    const isSubmitted = position?.status === POSITION_STATUSES.OPEN;
    const { isEditing } = useEditPositionState();

    if (isSubmitted || !position) {
        return;
    }

    if (!positionSummary) {
        return (
            <SectionPreview
                description={t('position.sections.summary.description')}
                headline={`4. ${t('position.sections.summary.title')}`}
            />
        );
    }

    if (!isSectionOpen) {
        return (
            <SectionSummaryPreview
                title={`4. ${t('position.sections.summary.title')}`}
                onEdit={onEditCurrentSection}
                balanceSheet={balanceSheetMap.get('ORDER')}
            />
        );
    }

    return (
        <div className="mt-6 flex w-full max-w-[720px] grow flex-col rounded border-t">
            <h2 className="font-headline-lg mb-[40px] mt-[40px]">{`4. ${t('position.sections.summary.title')}`}</h2>
            <div className="mb-6 flex flex-col gap-6">
                <FinanceSettings
                    referenceId={positionId}
                    groups={positionSummary?.organizations}
                    showErrorMessages={false}
                    context="POSITION"
                />
            </div>
            <SummaryItems balanceSheetMap={balanceSheetMap} />

            <div className="border-t-divider mt-[80px] w-full max-w-[720px] border-t shadow-inner">&nbsp;</div>

            <div className="flex justify-end px-4">
                {isEditing ? (
                    <PositionSaveButton />
                ) : (
                    <PositionSubmissionButton
                        positionId={positionId}
                        constructionProjectId={position.customerInfo?.constructionProjectId}
                    />
                )}
            </div>
        </div>
    );
};
