import { z } from 'zod';

export type LoggedInUser = z.infer<typeof LoggedInUserSchema>;

export type User = z.infer<typeof UserSchema>;

export type Organization = z.infer<typeof OrganizationSchema>;

const UserSchema = z.object({
    id: z.number(),
    created: z.number(),
    permissions: z.array(z.string()),
    locale: z.string(),
});

const OrganizationSchema = z.object({
    id: z.number(),
    name: z.string(),
    market: z.object({
        code: z.string(),
    }),
    paymentInfo: z.object({
        method: z.string(),
    }),
    billingAddress: z.object({
        zip: z.string(),
    }),
});

export const LoggedInUserSchema = z.object({
    user: UserSchema,
    organization: OrganizationSchema,
});
