import z from 'zod';
import { ORDER_ORGANIZATION_TRANSACTIONAL_ROLE_SCHEMA } from '../finance-settings/role.ts';
import { CURRENCY_SCHEMA } from '../currency/currency.ts';

export const TOTAL_TYPE_SCHEMA = z.enum(['ORDER', 'FULFILLMENT']);

export type TOTAL_TYPE = z.infer<typeof TOTAL_TYPE_SCHEMA>;

export const TotalSchema = z.strictObject({
    transactionalRole: ORDER_ORGANIZATION_TRANSACTIONAL_ROLE_SCHEMA,
    totalPriceAmount: z.number(),
    totalPriceCurrency: CURRENCY_SCHEMA,
});

export type Total = z.infer<typeof TotalSchema>;

export const TransactionalTotalsSchema = z.strictObject({
    totalType: TOTAL_TYPE_SCHEMA,
    transactionalTotals: z.array(TotalSchema),
});

export type TransactionalTotals = z.infer<typeof TransactionalTotalsSchema>;
