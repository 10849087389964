import React from 'react';
import { DateRange } from '@schuettflix/react-components';
import { SectionSummary } from '@/shared/components/SectionSummary.tsx';
import { useTranslation } from 'react-i18next';

export interface PositionSettingsSectionSummaryProps {
    onEditClick: () => void;
    validFrom: Date;
    validTo: Date;
    active: boolean;
    note?: string | null;
}

export const PositionSettingsSectionSummary: React.FC<PositionSettingsSectionSummaryProps> = ({
    validFrom,
    validTo,
    active,
    note,
    onEditClick,
}) => {
    const { t } = useTranslation();

    return (
        <SectionSummary
            title={`3. ${t('position.sections.positionSettings.title')}`}
            editable
            onEditClick={onEditClick}
        >
            <div className="text font-copy-md mt-6 flex items-center gap-6">
                <DateRange dateFrom={validFrom} dateTo={validTo} />
                <span>{active ? t(`position.settings.state.active`) : t(`position.settings.state.inactive`)}</span>
            </div>
            <div className="font-copy-sm text-subdued mt-1">{note}</div>
        </SectionSummary>
    );
};
