import { PostCustomerInfoRequestSchema, PatchCustomerInfoRequestSchema } from '@schuettflix/interfaces';
import { useMutation } from '@tanstack/react-query';
import { updateOrderWithClientInfo, updateOrderWithUpdatedClientInfo } from './order';
import { useQueryClient } from '@tanstack/react-query';
import { z } from 'zod';
import { useQueryErrorHandler } from '@/shared/hooks/useQueryErrorHandler.ts';

export const useUpdateOrder = (orderId: string) => {
    const { handleMutationErrors } = useQueryErrorHandler();
    if (!orderId) {
        throw new Error('Order ID is required for updating an order.');
    }

    const queryClient = useQueryClient();

    return {
        updateOrderWithClientInfo: useMutation({
            ...handleMutationErrors,
            mutationFn: (payload: z.input<typeof PostCustomerInfoRequestSchema>) =>
                updateOrderWithClientInfo(payload, orderId),
            onSuccess: () => queryClient.invalidateQueries({ queryKey: ['order', orderId] }),
        }),
        updateOrderWithUpdatedClientInfo: useMutation({
            ...handleMutationErrors,
            mutationFn: (payload: z.input<typeof PatchCustomerInfoRequestSchema>) =>
                updateOrderWithUpdatedClientInfo(payload, orderId),
            onSuccess: () => queryClient.invalidateQueries({ queryKey: ['order', orderId] }),
        }),
    };
};
