import {
    GetQuoteResponseSchema,
    PatchQuoteFulfillmentRequestSchema,
    PatchQuoteRequestSchema,
    PatchQuoteResponseSchema,
    PostQuoteFulfillmentRequestSchema,
    PostQuoteRequestSchema,
    PostQuoteResponseSchema,
} from '@schuettflix/interfaces';
import { getCustomRequestClient } from '@/clients/httpClients.ts';
import { z } from 'zod';

export const getProductQuoteByProductId = async ({ productId }: { productId: string }) => {
    return getCustomRequestClient()
        .get(`/v1/products/${productId}/quote`)
        .then(res => GetQuoteResponseSchema.parse(res.data));
};

export const postProductQuote = async (payload: z.input<typeof PostQuoteRequestSchema>) => {
    await getCustomRequestClient()
        .post('/v1/quote', payload)
        .then(res => PostQuoteResponseSchema.parse(res.data));
};

export const patchProductQuote = async ({
    body,
    quoteId,
}: {
    body: z.input<typeof PatchQuoteRequestSchema>;
    quoteId: string;
}) => {
    await getCustomRequestClient()
        .patch(`/v1/quote/${quoteId}`, body)
        .then(res => PatchQuoteResponseSchema.parse(res.data));
};

export const postFulfillmentQuote = async (payload: z.input<typeof PostQuoteFulfillmentRequestSchema>) => {
    await getCustomRequestClient()
        .post('/v1/order-fulfill/product', payload)
        .then(res => PostQuoteResponseSchema.parse(res.data));
};

export const patchFulfillmentQuote = async ({
    body,
    productId,
}: {
    body: z.input<typeof PatchQuoteFulfillmentRequestSchema>;
    productId: string;
}) => {
    await getCustomRequestClient()
        .patch(`/v1/order-fulfill/product/${productId}`, body)
        .then(res => PatchQuoteResponseSchema.parse(res.data));
};

export const deleteFulfillmentQuote = async ({ quoteId }: { quoteId: string }) => {
    await getCustomRequestClient().delete(`/v1/order-fulfill/quote/${quoteId}`);
};
