import { useQueryErrorHandler } from '@/shared/hooks/useQueryErrorHandler.ts';
import { useMutation } from '@tanstack/react-query';
import { startEditPosition } from '@/clients/position/position.ts';

export function useStartEditPosition(
    positionId: string,
    onSuccessHandler: (args: { positionId: string; temporaryId: string }) => void
) {
    const { handleMutationErrors } = useQueryErrorHandler();

    return useMutation({
        ...handleMutationErrors,
        mutationKey: ['start-edit-position', positionId],
        mutationFn: () => startEditPosition(positionId),
        onSuccess(data) {
            onSuccessHandler(data);
        },
        throwOnError: true,
    });
}
