import { createContext } from 'react';
import { OrderWizardProps } from '@/modules/order/components/OrderWizard/OrderWizard.tsx';

export interface AppContextProps {
    onClose: () => void;
    onPushMessage: OrderWizardProps['onPushMessage'];
}

export const AppContext = createContext<AppContextProps>({
    onClose: () => {},
    onPushMessage: () => {},
});
