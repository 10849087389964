import React, { ReactNode, Suspense } from 'react';
import { LoadingSpinner } from '@schuettflix/react-components';

export const PositionSectionWrapperSuspenseBoundary: React.FC<{ children: ReactNode }> = ({ children }) => {
    return (
        <Suspense
            fallback={
                <div className="flex justify-center py-4">
                    <LoadingSpinner />
                </div>
            }
        >
            {children}
        </Suspense>
    );
};
