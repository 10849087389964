import { z } from 'zod';
import { NestedSelectedProductResponseSchema } from '../selected-product/NestedProductResponseEntity.ts';
import { PRODUCT_GROUP_TYPE_SCHEMA } from './product-group-type.ts';

export const PostProductGroupRequestSchema = z.strictObject({
    orderId: z.string().uuid(),
    index: z.number(),
    name: z.string().optional(),
    templateId: z.string().uuid(),
    type: PRODUCT_GROUP_TYPE_SCHEMA,
});

export const PostProductGroupResponseSchema = z.strictObject({
    id: z.string(),
    index: z.number(),
    name: z.string().nullable(),
    orderId: z.string(),
    templateId: z.string().uuid(),
    createdAt: z.coerce.date(),
    updatedAt: z.coerce.date(),
    selectedProducts: NestedSelectedProductResponseSchema.array(),
    type: PRODUCT_GROUP_TYPE_SCHEMA,
});
