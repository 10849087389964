import { marketFromLocalStorage } from '@/shared/utils/localStorage.ts';
import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';

let _marketCode: string | undefined;

function setMarketCode(marketCode?: string) {
    _marketCode = marketCode;
}
export function getMarketCode() {
    return _marketCode ?? marketFromLocalStorage() ?? 'DE';
}

export const useMarketCode = (marketCode?: string) => {
    const queryClient = useQueryClient();

    useEffect(() => {
        setMarketCode(marketCode);
        const invalidateQueries = async () => {
            await queryClient.invalidateQueries();
        };
        void invalidateQueries();

        return () => {
            setMarketCode(undefined);
        };
    }, [marketCode, queryClient]);

    return getMarketCode();
};
