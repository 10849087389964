import { usePlatformInfo } from '@/clients/platformInfo/usePlatformInfo.ts';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { SUPPORTED_UNIT_LABEL, SUPPORTED_UNITS } from '@schuettflix/interfaces';

export const useFormatters = () => {
    const { t, i18n } = useTranslation();
    const { data: platformInfo } = usePlatformInfo();
    const platformCurrencyCode = platformInfo?.platform.market.currencyCode;
    const userLocale = i18n.language;

    const formatNumberWithCurrency = useCallback(
        (value: number, currencyCode?: string) => {
            if (!platformCurrencyCode && !currencyCode) {
                return value.toString();
            }

            return new Intl.NumberFormat(userLocale, {
                style: 'currency',
                currency: currencyCode || platformCurrencyCode,
            }).format(value);
        },
        [platformCurrencyCode, userLocale]
    );

    const formatNumberWithCurrencyPerUnit = useCallback(
        (value: number, unit: SUPPORTED_UNITS, currencyCode?: string) => {
            if (!platformCurrencyCode && !currencyCode) {
                return value.toString();
            }
            return `${formatNumberWithCurrency(value, currencyCode)} / ${t(SUPPORTED_UNIT_LABEL[unit])}`;
        },
        [platformCurrencyCode, t, formatNumberWithCurrency]
    );

    const formatDate = useCallback(
        (date: Date) => {
            return new Intl.DateTimeFormat(userLocale).format(date);
        },
        [userLocale]
    );

    const formatNumber = useCallback(
        (value: number, label?: string) => {
            const formattedNumber = new Intl.NumberFormat(userLocale, {
                style: 'decimal',
            }).format(value);

            return label ? `${formattedNumber} / ${label}` : formattedNumber;
        },
        [userLocale]
    );

    const formatPercentage = useCallback(
        (value: number): string => {
            return new Intl.NumberFormat(userLocale, {
                style: 'percent',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).format(value / 100);
        },
        [userLocale]
    );

    const getCurrencySymbol = useCallback(
        (currencyCode?: string) => {
            const formatter = new Intl.NumberFormat(userLocale, {
                style: 'currency',
                currency: currencyCode ?? platformCurrencyCode,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            });
            return formatter.format(0).replace(/\d/g, '').trim();
        },
        [userLocale, platformCurrencyCode]
    );

    return {
        formatNumberWithCurrency,
        formatNumberWithCurrencyPerUnit,
        formatDate,
        formatNumber,
        formatPercentage,
        getCurrencySymbol,
        currencyCode: platformCurrencyCode,
    };
};
