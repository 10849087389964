import React, { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { Outlet } from 'react-router-dom';
import { FullscreenLoading } from '@/shared/components/FullscreenLoading.tsx';

export const AuthGuard: React.FC = () => {
    const { oktaAuth, authState } = useOktaAuth();

    useEffect(() => {
        if (!authState) {
            return;
        }

        if (!authState?.isAuthenticated) {
            const originalUri = toRelativeUrl(window.location.href, window.location.origin);
            oktaAuth.setOriginalUri(originalUri);
            oktaAuth.signInWithRedirect().catch(error => {
                console.error('Error while redirecting to login', error);
            });
        }
    }, [oktaAuth, authState, authState?.isAuthenticated]);

    if (!authState || !authState?.isAuthenticated) {
        return <FullscreenLoading />;
    }

    return <Outlet />;
};
