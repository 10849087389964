import { z } from 'zod';
import { order } from '@schuettflix/schema-registry-ts';

export const PRODUCT_GROUP_TYPE_SCHEMA = order.orderService.versions.v1.schemas.PRODUCT_GROUP_TYPE_SCHEMA;
export type PRODUCT_GROUP_TYPE = z.infer<typeof PRODUCT_GROUP_TYPE_SCHEMA>;

export const PRODUCT_GROUP_TYPES = {
    FRANCO: PRODUCT_GROUP_TYPE_SCHEMA.enum.FRANCO,
    MERCHANT: PRODUCT_GROUP_TYPE_SCHEMA.enum.MERCHANT,
    STANDARD: PRODUCT_GROUP_TYPE_SCHEMA.enum.STANDARD,
} as const;
