import { z } from 'zod';
import { SUPPORTED_UNITS_SCHEMA } from '../quote-unit/unit-types.ts';
import { LINE_ITEM_TYPE_SCHEMA } from '../events/item-fulfillment-event-payload.ts';
import { QUOTE_STATUS_SCHEMA } from '../quote/quote-status.ts';
import { BILLING_INTERVAL_SCHEMA } from '../finance-settings/billing-interval.ts';
import { PRODUCT_TYPE_SCHEMA } from '../product-type/product-types.ts';
import { PRODUCT_CATEGORY_SCHEMA } from '../selected-product/product-category.ts';

export const GetOrderLineItemsUrlPathParametersSchema = z.strictObject({
    orderId: z.string().uuid(),
});

export const LineItemSchema = z.object({
    fulfillmentId: z.string(),
    productId: z.string().uuid(),
    productType: PRODUCT_TYPE_SCHEMA,
    productCategory: PRODUCT_CATEGORY_SCHEMA,
    dateOfService: z.coerce.date(),
    debtorOrganizationId: z.number(),
    creditorOrganizationId: z.number(),
    orderingOrganizationId: z.number(),
    sflxPlatformId: z.number(),
    taxClassId: z.string(),
    unit: SUPPORTED_UNITS_SCHEMA,
    quantity: z.number(),
    unitPriceAmount: z.number(),
    currencyCode: z.string().length(3),
    name: z.string(),
    description: z.string().optional(),
    projectPositionNumber: z.string().optional(),
    debtorCostCenter: z.string().optional(),
    creditorCostCenter: z.string().optional(),
    type: LINE_ITEM_TYPE_SCHEMA,
    billingInterval: BILLING_INTERVAL_SCHEMA,
    quoteStatus: QUOTE_STATUS_SCHEMA,
    supportingDocuments: z
        .array(
            z.object({
                description: z.string(),
                url: z.string().url(),
            })
        )
        .optional(),
});

export type LineItem = z.infer<typeof LineItemSchema>;

/**
 * validation schema for response payload
 */
export const GetLineItemListResponseSchema = LineItemSchema;
