import SimpleState from '@schuettflix/util-simple-state';

import css from '@/index.css?inline';

export type InstallProps = {
    hostAppVersion: string;
    userLocale: SimpleState<string>;
    accessToken: SimpleState<string>;
    baseUrl: {
        backend: SimpleState<string>;
    };
};

export const appendStyles = ({ target }: { target: ShadowRoot | HTMLElement }) => {
    const styleElement = document.createElement('style');
    styleElement.innerText = css;
    target.appendChild(styleElement);
    return () => {
        target.removeChild(styleElement);
    };
};

type Serializable = string | number | bigint | boolean | null | undefined;
export function groupBy<K extends keyof T, T extends Record<K, Serializable>>(data: T[], key: K) {
    return data.reduce<{ [P in `${T[K]}`]?: T[] }>((acc, item) => {
        const group: `${T[K]}` = `${item[key]}`;
        acc[group] = acc[group] ?? [];
        acc[group].push(item);
        return acc;
    }, {});
}
