import { z } from 'zod';
import { order } from '@schuettflix/schema-registry-ts';

export const ItemFulfillmentEventSchema = order.fulfillmentService.versions.v1.schemas.LINE_ITEM_FULFILLED;

export type ItemFulfillmentEventPayload = z.infer<typeof ItemFulfillmentEventSchema>;

export const LINE_ITEM_TYPE_SCHEMA = order.fulfillmentService.versions.v1.schemas.LINE_ITEM_TYPE_SCHEMA;
export type LINE_ITEM_TYPE = z.infer<typeof LINE_ITEM_TYPE_SCHEMA>;
export const KEY_VALUE_SCHEMA = order.fulfillmentService.versions.v1.schemas.KEY_VALUE_SCHEMA;
