import { z } from 'zod';
import { BILLING_TYPE_SCHEMA } from '../finance-settings/billing-types.ts';

export const GetFulfillmentPaymentTermUrlSchema = z.strictObject({
    orderId: z.string().uuid(),
});

export const GetFulfillmentPaymentTermSchema = z.strictObject({
    organizationId: z.number().int(),
    paymentTermId: z.number().int().nullable(),
    billingMethod: BILLING_TYPE_SCHEMA,
    productId: z.string().uuid(),
});

export type GetPaymentTerm = z.infer<typeof GetFulfillmentPaymentTermSchema>;

export const GetGroupedPaymentTermSchema = z.strictObject({
    organizationId: z.number().int(),
    paymentTermId: z.number().int().nullable(),
    billingMethod: BILLING_TYPE_SCHEMA,
    productId: z.array(z.string().uuid()),
});

export const GetGroupedPaymentTermListSchema = z.object({
    items: z.array(GetGroupedPaymentTermSchema),
    total: z.number(),
});

export type GetGroupedPaymentTermList = z.infer<typeof GetGroupedPaymentTermListSchema>;

export type GroupedPaymentTerm = z.infer<typeof GetGroupedPaymentTermSchema>;
