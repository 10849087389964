import { z } from 'zod';
import { order } from '@schuettflix/schema-registry-ts';

export const SUPPORTED_UNITS_SCHEMA = order.shared.versions.v1.schemas.SUPPORTED_UNITS_SCHEMA;

export type SUPPORTED_UNITS = z.infer<typeof SUPPORTED_UNITS_SCHEMA>;

export const SUPPORTED_UNIT_LABEL = {
    DAY: 'unitShortName.day',
    HOUR: 'unitShortName.hour',
    KILOMETER: 'unitShortName.kilometer',
    KILOGRAM: 'unitShortName.kilogram',
    TON: 'unitShortName.ton',
    CUBIC_METER: 'unitShortName.cubicMeter',
    CUBIC_CENTIMETER: 'unitShortName.cubicCentimeter',
    LITER: 'unitShortName.liter',
    PIECE: 'unitShortName.piece',
    TRANSPORT: 'unitShortName.transport',
    FLAT_RATE: 'unitShortName.flatRate',
    MINUTE: 'unitShortName.minute',
} as const satisfies Record<SUPPORTED_UNITS, string>;
