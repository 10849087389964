import { z } from 'zod';
import { AbstractPostQuoteRequestSchema } from '../quote/post-quote.ts';
import { GetQuoteResponseSchema } from './get-quote.ts';

const CustomRequestPostPayload = z.object({
    payload: z.object({
        // add custom fields of the fulfillment channel here
        name: z.string(),
    }),
});

/**
 * validation schema for whole (merged) request payload
 */
export const PostQuoteRequestSchema = AbstractPostQuoteRequestSchema.merge(CustomRequestPostPayload);

/**
 * validation schema for the response payload
 */
export const PostQuoteResponseSchema = GetQuoteResponseSchema;
