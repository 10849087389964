import { useQuery } from '@tanstack/react-query';
import { getSupportedUnitsByProductType } from '@/clients/selectedProducts/selectedProductsClient.ts';
import { PRODUCT_TYPES } from '@schuettflix/interfaces';

export function useSupportedUnitNamesByProductType(productType: PRODUCT_TYPES) {
    return useQuery({
        queryKey: ['supported-unit-by-product-type', productType],
        queryFn: () => getSupportedUnitsByProductType(productType),
        select: data => data.data.items.map(item => item.unitName),
    });
}
