import { z } from 'zod';

export const GetSupportingDocumentsListUrlSchema = z.object({
    orderId: z.string().uuid(),
});

export const SupportingDocumentsListSchema = z.object({
    id: z.string(),
    type: z.record(z.string(), z.string()).nullable(),
    uploadedAt: z.coerce.date().nullable(),
    fileName: z.string().nullable(),
});

export const GetSupportingDocumentsListResponseSchema = z.object({
    documents: z.array(SupportingDocumentsListSchema),
});

export type GetSupportingDocumentsListResponse = z.infer<typeof GetSupportingDocumentsListResponseSchema>;

export const GetSupportingDocumentUrlSchema = z.object({
    documentId: z.string().uuid(),
});

export const GetSupportingDocumentResponseSchema = z.object({
    url: z.string(),
    fileName: z.string().nullable(),
});

export type GetSupportingDocumentResponse = z.infer<typeof GetSupportingDocumentResponseSchema>;
