import { getMarketsFromToken } from '@/shared/utils/getMarketsFromToken.ts';
import { useNavigate } from 'react-router-dom';
import { getMarketCode } from '@/shared/hooks/useMarketCode.ts';
import { authTokenFromLocalStorage } from '@/shared/utils/localStorage.ts';
import { Select } from '@schuettflix/react-components';
import { useTranslation } from 'react-i18next';

export type MarketSelectorProps = {
    disabled?: boolean;
};

export const MarketSelector = ({ disabled = false }: MarketSelectorProps) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    let activePlatform = getMarketCode();
    const authToken = authTokenFromLocalStorage();

    if (!activePlatform) {
        const markets = getMarketsFromToken(authToken);
        localStorage.setItem('platform', markets[0]);
        activePlatform = markets[0];
    }

    const setPlatform = (platform: string) => {
        localStorage.setItem('platform', platform);
        navigate('/');
    };

    const handlePlatformChange = (changedPlatform: string | null) => {
        if (changedPlatform !== activePlatform && changedPlatform !== null) {
            setPlatform(changedPlatform);
        }
    };

    const availablePlatforms = getMarketsFromToken(authToken);

    if (availablePlatforms.length <= 1) {
        return null;
    }

    const platformOptions = availablePlatforms.map(platform => ({ value: platform, label: platform }));

    return (
        <div>
            <h3 className="font-copy-md-strong mb-2">{t('sections.platformSelection.description')}</h3>

            <Select
                value={activePlatform}
                onChange={handlePlatformChange}
                options={platformOptions}
                label={t('sections.platformSelection.platformLabel')}
                disabled={disabled}
            />
        </div>
    );
};
