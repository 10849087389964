import { z } from 'zod';
import { PRODUCT_TYPE_SCHEMA } from './product-types.ts';
import { SUPPORTED_UNITS_SCHEMA } from '../quote-unit/unit-types.ts';

export const NestedProductTypeResponseSchema = z.strictObject({
    name: PRODUCT_TYPE_SCHEMA,
    supportedUnits: z.array(
        z.strictObject({
            unitName: SUPPORTED_UNITS_SCHEMA,
        })
    ),
});
