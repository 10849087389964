import { z } from 'zod';
import { floatingPointPrecisionSchema, POSITION_PRODUCT_STATUS_SCHEMA } from './post-position-product';
import { PRODUCT_CATEGORY_SCHEMA } from '../selected-product/product-category';
import { PRODUCT_TYPE_SCHEMA } from '../product-type/product-types';
import { SUPPORTED_UNITS_SCHEMA } from '../quote-unit/unit-types.ts';

export const GetPositionProductsResponseSchema = z.strictObject({
    positionProductId: z.string().uuid(),
    positionId: z.string().uuid(),
    status: POSITION_PRODUCT_STATUS_SCHEMA,
    name: z.string(),
    quota: floatingPointPrecisionSchema,
    fulfilledQuantity: floatingPointPrecisionSchema,
    reservedQuantity: floatingPointPrecisionSchema,
    unit: SUPPORTED_UNITS_SCHEMA,
    partnerOrganizationId: z.number().positive(),
    projectOwnerOrganizationId: z.number().positive(),
    platformOrganizationId: z.number().positive(),
    purchasePrice: z.number(),
    purchaseTaxClassId: z.string(),
    salesPrice: z.number(),
    salesTaxClassId: z.string(),
    currencyCode: z.string().max(3),
    productCategory: PRODUCT_CATEGORY_SCHEMA,
    productType: PRODUCT_TYPE_SCHEMA,
    createdAt: z.coerce.date(),
    updatedAt: z.coerce.date(),
});

export const GetPositionProductsRequestSchema = z.object({
    positionIds: z.array(z.string().uuid()),
});

export const GetPositionProductsListResponseSchema = z.object({
    items: z.array(GetPositionProductsResponseSchema),
    total: z.number(),
});
