import { useEffect, useRef, useState } from 'react';
import { z } from 'zod';
import { PostOrderResponseSchema } from '@schuettflix/interfaces';
import { createOrder } from '@/clients/order/order.ts';
import { Navigate } from 'react-router-dom';
import { FullscreenLoading } from '@/shared/components/FullscreenLoading.tsx';

export function Main() {
    const [draftOrder, setDraftOrder] = useState<z.output<typeof PostOrderResponseSchema> | null>(null);

    const createNewOrder = async (): Promise<void> => {
        const response = await createOrder();
        setDraftOrder(response);
    };

    const startedCreatingOrder = useRef<boolean>(false);
    useEffect(() => {
        if (startedCreatingOrder.current) {
            return;
        }
        startedCreatingOrder.current = true;
        void createNewOrder();
    }, []);

    if (!draftOrder) {
        return <FullscreenLoading />;
    }

    return <Navigate to={`/order/${draftOrder.id}`} />;
}
