import type { PlatformInfo } from './types/platformTypes';
import { getMonolithClient } from '../httpClients';

export const getPlatformInfo = async () => {
    return getMonolithClient()
        .get<PlatformInfo>('/platforminfo')
        .then(res => res.data);
};

export * from './types/platformTypes';
