import { z } from 'zod';
import { PRODUCT_CATEGORY_SCHEMA } from '../selected-product/product-category.ts';
import { PRODUCT_TYPE_SCHEMA } from '../product-type/product-types.ts';
import { FULFILLMENT_STATUS_SCHEMA, CHANNEL_STATUS_SCHEMA } from './get-fulfillment-status.ts';

export const GetProductsUrlSchema = z.strictObject({
    orderId: z.string().uuid(),
});

export const GetProductsResponseSchema = z.object({
    id: z.string().uuid(),
    productGroupId: z.string().uuid(),
    productCategory: PRODUCT_CATEGORY_SCHEMA,
    productType: PRODUCT_TYPE_SCHEMA,
    status: FULFILLMENT_STATUS_SCHEMA,
    completedAt: z.coerce.date().nullable(),
    completedBy: z.number().nullable(),
    channelStatus: CHANNEL_STATUS_SCHEMA,
    index: z.number(),
});
