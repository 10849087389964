import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
    createProductSelectionApi,
    deleteProductSelectionApi,
} from '@/clients/selectedProducts/selectedProductsClient';
import { PRODUCT_CATEGORY, PRODUCT_TYPES } from '@schuettflix/interfaces';

export const useCreateSelectedProduct = () => {
    return {
        createSelectedProduct: useMutation({
            mutationKey: ['selected-product', 'select'],
            mutationFn: ({
                type,
                productGroupId,
                configurationId,
                index,
                templateId,
                category,
            }: {
                type: PRODUCT_TYPES;
                productGroupId: string;
                configurationId?: string;
                index?: number | undefined;
                templateId?: string;
                category: PRODUCT_CATEGORY;
            }) => {
                return createProductSelectionApi(type, category, productGroupId, configurationId, index, templateId);
            },
        }),
    };
};

export const useDeleteSelectedProduct = (orderId: string) => {
    const queryClient = useQueryClient();

    return {
        deleteSelectedProduct: useMutation({
            mutationKey: ['selected-product', 'delete-product'],
            mutationFn: ({ selectedProductId }: { selectedProductId: string }) => {
                return deleteProductSelectionApi(selectedProductId);
            },
            onSuccess: async () => {
                await queryClient.invalidateQueries({
                    queryKey: ['selected-product-group', 'get', orderId],
                });
            },
        }),
    };
};
