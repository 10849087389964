import { z } from 'zod';

export const CURRENCY_SCHEMA = z.enum(['EUR', 'PLN', 'CZK']);

export type CURRENCY = z.infer<typeof CURRENCY_SCHEMA>;

export const CURRENCY_DECIMALS: Record<CURRENCY, number> = {
    EUR: 2,
    PLN: 2,
    CZK: 2,
};

export function getCurrencyDecimals(currency: CURRENCY): number {
    return CURRENCY_DECIMALS[currency];
}
