import React, { useEffect } from 'react';
import { useFulfillmentContext } from '@/modules/fulfillment/context/FulfillmentContext.tsx';
import { z } from 'zod';
import { useZodForm } from '@/shared/hooks/useZodForm.ts';
import { Combobox, TextField } from '@schuettflix/react-components';
import { useOrganizationSuspenseQuery } from '@/clients/organization/useOrganization.ts';
import { Controller } from 'react-hook-form';
import { useConstructionProjectSuspenseQuery } from '@/clients/constructionProject/useConstructionProject.ts';
import { SelectUser } from '@/shared/components/SelectUser.tsx';
import { useTranslation } from 'react-i18next';
import { useSectionsValidity } from '@/modules/fulfillment/hooks/useSectionsValidity.ts';

interface ClientInformationFormFulfillmentProps {
    disabled?: boolean;
}

export const ClientInformationFormFulfillment: React.FC<ClientInformationFormFulfillmentProps> = ({ disabled }) => {
    const { order, setOrder, isSubmitted } = useFulfillmentContext();
    const { data: organization } = useOrganizationSuspenseQuery(order?.customerInfo?.organizationId);
    const { data: constructionProject } = useConstructionProjectSuspenseQuery(
        order?.customerInfo?.constructionProjectId
    );
    const { t } = useTranslation();

    const clientInformationSchema = z.object({
        userId: z.number({
            invalid_type_error: t('fulfillment.clientInformation.clientUserName.invalidType'),
        }),
        manualCostCenter: z.string().nullable(),
    });

    const {
        register,
        control,
        getValues,
        trigger: triggerFormValidation,
        formState: { errors, isValid },
    } = useZodForm({
        schema: clientInformationSchema,
        defaultValues: {
            manualCostCenter: order?.customerInfo?.manualCostCenter,
            userId: order?.customerInfo?.userId,
        },
        mode: 'onChange',
    });

    useEffect(() => {
        if (!isSubmitted) {
            return;
        }

        void triggerFormValidation();
    }, [isSubmitted]);

    useSectionsValidity('client-information', isValid);

    if (!constructionProject || !organization) {
        return;
    }

    const handleInputChange = () => {
        if (!order) return;

        const data = getValues();

        setOrder({
            ...order,
            customerInfo: {
                ...order.customerInfo!,
                ...data,
            },
        });
    };

    return (
        <>
            <form onChange={handleInputChange} className="grid gap-2">
                <Combobox
                    value={organization.id}
                    onChange={() => {}}
                    options={[
                        {
                            label: organization.name,
                            value: organization.id,
                        },
                    ]}
                    label={t('fulfillment.clientInformation.organization.label')}
                    disabled
                />

                <Combobox
                    value={constructionProject.id}
                    onChange={() => {}}
                    options={[
                        {
                            label: constructionProject.name,
                            value: constructionProject.id,
                        },
                    ]}
                    label={t('fulfillment.clientInformation.constructionProject.label')}
                    disabled
                />

                <Controller
                    name="userId"
                    control={control}
                    render={({ field: { onChange, ...field } }) => (
                        <SelectUser
                            {...field}
                            label={t('fulfillment.clientInformation.clientUserName.label')}
                            criticalized={!!errors['userId']}
                            helperText={errors['userId']?.message}
                            filters={{
                                isActive: true,
                                organization: organization.id,
                            }}
                            onChange={value => {
                                onChange(value?.id ?? null);
                                handleInputChange();
                            }}
                            disabled={disabled}
                        />
                    )}
                />
                <TextField
                    {...register('manualCostCenter')}
                    label={t('fulfillment.clientInformation.costCenter.label')}
                    name="manualCostCenter"
                    disabled={disabled}
                />
            </form>
        </>
    );
};
