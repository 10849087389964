import { useQueryErrorHandler } from '@/shared/hooks/useQueryErrorHandler.ts';
import { useMutation } from '@tanstack/react-query';
import { createPositionSubmit } from '@/clients/position/position.ts';

export function useSubmitPosition(positionId: string) {
    const { handleMutationErrors } = useQueryErrorHandler();

    return useMutation({
        ...handleMutationErrors,
        mutationFn: () => {
            return createPositionSubmit(positionId);
        },
        throwOnError: true,
    });
}
