import z from 'zod';
import { PROJECT_CODE_SCHEMA } from '../project/project.ts';

export const PatchCustomerInfoUrlSchema = z.strictObject({
    orderId: z.string().uuid(),
    customerInfoId: z.string().uuid(),
});

/**
 * validation schema for request payload
 */
export const PatchCustomerInfoRequestSchema = z.strictObject({
    id: z.string().uuid().optional(),
    organizationId: z.number().optional(),
    userId: z.number().optional(),
    manualCostCenter: z.string().nullable().optional(),
    constructionProjectId: z.string().uuid().optional(),
    projectCode: PROJECT_CODE_SCHEMA.optional(),
});

/**
 * validation schema for response payload
 */
export const PatchCustomerInfoResponseSchema = z.strictObject({
    id: z.string(),
    orderId: z.string().uuid(),
    organizationId: z.number(),
    userId: z.number(),
    manualCostCenter: z.string().nullable(),
    constructionProjectId: z.string(),
    projectCode: PROJECT_CODE_SCHEMA,
});
