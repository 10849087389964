export const calculateTakeRatePercentage = (revenue: number, cost: number) => {
    // if both are negative, we need to swap them as revenue is actually a cost and vice versa
    if (revenue < 0 && cost < 0) {
        const originalRevenue = revenue;
        revenue = Math.abs(cost);
        cost = Math.abs(originalRevenue);
    }

    // if revenue is negative, the revenue is actually a cost
    if (revenue < 0) {
        cost = cost + Math.abs(revenue);
        revenue = 0;
    }

    // if cost is negative, the cost is actually a revenue
    if (cost < 0) {
        revenue = revenue + Math.abs(cost);
        cost = 0;
    }

    if (cost === 0 && revenue === 0) {
        return 0;
    }

    // without any cost, the take rate is 100%
    if (cost === 0) {
        return 100;
    }

    // without any revenue, the take rate is -100%
    if (revenue === 0) {
        return -100;
    }

    return ((revenue - cost) / revenue) * 100;
};
