import { z } from 'zod';
import { PaymentSettingsResponseSchema } from './payment-settings.ts';
import { PRODUCT_CATEGORY_SCHEMA } from '../selected-product/product-category.ts';

export const GetPaymentSettingsRequestSchema = z.object({
    orders: z.array(
        z.strictObject({
            orderId: z.string().uuid(),
            organizationIds: z.array(z.number()),
        })
    ),
});

export const GetPaymentSettingsResponseSchema = z.strictObject({
    organizations: z.array(
        z.strictObject({
            orderId: z.string().uuid(),
            orgId: z.number(),
            financeSettings: z.array(PaymentSettingsResponseSchema),
        })
    ),
});

export type GetPaymentSettingsResponse = z.infer<typeof GetPaymentSettingsResponseSchema>;

const PaymentTermsPartialLineItemSchema = z.object({
    orderingOrganizationId: z.number(),
    creditorOrganizationId: z.number(),
    debtorOrganizationId: z.number(),
    sflxPlatformId: z.number(),
    productCategory: PRODUCT_CATEGORY_SCHEMA,
    productId: z.string().uuid(),
});

export const GetPaymentTermsRequestSchema = z.object({
    lineItems: z.array(PaymentTermsPartialLineItemSchema),
});
