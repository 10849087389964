import userflow from 'userflow.js';
import { User, Organization } from '@/clients/organization/types/loggedInUser.ts';

export const useUserflow = () => {
    if (window.userflowToken) {
        userflow.init(window.userflowToken);
        userflow.setResourceCenterLauncherHidden(true);
    } else if (import.meta.env.VITE_SFLX_ENVIRONMENT !== 'test') {
        console.warn(' No userflow token set.');
    }

    return {
        identify: async ({ id, created, permissions }: User, organization: Organization, userLocale: string) => {
            await userflow.identify(id.toString(), {
                locale_code: userLocale,
                name: id.toString(),
                userId: id.toString(),
                signed_up_at: created ? new Date(created * 1000).toISOString() : '',
                device_type: window.innerWidth > 1023 ? 'desktop' : 'mobile',
                ...Object.fromEntries(permissions.map(permission => [permission, true])),
                // We are setting this property to true only for those users having logged-in into GO.
                // Since this property is not being set at all in the Monolith, it would always stay true.
                // This way, it gives us the possibility to adapt the GO-related flows and notify users (accessing GO) about them, no matter if they are in the Monolith or GO.
                // Therefore, this property is needed strictly for the userflow.js library.
                generic_order: true,
            });

            await userflow.group(organization.id.toString(), {
                organizationId: organization.id.toString(),
                organizationName: organization.name,
                name: organization.id.toString(),
                market: organization.market.code,
                paymentMethod: organization.paymentInfo.method,
                organization_plz_region: organization.billingAddress.zip,
            });
        },
    };
};
