import { AppContext } from '@/shared/context/AppContext.ts';
import { OrderWizard } from '@/modules/order/components/OrderWizard/OrderWizard.tsx';
import { LoadingSpinner } from '@schuettflix/react-components';
import { Suspense, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DocumentTitle } from '@/shared/components/DocumentTitle.tsx';

export function OrderPage() {
    const { orderId } = useParams<{ orderId: string }>();
    const { onPushMessage } = useContext(AppContext);
    const navigate = useNavigate();
    const { t } = useTranslation();

    function navigateToMainPage() {
        navigate('/');
    }

    return (
        <>
            <DocumentTitle title={t('page.title')} />
            <Suspense
                fallback={
                    <div className="flex h-screen items-center justify-center">
                        <LoadingSpinner />
                    </div>
                }
            >
                {!!orderId && (
                    <OrderWizard onPushMessage={onPushMessage} orderId={orderId} onComplete={navigateToMainPage} />
                )}
            </Suspense>
        </>
    );
}
