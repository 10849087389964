import { parseDate } from '@internationalized/date';
import { format } from 'date-fns';

export function getDatePickerValue(startDate?: Date, endDate?: Date) {
    if (!startDate || !endDate) return;

    return {
        start: parseDate(format(startDate, 'yyyy-MM-dd')),
        end: parseDate(format(endDate, 'yyyy-MM-dd')),
    };
}
