import { useFormatters } from '@/shared/hooks/useFormatters/useFormatters.ts';
import { SummaryItem } from './SummaryItem.tsx';
import { useTranslation } from 'react-i18next';
import { getTagType } from '@/shared/utils/getTagType.ts';
import { BalanceSheetMap } from '@/shared/hooks/useBalanceSheet.ts';

type SummaryItemsProps = {
    balanceSheetMap: BalanceSheetMap;
};

export function SummaryItems({ balanceSheetMap }: SummaryItemsProps) {
    const { formatNumberWithCurrency, formatPercentage } = useFormatters();
    const { t } = useTranslation();

    const orderStats = balanceSheetMap.get('ORDER');
    const fulfillmentStats = balanceSheetMap.get('FULFILLMENT');
    if (!orderStats) {
        return null;
    }
    const allStats = fulfillmentStats || orderStats;

    return (
        <div className="border-t-active flex justify-end divide-x border-t px-2 py-6">
            <SummaryItem
                label={t('sections.summary.balancesheet.totalrevenue')}
                beforeValue={fulfillmentStats && formatNumberWithCurrency(orderStats.revenue)}
                value={formatNumberWithCurrency(allStats.revenue)}
                type={getTagType(allStats.revenue)}
                className="pl-0"
            />
            <SummaryItem
                label={t('sections.summary.balancesheet.totalcosts')}
                beforeValue={fulfillmentStats && formatNumberWithCurrency(orderStats.costs)}
                value={formatNumberWithCurrency(allStats.costs)}
                type={getTagType(allStats.costs, true)}
            />
            <SummaryItem
                label={t('sections.summary.balancesheet.totalprofit')}
                beforeValue={fulfillmentStats && formatNumberWithCurrency(orderStats.profit)}
                value={formatNumberWithCurrency(allStats.profit)}
                type={getTagType(allStats.profit)}
            />
            <SummaryItem
                label={t('sections.summary.balancesheet.takerate')}
                beforeValue={fulfillmentStats && formatPercentage(orderStats.takeRate)}
                value={formatPercentage(allStats.takeRate)}
                type={getTagType(allStats.takeRate)}
                className="pr-0"
            />
        </div>
    );
}
