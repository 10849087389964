import { PositionWizard } from '@/modules/position/components/PositionWizard';
import { LoadingSpinner } from '@schuettflix/react-components';
import { Suspense } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DocumentTitle } from '@/shared/components/DocumentTitle.tsx';

export function PositionPage() {
    const { positionId } = useParams<{ positionId: string }>();
    const { t } = useTranslation();

    return (
        <>
            <DocumentTitle title={t('positions.create-position')} />
            <Suspense
                fallback={
                    <div className="flex h-screen items-center justify-center">
                        <LoadingSpinner />
                    </div>
                }
            >
                {!!positionId && <PositionWizard positionId={positionId} />}
            </Suspense>
        </>
    );
}
