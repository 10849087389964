import { z } from 'zod';
import { BILLING_TYPE_SCHEMA } from '../finance-settings/billing-types.ts';

export const PatchFulfillmentPaymentTermUrlSchema = z.strictObject({
    orderId: z.string().uuid(),
    organizationId: z.string().transform(Number),
});

export const PatchFulfillmentPaymentTermRequestSchema = z.strictObject({
    paymentTermId: z.number().int().nullable().optional(),
    billingMethod: BILLING_TYPE_SCHEMA.optional(),
});
