import React from 'react';
import { PositionSectionWrapperSuspenseBoundary } from '@/modules/position/components/PositionSectionWrapperSuspenseBoundary.tsx';
import { PositionSection } from '@/modules/position/components/PositionWizard.tsx';
import { QueryErrorBoundary } from '@/shared/components/QueryErrorBoundary';
import { SectionErrorFallbackWithReset } from '@/shared/components/SectionErrorFallback';
import { cn } from '@/shared/utils/cn.ts';

export interface PositionSectionWrapperProps {
    className?: string;
    children: React.ReactElement<PositionSection>;
}

export const PositionSectionWrapper = ({ className, children }: PositionSectionWrapperProps) => {
    return (
        <PositionSectionWrapperSuspenseBoundary>
            <section className={cn('flex w-full max-w-[720px] flex-col items-center', className)}>
                <QueryErrorBoundary fallbackComponent={SectionErrorFallbackWithReset}>{children}</QueryErrorBoundary>
            </section>
        </PositionSectionWrapperSuspenseBoundary>
    );
};
