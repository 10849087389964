import { ForwardedRef, useImperativeHandle, useRef } from 'react';

export function useInternalRef<Element extends HTMLElement>(forwardedRef: ForwardedRef<Element>) {
    const ref = useRef<Element>(null);

    /**
     * We use an internal ref to support the uncontrolled component pattern.
     * However, when a ref is provided by the user, we have to customize it to
     * have access to the input element.
     */
    useImperativeHandle(forwardedRef, () => {
        return ref.current!;
    });

    return ref;
}
