import React from 'react';
import { HelpIcon } from '@schuettflix/icons-react';
import userflow from 'userflow.js';

export function ResourceCenter() {
    const openResourceCenter = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        userflow.toggleResourceCenter();
    };

    return (
        <div
            className="flex cursor-pointer items-center gap-2 hover:opacity-50"
            data-test="resource-center"
            onClick={openResourceCenter}
        >
            <HelpIcon size="sm" />
        </div>
    );
}
