import z from 'zod';
import { BILLING_TYPE_SCHEMA } from './billing-types.ts';
import { ORDER_ORGANIZATION_ROLE_SCHEMA, ORDER_ORGANIZATION_TRANSACTIONAL_ROLE_SCHEMA } from './role.ts';

export const GetPaymentTermUrlSchema = z.strictObject({
    orderId: z.string().uuid(),
    organizationId: z.string().transform(Number),
});

/**
 * validation schema for response payload
 */
export const GetPaymentTermResponseSchema = z.strictObject({
    paymentTerms: z.array(
        z.strictObject({
            orderId: z.string(),
            organizationId: z.number(),
            paymentTermId: z.number().nullable(),
            billingMethod: BILLING_TYPE_SCHEMA,
            transactionalRole: ORDER_ORGANIZATION_TRANSACTIONAL_ROLE_SCHEMA,
            functionalRoles: z.array(ORDER_ORGANIZATION_ROLE_SCHEMA),
        })
    ),
});

export type GetPaymentTermResponse = z.infer<typeof GetPaymentTermResponseSchema>;
