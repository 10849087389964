import { useSuspenseQuery } from '@tanstack/react-query';
import { getPositionSummaryById } from './position';

export const usePositionSummary = (positionId: string, enabled = true) => {
    return useSuspenseQuery({
        queryKey: ['position-summary', positionId, enabled],
        queryFn: () => (enabled ? getPositionSummaryById(positionId) : null),
        refetchOnWindowFocus: false,
    });
};
