import { Button } from '@schuettflix/planum-react';
import { useSubmitPosition } from '@/clients/position/useSubmitPosition.ts';
import { useMonolithFrontendUrl } from '@/shared/hooks/useMonolithFrontendUrl.ts';
import { useTranslation } from 'react-i18next';
import { encodeBase64Json } from '@/shared/utils/base64Json.ts';

interface PositionSubmissionButtonProps {
    positionId: string;
    constructionProjectId?: string;
}

export function PositionSubmissionButton({ positionId, constructionProjectId }: PositionSubmissionButtonProps) {
    const { t } = useTranslation();
    const submitPositionMutation = useSubmitPosition(positionId);

    const { openUrl } = useMonolithFrontendUrl({
        target: 'currentTab',
        historyMethod: 'push',
    });

    const handleClick = async () => {
        const response = await submitPositionMutation.mutateAsync();

        const projectPositionFilter = response?.active ? { status: 'open' } : { status: ['inactive', 'created'] };

        openUrl({
            path: `/order-management/projects/${constructionProjectId}/positions`,
            query: {
                projectPositionForced: encodeBase64Json(projectPositionFilter),
            },
            toast: {
                type: 'success',
                message: t('positions.successfully-created', {
                    positionNumber: response?.positionNumber,
                }),
            },
        });
    };

    return (
        <Button onPress={handleClick} isLoading={submitPositionMutation.isPending || submitPositionMutation.isSuccess}>
            {t('position.summary.createNewPosition')}
        </Button>
    );
}
