import { useMemo } from 'react';
import { calculateTakeRatePercentage } from '@/shared/utils/calculateTakeRatePercentage.ts';
import { TransactionalTotals } from '@schuettflix/interfaces';
import { Price } from '@schuettflix/conversion';

export type BalanceSheetMap = Map<
    TransactionalTotals['totalType'] | 'ALL',
    {
        revenue: number;
        costs: number;
        profit: number;
        takeRate: number;
    }
>;

function add(a: number, b: number, currency: ConstructorParameters<typeof Price>[1] | undefined = 'EUR'): number {
    return new Price(a, currency).add(new Price(b, currency)).toNumber();
}

export function useBalanceSheet(totals: TransactionalTotals[]): BalanceSheetMap {
    return useMemo(() => {
        const emptySheet = { revenue: 0, costs: 0, profit: 0, takeRate: 0 };
        return totals.reduce<BalanceSheetMap>((accumulator, { totalType, transactionalTotals }) => {
            const stats = accumulator.get(totalType) || { ...emptySheet };
            transactionalTotals.map(({ transactionalRole, totalPriceAmount }) => {
                stats.revenue = add(stats.revenue, transactionalRole === 'DEBTOR' ? totalPriceAmount : 0);
                stats.costs = add(stats.costs, transactionalRole === 'CREDITOR' ? totalPriceAmount : 0);
                stats.profit = new Price(stats.revenue, 'EUR').subtract(new Price(stats.costs, 'EUR')).toNumber();
                stats.takeRate = calculateTakeRatePercentage(stats.revenue, stats.costs);
            });

            accumulator.set(totalType, stats);
            return accumulator;
        }, new Map());
    }, [totals]);
}
