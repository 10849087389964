import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { getPlatformInfo } from './PlatformInfo';

export function usePlatformInfo() {
    return useQuery({
        queryKey: ['platformInfo'],
        queryFn: getPlatformInfo,
    });
}

export function usePlatformCurrencyCode() {
    return useSuspenseQuery({
        queryKey: ['platformInfo'],
        queryFn: getPlatformInfo,
        select: data => data?.platform.market.currencyCode,
    });
}
