import { z } from 'zod';

export const CustomRequestPostPositionStartEditUrlSchema = z.strictObject({
    positionId: z.string().uuid(),
    temporaryId: z.string().uuid(),
});

export const CustomRequestPostPositionStartEditRequestSchema = z.record(z.string(), z.string());

export type CustomRequestPostPositionStartEditRequest = z.infer<typeof CustomRequestPostPositionStartEditRequestSchema>;
