import { z } from 'zod';

export const ProjectNumberSchema = z.string().regex(/^PJ?\d{2}-\d{5}$/, {
    message: 'Project number must follow the format PJXX-XXXXX where X are digits',
});

export const ProjectPositionNumberSchema = z.string().regex(/^PJ?\d{2}-\d{5}-5\d{2}$/, {
    message: 'Project Position Number must follow the format PJ24-00100-5XX where XX are digits',
});

export const POSITION_STATUS_SCHEMA = z.enum(['DRAFT', 'OPEN', 'CLOSED']);
export type POSITION_STATUS = z.infer<typeof POSITION_STATUS_SCHEMA>;

export const POSITION_STATUSES = {
    DRAFT: POSITION_STATUS_SCHEMA.enum.DRAFT,
    OPEN: POSITION_STATUS_SCHEMA.enum.OPEN,
    CLOSED: POSITION_STATUS_SCHEMA.enum.CLOSED,
} as const;
