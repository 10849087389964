import { z } from 'zod';
import { PRODUCT_TYPE_SCHEMA } from '../product-type/product-types.ts';
import { PRODUCT_CATEGORY_SCHEMA } from '../selected-product/product-category.ts';

export const PostProductRequestSchema = z.object({
    selectedProductId: z.string().uuid(),
    productCategory: PRODUCT_CATEGORY_SCHEMA,
    productType: PRODUCT_TYPE_SCHEMA,
    productGroupId: z.string().uuid(),
    index: z.number(),
});
