import { PaymentTermUpdatePayload, updatePaymentTerms } from '@/clients/fulfillment/fulfillment.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useQueryErrorHandler } from '@/shared/hooks/useQueryErrorHandler.ts';

export const useFulfillmentPaymentTermsMutation = (orderId: string) => {
    const { handleMutationErrors } = useQueryErrorHandler();
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: ['payment-terms-fulfillment', orderId],
        ...handleMutationErrors,
        mutationFn: async (payload: PaymentTermUpdatePayload) => updatePaymentTerms(orderId, payload),
        onSettled: () => {
            void queryClient.invalidateQueries({ queryKey: ['payment-terms-fulfillment', orderId] });
        },
    });
};
