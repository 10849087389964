import { z } from 'zod';
import { AbstractPostQuoteRequestSchema } from '../quote/post-quote.ts';
import { GetQuoteResponseSchema } from './get-quote.ts';

const InternalCustomRequestPostPayload = z.object({
    meta: z
        .object({
            id: z.string(),
            createdAt: z.coerce.date(),
        })
        .merge(AbstractPostQuoteRequestSchema.shape.meta)
        .omit({ callbackUrl: true }),
    payload: z.object({
        // add custom fields of the fulfillment channel here
        name: z.string(),
    }),
});

/**
 * validation schema for whole (merged) request payload
 */
export const InternalPostQuoteRequestSchema = InternalCustomRequestPostPayload;

/**
 * validation schema for the response payload
 */
export const InternalPostQuoteResponseSchema = GetQuoteResponseSchema;
