import { z } from 'zod';
import { SUPPORTED_UNITS_SCHEMA } from '../quote-unit/unit-types.ts';

/**
 * abstract quote validation schema for patch request payload
 */
export const AbstractPatchQuoteRequestSchema = z.strictObject({
    meta: z.strictObject({
        // product information
        amount: z.number().optional(),
        unit: SUPPORTED_UNITS_SCHEMA.optional(),
        serviceDate: z.coerce.date().optional(),
        // payment information
        partnerOrganizationId: z.number().int().positive().optional(),
        orderingOrganizationId: z.number().int().positive().optional(),
        platformOrganizationId: z.number().int().positive().optional(),
        purchasePrice: z.number().optional(),
        purchaseTaxClassId: z.string().optional(),
        salesPrice: z.number().optional(),
        salesTaxClassId: z.string().optional(),
        currencyCode: z.string().optional(),
        // callback information
        callbackUrl: z.string().url(),
    }),
    // will be overwritten by custom fulfillment channel payload
    payload: z.any(),
});
