import { LaptopIcon, MaterialIcon, VehicleMaterialIcon } from '@schuettflix/icons-react';
import { PrefillTemplate } from '@/shared/types/prefill-template-data';
import { useTranslation } from 'react-i18next';
import {
    PRODUCT_GROUP_TEMPLATE_TITLE_LABEL,
    TEMPLATE_GROUP_DESCRIPTION_LABEL,
    TemplateGroup,
} from '@schuettflix/interfaces';
import { getPrefilledTemplate } from '@/shared/components/ProductSelection/PresetSelection/utils/template';
import { cn } from '@/shared/utils/cn.ts';

interface ProductCardProps {
    context: 'transport' | 'material' | 'service';
    templateGroup: TemplateGroup;
    onSelect: (template: PrefillTemplate) => void;
}

export const ProductCard = ({ templateGroup, context, onSelect }: ProductCardProps) => {
    const { t } = useTranslation();
    const template = templateGroup.templates?.[0];

    if (!template) {
        return null;
    }

    const prefilledTemplate = getPrefilledTemplate(template);
    const templateGroupTitle = PRODUCT_GROUP_TEMPLATE_TITLE_LABEL[templateGroup.productGroupTemplateTitle];

    return (
        <div
            className={cn('shadow-low flex basis-1/4 cursor-pointer rounded-md bg-white p-4', {
                'h-[52px]': !template.description,
                'h-[76px]': template.description,
            })}
            onClick={() => {
                onSelect(prefilledTemplate || 'empty');
            }}
        >
            <div className="flex w-full flex-col items-start justify-center gap-1">
                {template.description && (
                    <span className="text-subdued font-copy-md">
                        {t(TEMPLATE_GROUP_DESCRIPTION_LABEL[template.description])}
                    </span>
                )}
                <span
                    className={cn('text font-copy-md-strong', {
                        'flex w-full justify-between': !template.description,
                    })}
                >
                    <span>{t(templateGroupTitle)}</span>
                    {!template.description && <ProductCardIcons context={context} />}
                </span>
            </div>

            {template.description && (
                <div className="flex w-1/2 flex-col items-end justify-start">
                    <ProductCardIcons context={context} />
                </div>
            )}
        </div>
    );
};

const ProductCardIcons = ({ context }: { context: 'transport' | 'material' | 'service' }) => (
    <>
        {context === 'transport' && <VehicleMaterialIcon size="xs" />}
        {context === 'material' && <MaterialIcon size="xs" />}
        {context === 'service' && <LaptopIcon size="xs" />}
    </>
);
