import React from 'react';

export function ThumbsUpImage() {
    return (
        <svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M94.0586 58.26H115.669"
                stroke="#FF0000"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M39.5391 57.64H61.1491"
                stroke="#FF0000"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M76.8672 37.26V15.46"
                stroke="#FF0000"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M88.6094 46.58L104.729 30.46"
                stroke="#FF0000"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M64.6278 45.86L48.5078 29.74"
                stroke="#FF0000"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M107.208 47.73C107.208 47.2652 107.07 46.8109 106.812 46.4244C106.554 46.038 106.187 45.7368 105.757 45.5589C105.328 45.381 104.855 45.3345 104.399 45.4252C103.943 45.5158 103.525 45.7396 103.196 46.0683C102.867 46.397 102.644 46.8157 102.553 47.2715C102.462 47.7274 102.509 48.1999 102.687 48.6293C102.865 49.0587 103.166 49.4257 103.552 49.684C103.939 49.9422 104.393 50.08 104.858 50.08C105.481 50.08 106.079 49.8324 106.52 49.3917C106.96 48.951 107.208 48.3533 107.208 47.73V47.73Z"
                stroke="#FF0000"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M66.5084 22.3099H58.3984"
                stroke="#FF0000"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M62.457 18.25V26.36"
                stroke="#FF0000"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M79.2185 122.7L65.5685 121.7C62.8667 121.479 60.2741 120.535 58.0625 118.968C55.8509 117.4 54.1018 115.266 52.9985 112.79C52.9985 112.79 53.1385 97.48 52.9985 87.37C52.8585 77.26 58.9085 75.63 61.6185 75C71.5285 72.45 71.6185 58.51 71.6185 58.51C71.6185 58.51 71.9085 45.75 71.6185 45.89C73.5188 45.8677 75.4132 46.1066 77.2485 46.6C79.6481 47.3026 81.7542 48.7661 83.2495 50.7701C84.7448 52.774 85.5481 55.2097 85.5385 57.71V78.05H102.648C105.292 78.0266 107.838 79.0513 109.728 80.9C112.048 83.26 113.998 87 112.508 95C111.668 98.91 108.938 101.82 105.088 101.59L89.7885 100.7C88.1045 100.602 86.5278 99.8409 85.4034 98.5835C84.279 97.326 83.6984 95.6744 83.7885 93.99C83.842 93.0711 84.0763 92.1717 84.4779 91.3434C84.8794 90.5152 85.4404 89.7742 86.1287 89.163C86.817 88.5519 87.6191 88.0824 88.489 87.7816C89.3589 87.4808 90.2797 87.3545 91.1985 87.41L102.888 88.09"
                stroke="#1F1920"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M107.926 101.41C108.888 102.254 109.569 103.371 109.878 104.612C110.187 105.854 110.11 107.16 109.656 108.356C109.203 109.552 108.395 110.581 107.34 111.306C106.286 112.03 105.036 112.416 103.756 112.41H89.5763C88.1449 112.395 86.7617 111.891 85.6565 110.981C84.5512 110.072 83.7906 108.811 83.5009 107.409C83.2111 106.007 83.4098 104.549 84.0639 103.275C84.718 102.002 85.788 100.991 87.0963 100.41"
                stroke="#1F1920"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M103.048 112.84C104.238 113.536 105.165 114.604 105.688 115.88C106.21 117.155 106.298 118.567 105.939 119.897C105.58 121.228 104.792 122.403 103.699 123.242C102.606 124.081 101.266 124.537 99.8882 124.54H89.3982C87.9522 124.54 86.5505 124.041 85.4297 123.128C84.3089 122.214 83.5376 120.942 83.2461 119.526C82.9546 118.11 83.1608 116.636 83.8298 115.354C84.4988 114.073 85.5897 113.061 86.9182 112.49"
                stroke="#1F1920"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M53.5269 81.34H40.0469V116.55H49.6969"
                stroke="#1F1920"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M40.0481 85.98H24.3281"
                stroke="#1F1920"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
