import primaryBulkGoodsLargeImg from '@/images/primary_bulk_goods_large.png';
import recyclingBulkGoodsLargeImg from '@/images/recycling_bulk_goods_large.jpg';
import nonHazardousWasteLargeImg from '@/images/non_hazardous_waste_large.jpg';
import hazardousWasteLargeImg from '@/images/hazardous_waste_large.jpg';
import asphaltLargeImg from '@/images/asphalt_large.jpg';
import concreteLargeImg from '@/images/concrete_large.png';
import { Template, TEMPLATE_GROUP_TITLE } from '@schuettflix/interfaces';
import { PrefillTemplate } from '@/shared/types/prefill-template-data';

export function getPrefilledTemplate(template: Template): PrefillTemplate {
    return {
        templateId: template.id,
        productGroups: [{ name: template.name, selectedProducts: template.preSelectedProducts }],
        type: template.type,
    };
}

export function getPrefilledTemplateGroup(
    templateGroupName: TEMPLATE_GROUP_TITLE
): Partial<Record<'imageSource' | 'icon', string>> | undefined {
    switch (templateGroupName) {
        case 'ASPHALT':
        case 'ASPHALT_DELIVERY':
            return { imageSource: asphaltLargeImg };
        case 'CONCRETE':
        case 'CONCRETE_DELIVERY':
            return { imageSource: concreteLargeImg };
        case 'HAZARDOUS_WASTE_RECYCLING':
        case 'HAZARDOUS_WASTE_DISPOSAL':
            return { imageSource: hazardousWasteLargeImg };
        case 'NON_HAZARDOUS_WASTE_RECYCLING':
        case 'NON_HAZARDOUS_WASTE_DISPOSAL':
            return { imageSource: nonHazardousWasteLargeImg };
        case 'BULK_GOODS':
        case 'BULK_GOODS_DELIVERY':
            return { imageSource: primaryBulkGoodsLargeImg };
        case 'RECYCLING_BULK_GOODS':
        case 'RECYCLING_BULK_GOODS_DELIVERY':
            return { imageSource: recyclingBulkGoodsLargeImg };
        case 'TRANSPORT':
        case 'TRANSPORT_PER_HOUR':
        case 'TRANSPORT_PER_TON':
            return { icon: 'transport' };
    }
}
