import { z } from 'zod';
import { POSITION_STATUS_SCHEMA, ProjectNumberSchema, ProjectPositionNumberSchema } from './shared';

const PostCustomerInfoRequestSchema = z.strictObject({
    constructionProjectId: z.string().uuid(),
    organizationId: z.number(),
    userId: z.number(),
    manualCostCenter: z.string().optional(),
});

export const PostPositionRequestSchema = z.strictObject({
    projectNumber: ProjectNumberSchema,
    marketCode: z.string(),
    customerInfo: PostCustomerInfoRequestSchema,
});

const PostCustomerInfoResponseSchema = z.strictObject({
    ...PostCustomerInfoRequestSchema.shape,
    id: z.string().uuid(),
    positionId: z.string().uuid(),
    manualCostCenter: z.string().nullable(),
    createdAt: z.coerce.date(),
    updatedAt: z.coerce.date(),
});

export const PostPositionResponseSchema = z.strictObject({
    id: z.string().uuid(),
    projectNumber: ProjectNumberSchema,
    positionNumber: ProjectPositionNumberSchema,
    marketCode: z.string(),
    status: POSITION_STATUS_SCHEMA,
    validFrom: z.coerce.date().nullable(),
    note: z.string().optional().nullable(),
    active: z.boolean(),
    validTo: z.coerce.date().nullable(),
    createdAt: z.coerce.date(),
    updatedAt: z.coerce.date(),
    orderCount: z.number(),
    customerInfo: PostCustomerInfoResponseSchema,
});
