import { useSuspenseQuery } from '@tanstack/react-query';
import { getOrderSummaryById } from './order';

export function getOrderSummaryQueryKey(orderId: string) {
    return ['order-summary', orderId];
}

export const useOrderSummary = (orderId: string) => {
    return useSuspenseQuery({
        queryKey: getOrderSummaryQueryKey(orderId),
        queryFn: () => getOrderSummaryById(orderId),
        refetchOnWindowFocus: false,
    });
};
