import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormatters } from '@/shared/hooks/useFormatters/useFormatters.ts';
import { SectionSummary } from '@/shared/components/SectionSummary.tsx';

interface SectionSummaryPreviewProps {
    title: string;
    onEdit: () => void;
    balanceSheet?: {
        revenue: number;
        costs: number;
        profit: number;
        takeRate: number;
    };
}

export const SectionSummaryPreview: React.FC<SectionSummaryPreviewProps> = ({ title, onEdit, balanceSheet }) => {
    const { t } = useTranslation();
    const { formatNumberWithCurrency, formatPercentage } = useFormatters();

    if (!balanceSheet) {
        return null;
    }
    const { revenue, costs, profit, takeRate } = balanceSheet;

    return (
        <SectionSummary title={title} editable onEditClick={onEdit}>
            <div className="flex flex-nowrap gap-6">
                <p className="font-copy-sm text-disabled">{`${t(
                    'sections.summary.balancesheet.totalrevenue'
                )}: ${formatNumberWithCurrency(revenue)}`}</p>
                <p className="font-copy-sm text-disabled">{`${t(
                    'sections.summary.balancesheet.totalcosts'
                )}: ${formatNumberWithCurrency(costs)}`}</p>
                <p className="font-copy-sm text-disabled">{`${t(
                    'sections.summary.balancesheet.totalprofit'
                )}: ${formatNumberWithCurrency(profit)}`}</p>
                <p className="font-copy-sm text-disabled ml-auto">{`${t(
                    'sections.summary.balancesheet.takerate'
                )}: ${formatPercentage(takeRate)}`}</p>
            </div>
        </SectionSummary>
    );
};
